import { Injectable } from '@angular/core';
import { ManageUserAccessEditorComponent } from './manage-user-access-editor.component';
import { ModalServiceStandAlone } from '../../../elements/generic-modal/modal-service-standalone.service';
import { CommunityProfile } from '../../domain/community-profile';
import { RootResource } from '../../domain/resource';
import { EditorService } from '../../editor.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUserAccessInteractionService extends EditorService<RootResource<CommunityProfile>, ManageUserAccessEditorComponent> {
  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, ManageUserAccessEditorComponent, 'modal-dialog-centered modal-xxl');
  }
}
