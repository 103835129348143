<div id="privacy" #privacy class="py-5 overflow-auto container">

  <h1 class="pt-5">Datenschutzerklärung</h1>

  <h2>1. Verantwortliche Stelle</h2>
  <p>
    Verantwortlich für die Datenverarbeitung auf dieser Plattform ist:
  </p>
  <p>
    <strong>Dr. Peter Machon</strong><br>
    Bool 1<br>
    8574 Lengwil<br>
    Schweiz<br>
    E-Mail: <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>
  </p>

  <h2>2. Geltungsbereich</h2>
  <p>
    Diese Datenschutzerklärung gilt für die Verarbeitung personenbezogener Daten von Benutzern, Communities und Kunden, unabhängig von ihrem Standort. Die Plattform wird von der Schweiz aus betrieben, und die Verarbeitung der Daten erfolgt gemäß den geltenden schweizerischen Datenschutzgesetzen (insbesondere dem revidierten Datenschutzgesetz, DSG) sowie, soweit anwendbar, der Datenschutz-Grundverordnung (DSGVO) der Europäischen Union.
  </p>

  <h2>3. Art der verarbeiteten Daten</h2>
  <p>
    Wir verarbeiten folgende personenbezogene Daten unserer Benutzer:
  </p>
  <ul>
    <li>Vor- und Nachname</li>
    <li>E-Mail-Adresse</li>
  </ul>
  <p>
    Zusätzlich können Inhalte verarbeitet werden, die von Communities auf der Plattform bereitgestellt werden. Diese Inhalte können Informationen enthalten, die für die Nutzung der Plattform oder den Kauf von Produkten und Dienstleistungen erforderlich sind.
  </p>

  <h2>4. Zwecke der Datenverarbeitung</h2>
  <p>
    Die Verarbeitung der personenbezogenen Daten erfolgt zu folgenden Zwecken:
  </p>
  <ul>
    <li>Ermöglichung der Registrierung und Nutzung der Plattform.</li>
    <li>Bereitstellung von Inhalten, die von Communities hochgeladen werden und für Benutzer zum Kauf verfügbar sind.</li>
    <li>Abwicklung der Käufe und Zahlungsprozesse über die Plattform.</li>
    <li>Weitergabe der Nutzerdaten an die Communities zur Bereitstellung spezifischer Inhalte und erweiterter Informationen.</li>
  </ul>

  <h2>5. Rechtsgrundlage der Verarbeitung</h2>
  <p>
    Die Verarbeitung personenbezogener Daten erfolgt auf der Grundlage folgender Rechtsgrundlagen:
  </p>
  <ul>
    <li>
      <strong>Vertragserfüllung:</strong> Die Verarbeitung ist notwendig, um die Nutzung der Plattform zu ermöglichen, Inhalte bereitzustellen und Käufe abzuwickeln.
    </li>
    <li>
      <strong>Rechtliche Verpflichtung:</strong> Die Verarbeitung kann erforderlich sein, um rechtliche Verpflichtungen zu erfüllen (z. B. steuerrechtliche Anforderungen).
    </li>
    <li>
      <strong>Einwilligung:</strong> In bestimmten Fällen kann die Verarbeitung auf der Grundlage einer Einwilligung erfolgen (z. B. für bestimmte Kommunikationszwecke durch Communities).
    </li>
  </ul>

  <h2>6. Verantwortung der Communities</h2>
  <p>
    Die Communities, die Inhalte auf der Plattform bereitstellen, sind eigenständig für die von ihnen veröffentlichten Inhalte sowie für die Verarbeitung personenbezogener Daten im Zusammenhang mit ihrer Tätigkeit verantwortlich. Dies umfasst auch die Verwendung von Daten für Kommunikationszwecke, wie beispielsweise den Versand von Newslettern.
  </p>
  <p>
    Benutzer sollten sich an die jeweilige Community wenden, um weitere Informationen über deren Datenschutzpraktiken zu erhalten.
  </p>

  <h2>7. Weitergabe der Daten</h2>
  <p>
    Die Weitergabe personenbezogener Daten umfasst:
  </p>
  <ul>
    <li>Vor- und Nachname</li>
    <li>E-Mail-Adresse</li>
  </ul>
  <p>
    Zur Abwicklung von Käufen und zur Bereitstellung von Inhalten werden personenbezogene Daten an folgende Drittanbieter weiter gegeben:
  </p>
  <ul>
    <li><a href="https://firebase.google.com/" about="blanc">Google Firebase</a>,</li>
    <li><a href="https://stripe.com/" about="blanc">Stripe</a>.</li>
  </ul>
  <p>
    Zudem werden personenbezogene Daten an die jeweiligen Communities weitergegeben, sobald ein Anmeldung in diesen erfolgt.
  </p>
  <p>
    Die Weitergabe der Daten erfolgt ausschließlich für die genannten Zwecke. Eine Weitergabe an weitere Dritte erfolgt nicht ohne ausdrückliche Zustimmung.
  </p>

  <h2>8. Speicherdauer</h2>
  <p>
    Wir speichern personenbezogene Daten nur so lange, wie dies zur Erfüllung der genannten Zwecke erforderlich ist oder gesetzliche Aufbewahrungspflichten bestehen. Daten, die im Zusammenhang mit Käufen stehen, werden gemäß den gesetzlichen Vorschriften gespeichert.
  </p>

  <h2>9. Rechte der betroffenen Personen</h2>
  <p>Betroffene Personen haben folgende Rechte:</p>
  <ul>
    <li>Recht auf Auskunft über die verarbeiteten Daten.</li>
    <li>Recht auf Berichtigung unrichtiger oder unvollständiger Daten.</li>
    <li>Recht auf Löschung der Daten, soweit keine gesetzlichen Aufbewahrungspflichten bestehen.</li>
    <li>Recht auf Einschränkung der Verarbeitung.</li>
    <li>Recht auf Datenübertragbarkeit.</li>
    <li>Widerspruchsrecht gegen die Verarbeitung.</li>
  </ul>
  <p>
    Zur Ausübung dieser Rechte können Sie sich jederzeit an uns wenden.
  </p>

  <h2>10. Internationale Datenübertragungen</h2>
  <p>
    Da die Plattform von der Schweiz aus betrieben wird, können Daten international übertragen werden. Wir treffen geeignete Maßnahmen, um sicherzustellen, dass solche Übertragungen im Einklang mit den geltenden Datenschutzgesetzen erfolgen.
  </p>

  <h2>11. Kontakt für Datenschutzfragen</h2>
  <p>
    Für Fragen zum Datenschutz oder zur Ausübung Ihrer Rechte wenden Sie sich bitte an:<br>
    <strong>Dr. Peter Machon</strong><br>
    E-Mail: <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>
  </p>

</div>
