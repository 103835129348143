import { Component } from '@angular/core';
import { CommunityNavigationComponent } from '../community-navigation/community-navigation.component';
import { FeedComponent } from '../feed/view/feed.component';
import { NgIf } from '@angular/common';
import { PostViewComponent } from '../post/view/post-view.component';
import { CommunityBaseComponent } from '../community-base/community-base.component';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../profile/profile.service';
import { ResourceAccessService } from '../../api/resource/resource-access.service';

@Component({
  selector: 'app-community-blog',
  standalone: true,
  imports: [
    CommunityNavigationComponent,
    FeedComponent,
    NgIf,
    PostViewComponent
  ],
  templateUrl: './community-blog.component.html',
  styleUrl: './community-blog.component.scss'
})
export class CommunityBlogComponent extends CommunityBaseComponent {

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    accessService: ResourceAccessService
  ) {
    super(route, profileService, accessService);
  }
}
