import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GenericElementComponent} from "../../../generic-element/generic-element.component";
import {NgForOf, NgIf} from "@angular/common";
import {Resource} from "../../domain/resource";
import {Post} from "../../domain/post";
import {PostInteractionService} from "../post-interaction.service";
import {PostViewComponent} from "../view/post-view.component";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";

@Component({
  selector: 'app-post-standalone',
  standalone: true,
  imports: [
    FormsModule,
    GenericElementComponent,
    NgForOf,
    ReactiveFormsModule,
    NgIf,
    PostViewComponent,
    HasWritePermissionDirective
  ],
  templateUrl: './post-standalone.component.html',
  styleUrl: './post-standalone.component.scss'
})
export class PostStandaloneComponent { // implements OnInit {

  @Input() postResource!: Resource<Post>;
  // @Input() postReference!: ResourceId;

  constructor(private postInteractionService: PostInteractionService){ //, private postService: PostService) {
  }

  edit() {
    this.postInteractionService.edit(this.postResource, r => this.postResource = r);
  }

  // // ngOnInit(): void {
  // //   if (!this.postResource) {
  // //     if (this.postReference) {
  // //       this.postService.get(this.postReference).subscribe(r => this.postResource = r);
  // //     } else {
  // //       alert('Post resource missing. Congratulations, you found a bug. Please report.')
  // //     }
  // //   }
  // }
}
