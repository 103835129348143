import {Component, computed, inject, input, ViewChild} from '@angular/core';
import { NgIf, NgOptimizedImage } from "@angular/common";
import { MarkdownComponent } from "ngx-markdown";
import { ElementData } from "./elementData";
import { LazyLoadMediaDirective } from '../elements/lazy-load-video/lazy-load-media.directive';
import { MarkdownTag } from '../community/post/editor/markdown-tag';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { JsonUtil } from '../utils/json-util';
import { VideoElement } from './video-element';
import {ModalServiceStandAlone} from "../elements/generic-modal/modal-service-standalone.service";
import {ModalComponent} from "../elements/generic-modal/modal.component";
import { LinkButtonData } from '../community/post/editor/link-button-form/link-button-data';

interface LinkButtonDataExtended extends LinkButtonData{
  sanitizedUrl: SafeUrl;
}

@Component({
  selector: 'app-generic-element',
  standalone: true,
  imports: [
    NgIf,
    MarkdownComponent,
    NgOptimizedImage,
    LazyLoadMediaDirective,
    ModalComponent
  ],
  templateUrl: './generic-element.component.html',
  styleUrl: './generic-element.component.scss'
})
export class GenericElementComponent {
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly modalService = inject(ModalServiceStandAlone);
  protected readonly MarkdownTag = MarkdownTag;

  @ViewChild('youTubeModal') youTubeModal!: ModalComponent<any>;

  data = input.required<ElementData>();

  videoData = computed<VideoElement>(() => {
    if (this.data().kind !== MarkdownTag.video) {
      return {} as VideoElement;
    }

    if (JsonUtil.isJson(this.data().content)) {
      return JSON.parse(this.data().content) as VideoElement;
    } else {
      return {videoUrl: this.data().content}; // For compatibility reasons. Old video url was a simple string
    }
  });

  sanitizedYouTubeUrl = computed<SafeResourceUrl | undefined>(() => {
    if (this.data().kind === MarkdownTag.youTubeVideo) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.data().content);
    }
    return undefined;
  });

  linkButtonData = computed<LinkButtonDataExtended>(() => {
    if (this.data().kind !== MarkdownTag.callToActionButton && this.data().kind !== MarkdownTag.linkButton) {
      return {} as LinkButtonDataExtended;
    }

    if (JsonUtil.isJson(this.data().content)) {
      const buttonData = JSON.parse(this.data().content) as LinkButtonData;
      return {
        ...buttonData,
        sanitizedUrl: this.domSanitizer.bypassSecurityTrustUrl(buttonData.link)
      };
    } else {
      console.error('Parsing of link button data from callToActionButton or linkButton markdown was unsuccessful!');
      return {} as LinkButtonDataExtended;
    }
  });

  openYouTubeModal() {
    this.youTubeModal.open();
  }

}
