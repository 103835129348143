import { Component, computed, inject, input } from '@angular/core';
import { TextTileComponent } from '../text-tile/text-tile.component';
import { Theme } from '../text-tile/theme';
import { ProductService } from '../../community/product/product.service';
import { SubscriptionPlan } from '../../api/membership/domain/subscription-plan';

@Component({
  selector: 'app-product-access-indicator',
  standalone: true,
  imports: [
    TextTileComponent
  ],
  templateUrl: './product-access-indicator.component.html',
  styleUrl: './product-access-indicator.component.scss'
})
export class ProductAccessIndicatorComponent {
  private readonly productService = inject(ProductService);

  productPurchasePlan = input.required<SubscriptionPlan>();
  theme = input<Theme>(Theme.light);

  // Use this code when product Data is implemented in the store
  // resourceId = input.required<ResourceId>();
  // productPurchasePlan = toSignal<SubscriptionPlan>(
  //   toObservable(this.resourceId)
  //     .pipe(
  //       switchMap(resourceId => this.productService.mayPurchaseProduct(this.resourceId())),
  //       map(mayPurchase => mayPurchase.minimumRequiredPlan)
  //     )
  // );

  accessLevelStr = computed<string>(() => {
    if (this.productPurchasePlan() === SubscriptionPlan.NO_MEMBERSHIP) {
      return '';
    }
    if (this.productPurchasePlan() === SubscriptionPlan.FREE) {
      return 'Free';
    }
    if (this.productPurchasePlan() === SubscriptionPlan.BASIC) {
      return 'Basic';
    }
    if (this.productPurchasePlan() === SubscriptionPlan.PREMIUM) {
      return 'Premium';
    }
    return '';
  });

}
