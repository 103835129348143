import { Injectable } from '@angular/core';
import { ModalServiceStandAlone } from '../../../elements/generic-modal/modal-service-standalone.service';
import { ProductBuyModalComponent } from './product-buy-modal.component';
import { EditorService } from '../../editor.service';
import { Offering } from '../../../api/product/domain/offering';

@Injectable({
  providedIn: 'root'
})
export class ProductInteractionService extends EditorService<Offering[], ProductBuyModalComponent> {

  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, ProductBuyModalComponent, 'modal-dialog-centered');
  }
}
