import { Component, computed, input, output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { PostViewComponent } from '../../post/view/post-view.component';
import { ContentElement, ContentElements } from '../../domain/content-element';
import { Resource } from '../../domain/resource';
import { ContentKind } from '../../../api/user/domain/content-kind';
import { ResourceUtil } from '../../../utils/resource-util';

interface SelectableContentElement {
  resource: Resource<ContentElement>,
  type: ContentKind,
  isSelected: boolean,
  selectionIndex: number | undefined
}

@Component({
  selector: 'app-resource-selector',
  standalone: true,
  imports: [
    AsyncPipe,
    PostViewComponent
  ],
  templateUrl: './resource-selector.component.html',
  styleUrl: './resource-selector.component.scss'
})
export class ResourceSelectorComponent {
  private selectedResources: SelectableContentElement[] = [];

  resources = input.required<Resource<ContentElement>[]>();
  showSelectionOrder = input<boolean>(false);
  selectedResourceUuids = input<string[]>([]);

  selectableResources = computed<SelectableContentElement[]>(() => {
    const allResources = this.mapToSelectableContentElements(this.resources());
    this.selectedResources = allResources.filter(resource =>
      this.selectedResourceUuids().some(selectedResource => selectedResource === resource.resource.uuid))
    return allResources;
  });

  selectionChange = output<Resource<ContentElement>[]>();


  toggleSelection(element: SelectableContentElement) {
    element.isSelected = !element.isSelected;

    if (element.isSelected) {
      this.selectedResources.push(element);
      element.selectionIndex = 9999;
    } else {
      element.selectionIndex = undefined;
      this.selectedResources = this.selectedResources.filter(e => e.resource.uuid !== element.resource.uuid);
    }

    this.selectedResources = this.resetSelectionIndices(this.selectedResources);

    this.selectionChange.emit(this.selectedResources.map(resource => resource.resource));
  }

  getElementType(element: SelectableContentElement): string {
    if (element.type === ContentKind.feed)
      return 'Feed';
    if (element.type === ContentKind.collection)
      return 'Collection';

    return 'Other';
  }

  private mapToSelectableContentElements(resources: Resource<any>[]): SelectableContentElement[] {
    const contentElements: SelectableContentElement[] = [];

    resources.forEach(resource => {
      const selectionIndex = this.selectedResourceUuids().findIndex(uuid => resource.uuid === uuid);
      contentElements.push({
        resource,
        type: ResourceUtil.isCollection(resource) ? ContentKind.collection : ContentKind.feed,
        isSelected: selectionIndex >= 0,
        selectionIndex: selectionIndex >= 0 ? selectionIndex + 1 : undefined
      });
    });

    return contentElements;
  }

  private resetSelectionIndices(list: SelectableContentElement[]): SelectableContentElement[] {
    const selectedElements = list.filter(element => element.isSelected);

    selectedElements.sort((a, b) => a.selectionIndex! - b.selectionIndex!);

    selectedElements.forEach((element, index) => {
      element.selectionIndex = index + 1;
    });

    return list;
  }
}
