import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  standalone: true,
  imports: [],
  templateUrl: './disclaimer.component.html',
  styleUrl: './disclaimer.component.scss'
})
export class DisclaimerComponent {

  @HostBinding('class') class = 'd-flex w-100';
}
