import { Directive, ElementRef, inject, input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLazyLoadMedia]',
  standalone: true
})
export class LazyLoadMediaDirective implements OnInit {
  private readonly el = inject(ElementRef);
  private renderer = inject(Renderer2);

  observer!: IntersectionObserver;

  src = input('', {alias: 'appLazyLoadMedia'});

  ngOnInit(): void {
    this.setupObserver();
  }

  setupObserver() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadVideo();
            this.observer.unobserve(this.el.nativeElement);
          }
        });
      }, { threshold: 0 });

      this.observer.observe(this.el.nativeElement);
    } else {
      this.loadVideo();
    }
  }

  loadVideo() {
    const media: HTMLVideoElement | HTMLAudioElement = this.el.nativeElement;
    if (this.src()) {
      const source = media.querySelector('source');
      if (source) {
        this.renderer.setAttribute(source, 'src', this.src());
        media.load();
      }
    }
  }
}
