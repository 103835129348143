import {map, Observable, ReplaySubject, switchMap} from "rxjs";
import {ResourceId, RootResourceId} from "../../community/domain/resource";
import {Injectable} from "@angular/core";
import {ProfileService} from "../../community/profile/profile.service";
import {AccessDelegate, FullDelegate} from "../../community/domain/instant";
import {UserRightsByCommunity} from './domain/user-rights-by-community';
import {MayAccess} from './domain/may-access';
import {Accessibility} from "../product/domain/accessibility";

export enum AccessLevels {
  unrestricted = 'unrestricted',
  free = 'free',
  freePlus = 'freePlus',
  basic = 'basic',
  basicPlus = 'basicPlus',
  premium = 'premium',
  premiumPlus = 'premiumPlus'
}

export interface AccessSetupData {
  communityId: RootResourceId,
  accessLevel: AccessLevels
}

const ACCESS_DELEGATE_HEADER = 'X-Access-Delegate'

export interface Header {
  key: string,
  value: string
}

export function accessDelegateHeader(delegate: FullDelegate): Header {
  const d: AccessDelegate = {assignmentId: delegate.assignmentId, creationTimestamp: delegate.creationTimestamp};
  return {key: ACCESS_DELEGATE_HEADER, value: JSON.stringify(d)}
}

function isResourceId(obj: any): obj is ResourceId {
  return typeof obj === 'object' &&
    'uuid' in obj &&
    'creationTimestamp' in obj &&
    'assignmentId' in obj &&
    typeof obj.uuid === 'string' &&
    typeof obj.creationTimestamp === 'number' &&
    typeof obj.assignmentId == 'string';
}

@Injectable({
  providedIn: 'root'
})
export abstract class ResourceAccessService {

  private communityDelegateResource = new ReplaySubject<FullDelegate>(1);
  private contentDelegateResource = new ReplaySubject<FullDelegate>(1);
  protected mayWriteSubject = new ReplaySubject<boolean>(1);

  protected constructor(protected profileService: ProfileService) {
    this.getCommunityDelegate()
      .pipe(
        switchMap(community => this.checkCommunityWriteAccess(community))
      )
      .subscribe();
  }

  setCommunityDelegate(resourceId: RootResourceId) {
    this.communityDelegateResource.next({
      assignmentId: null,
      creationTimestamp: resourceId.creationTimestamp,
      uuid: resourceId.uuid
    });
  }

  setContentDelegate(resourceId: ResourceId | RootResourceId) {
    if (isResourceId(resourceId)) {
      this.contentDelegateResource.next(resourceId);
    } else {
      this.contentDelegateResource.next({
        assignmentId: null,
        creationTimestamp: resourceId.creationTimestamp,
        uuid: resourceId.uuid
      });
    }
  }

  getCommunityDelegate() {
    return this.communityDelegateResource.asObservable();
  }

  getContentDelegate() {
    return this.contentDelegateResource.asObservable();
  }

  mayWrite() {
    return this.mayWriteSubject.asObservable();
  }

  abstract setAccessLevel(resourceId: ResourceId, accessLevel: AccessLevels): Observable<void>;

  abstract getAccessLevel(resourceId: ResourceId): Observable<AccessSetupData>;

  abstract getMayAccess(resourceId: ResourceId): Observable<MayAccess>;

  abstract getMayAccessPublic(resourceId: ResourceId): Observable<MayAccess>;

  abstract checkCommunityWriteAccess(communityId: RootResourceId): Observable<boolean>;

  abstract getUserRightsByCommunity(communityId: RootResourceId): Observable<UserRightsByCommunity>;

}
