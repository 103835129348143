import { Injectable } from '@angular/core';
import { SubscriptionPlan } from '../../api/membership/domain/subscription-plan';
import { Currency } from '../../api/product/domain/currency';
import { Price } from '../../api/product/domain/price';
import { Resource } from '../domain/resource';
import { Product } from '../../api/product/domain/product';
import { CommunityMembership } from '../../api/membership/domain/community-membership';
import { Period } from '../../api/product/domain/period';
import { ResourceUtil } from '../../utils/resource-util';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  getSubscriptionPlanStrings(plan: SubscriptionPlan): string {
    if (plan === SubscriptionPlan.FREE) {
      return 'Free';
    }
    if (plan === SubscriptionPlan.BASIC) {
      return 'Basic';
    }
    if (plan === SubscriptionPlan.PREMIUM) {
      return 'Premium';
    }
    return '';
  }

  getCurrencySymbol(currency: Currency): string {
    return ResourceUtil.getCurrencySymbol(currency);
  }

  getMonthlyPrice(subscription: Resource<Product<CommunityMembership>>): Price {
    return this.getPrice(subscription, Period.month);
  }

  getYearlyPrice(subscription: Resource<Product<CommunityMembership>>): Price {
    return this.getPrice(subscription, Period.year);
  }

  private getPrice(subscription: Resource<Product<CommunityMembership>>, period: Period): Price {
    const priceWithUuid = [...subscription.data.offerings[0].prices].find(([uuid, price]) => price.period === period)!;
    return priceWithUuid[1];
    // return Object.values(subscription.data.offerings[0].prices).find(price => price.period === period)!;
  }
}
