import { Period } from './period';
import { Currency } from './currency';

export interface Price {
  amount: number;
  currency: Currency;
  period: Period;
  tryoutPeriodInDays: number;
  description: string;
}

export class Prices {
  static empty(): Price {
    return {
      amount: 0,
      currency: Currency.EUR,
      period: Period.oneTime,
      tryoutPeriodInDays: 0,
      description: ''
    }
  }
}
