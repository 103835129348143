import { Component, inject, OnDestroy, Renderer2 } from '@angular/core';
import { CommunityBaseComponent } from "../../community-base/community-base.component";
import { Collection } from "../../domain/collection";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { CommunityNavigationComponent } from "../../community-navigation/community-navigation.component";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { PostViewComponent } from "../../post/view/post-view.component";
import { CHAPTER_ID, CommunityRoutes } from "../../community.routes";
import { catchError, map, mergeMap, of, Subscription, switchMap, tap, throwError } from "rxjs";
import { Post } from "../../domain/post";
import { Resource } from "../../domain/resource";
import { PostService } from "../../post/post.service";
import { CollectionService } from "../collection.service";
import { ChapterEditorService } from "../chapter-editor/chapter-editor.service";
import { ProfileService } from "../../profile/profile.service";
import { ResourceAccessService } from "../../../api/resource/resource-access.service";
import { HasWritePermissionDirective } from "../../access-control/has-write-permission.directive";
import { ElementData } from '../../../generic-element/elementData';
import { getHeadingList, HeadingData } from 'marked-gfm-heading-id';
import { MarkdownService } from 'ngx-markdown';
import { NotificationService } from '../../../services/notification.service';
import { Chapter } from '../chapter-editor/Chapter';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginModalService } from '../../../login/login-modal/login-modal.service';
import {UserContentApiService} from "../../../api/user/user-content-api.service";

interface ChapterData {
  title: string;
  content: Resource<Post>;
}

@Component({
  selector: 'app-collection',
  standalone: true,
  imports: [
    CommunityNavigationComponent,
    NgForOf,
    PostViewComponent,
    RouterLink,
    NgIf,
    HasWritePermissionDirective,
    RouterLinkActive,
    NgClass
  ],
  templateUrl: './collection.component.html',
  styleUrl: './collection.component.scss'
})
export class CollectionComponent extends CommunityBaseComponent implements OnDestroy {
  private readonly notificationService = inject(NotificationService);
  private readonly loginModalService = inject(LoginModalService);

  private subscription!: Subscription;

  collection!: Resource<Collection>;
  chapterIndex = -1;
  chapter!: ChapterData;
  chapterHeaders: HeadingData[] = [];

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private collectionService: CollectionService,
    private postService: PostService,
    private chapterEditorService: ChapterEditorService,
    resourceAccessService: ResourceAccessService,
    private router: Router,
    private renderer: Renderer2,
    private markdownService: MarkdownService,
    private userContentApiService: UserContentApiService,
    private domSanitizer: DomSanitizer
  ) {
    super(route, profileService, resourceAccessService);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.subscription)
      this.subscription.unsubscribe();

    this.subscription = this.collectionService
      .get(this.contentResourceId)
      .pipe(
        tap(collection => this.collection = collection),
        tap(() => this.userContentApiService.setUserOpenedCollection(this.collection).subscribe()),
        map(r => r.data),
        mergeMap(collection => {
          return this.route.paramMap.pipe(
            switchMap(parameterMap => {
              const chapterId = parameterMap.get(CHAPTER_ID);
              if (chapterId) {
                const chapterIndex = collection.chapters.findIndex(chapter => chapter.uuid == chapterId);

                this.chapterIndex = chapterIndex;

                if (chapterIndex != -1) {
                  const chapterReference = collection.chapters[chapterIndex];

                  return this.postService.get(chapterReference).pipe(
                    tap(post => {
                      this.chapter = {title: chapterReference.data.title, content: post}
                      this.chapterHeaders = this.getChapterHeaders(post.data.elements);
                    //   // @ts-ignore
                    //   this.chapterHeaders = this.chapterHeaders.map(header => ({
                    //     ...header,
                    //     text: this.domSanitizer.bypassSecurityTrustHtml(header.text)
                    //   }))
                    }),
                    catchError((error: HttpErrorResponse) => {
                      if (error.status === 401) {
                        this.loginModalService.signUpOrLoginAndRoute(this.router.url);
                      }
                      return throwError(() => error);
                    })
                  );
                } else {
                  alert('Unknown Chapter: ' + chapterId + '. This is a bug. Please report.')
                  return of();
                }
              } else {
                return of();
              }
            }))
        }),
      )
      .subscribe();
  }

  scrollToHeader(event: MouseEvent, header: HeadingData) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).blur();  // Remove focus from the clicked element
    const heading = document.getElementById(header.id);
    heading?.scrollIntoView({behavior: 'smooth'});
  }

  collectionLink(): string {
    return CommunityRoutes.collection(this.community.creationTimestamp, this.community.uuid, this.contentResourceId.creationTimestamp, this.contentResourceId.uuid);
  }

  chapterLink(chapterId: string): string {
    return CommunityRoutes.chapter(this.community.creationTimestamp, this.community.uuid, this.contentResourceId.creationTimestamp, this.contentResourceId.uuid, chapterId);
  }

  removeFocus(event: MouseEvent): void {
    (event.target as HTMLElement).blur();  // Remove focus from the clicked element
  }

  hasNextChapter(index: number): boolean {
    return index != -1 ? (this.collection.data.chapters.length - 1 > index) : this.collection.data.chapters.length > 0;
  }

  nextChapter() {
    const nextIndex = (this.chapterIndex || this.chapterIndex == 0) ? this.chapterIndex + 1 : 0;
    this.router
      .navigateByUrl(this.chapterLink(this.collection.data.chapters[nextIndex].uuid))
      .then(() => this.scrollUp());
  }

  hasPreviousChapter(index: number) {
    return index != -1;
  }

  onLinkClick(event: MouseEvent) {
    this.removeFocus(event);
    this.scrollUp();
  }

  previousChapter() {
    const previousIndex = Math.max(this.chapterIndex - 1, -1);
    this.router
      .navigateByUrl(previousIndex == -1
        ? this.collectionLink()
        : this.chapterLink(this.collection.data.chapters[previousIndex].uuid)
      )
      .then(() => this.scrollUp());
  }

  addChapter(initialData?: Chapter) {
    this
      .chapterEditorService.openModal(
      'Create Chapter',
      initialData || null,
      chapter => {
        this.postService
          .create(this.contentResourceId.uuid, chapter.content)
          .pipe(
            catchError(err => {
              this.notificationService.showGeneralSaveError();
              this.addChapter(chapter);
              return throwError(() => err);
            }),
            mergeMap(postResource => {
              this.collection.data.chapters.push({
                data: {
                  title: chapter.title
                },
                assignmentId: postResource.assignmentId,
                creationTimestamp: postResource.creationTimestamp,
                uuid: postResource.uuid
              })
              return this.collectionService.save(this.collection)
                .pipe(
                  catchError(err => {
                    this.notificationService.showGeneralSaveError('The creation of the post itself was ' +
                      'successful, only the assignment to your collection encountered an error.'); // needs to be more user-friendly later on. For now this is so that the first users can report this message to the support team.
                    return throwError(() => err);
                  })
                );
            }),
            tap(collection => {
              this.nextChapter();
            })
          )
          .subscribe()
      }
    )
  }

  editChapter(chapterData: ChapterData) {
    this
      .chapterEditorService.openModal(
      'Edit Chapter',
      {title: chapterData.title, content: chapterData.content.data},
      chapter => {
        const requestData: Resource<Post> = {
          assignmentId: chapterData.content.assignmentId,
          creationTimestamp: chapterData.content.creationTimestamp,
          uuid: chapterData.content.uuid,
          data: chapter.content
        };
        this.postService
          .save(requestData)
          .pipe(
            catchError(err => {
              this.notificationService.showGeneralSaveError();
              this.editChapter({title: chapter.title, content: requestData});
              return throwError(() => err);
            }),
            mergeMap(postResource => {
              this.collection.data.chapters[this.chapterIndex].data.title = chapter.title
              return this.collectionService.save(this.collection)
                .pipe(
                  catchError(err => {
                    this.notificationService.showGeneralSaveError('The content was updated successfully ' +
                      'only saving the title encountered an error.'); // needs to be more user-friendly later on. For now this is so that the first users can report this message to the support team.
                    return throwError(() => err);
                  })
                );
            })
          )
          .subscribe(r => this.ngOnInit())
      }
    )
  }

  private scrollUp() {
    const element = this.renderer.selectRootElement('#communityMainContent', true);
    if (element) {
      element.scrollTop = 0;  // Scroll the element to the top
    }
  }

  private getChapterHeaders(contentElements: ElementData[]): HeadingData[] {
    const headings: HeadingData[] = [];

    contentElements.forEach(element => {
      if (element.kind === 'markdown') {
        this.markdownService.parse(element.content); // Needs to run before getHeadingList() is called
        const headingList = getHeadingList();
        headings.push(...headingList);
      }
    });

    return headings;
  }
}
