import { Component, input } from '@angular/core';
import { Theme } from './theme';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-text-tile',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './text-tile.component.html',
  styleUrl: './text-tile.component.scss'
})
export class TextTileComponent {
  protected readonly Theme = Theme;

  theme = input<Theme>(Theme.light);
  roundedEnds = input<boolean>(false);
}
