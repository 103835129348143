@if (showPurchaseSuccess()) {
  <div class="alert alert-success animated fadeIn text-center">
    Thank you for your purchase. You now have access to all {{ activePlan() }} content.
  </div>
}
@if (showPurchaseError()) {
  <div class="alert alert-danger animated fadeIn text-center">
    There was an error with the purchase checkout. That shouldn't have happened, we are very sorry. Please contact
    support: contact&#64;keldysh.io
  </div>
}
<div class="subscription-container">
  <div class="row">
    @if (subscriptions()?.length) {
      <div class="col mb-2">
        <app-subscription-card [subscriptionPlan]="freeSubscription()!" [state]="freeState()"
                               (edit)="onEditClick($event)" (join)="onJoinFreeClick($event)"
                               [showRequired]="requiredPlan() === SubscriptionPlan.FREE"
                               [showDescription]="showDescription()"></app-subscription-card>
      </div>
<!--     todo:  (userState() && userState() === CommunityUserRole.creator should be one global function -->
      @if (basicSubscription()!.data.offerings.length > 0 || (userState() && userState() === CommunityUserRole.creator)) {
        <div class="col mb-2">
          <app-subscription-card [subscriptionPlan]="basicSubscription()!" [state]="basicState()"
                                 (edit)="onEditClick($event)" (join)="onJoinClick($event)"
                                 [showRequired]="requiredPlan() === SubscriptionPlan.BASIC"
                                 [showDescription]="showDescription()"></app-subscription-card>
        </div>
      }
      @if (premiumSubscription()!.data.offerings.length > 0 || (userState() && userState() === CommunityUserRole.creator)) {
        <div class="col mb-2">
          <app-subscription-card [subscriptionPlan]="premiumSubscription()!" [state]="premiumState()"
                                 (edit)="onEditClick($event)" (join)="onJoinClick($event)"
                                 [showRequired]="requiredPlan() === SubscriptionPlan.PREMIUM"
                                 [showDescription]="showDescription()"></app-subscription-card>
        </div>
      }
    }
  </div>
</div>
