<div class="content d-flex w-100">
  <!-- Sidenav -->
  <nav *ngIf="community" class="sidenav d-flex flex-column align-items-center p-md-3 p-2 navbar-dark col-2"
       style="background-color: var(--bs-primary); max-width: 14rem">
    <a [routerLink]="CommunityRoutes.community(community.creationTimestamp, community.uuid)"
       class="navbar-brand text-center logo-link">
      <img *ngIf="community?.data?.logoLink" ngSrc="{{community?.data?.logoLink}}" alt="{{ community?.data?.title}}"
           class="logo" height="120" width="500">
      <span style="font-weight: bold" *ngIf="!community?.data?.logoLink">{{ community?.data?.title }}</span>
    </a>
    <!--    <a [routerLink]="CommunityRoutes.membership(community.creationTimestamp, community.uuid)"-->
    <!--       class="btn join-button mt-2 d-flex justify-content-center align-items-center"-->
    <!--       type="button">Join</a>-->
    <div class="mt-1">
      <a [routerLink]="CommunityRoutes.membership(community.creationTimestamp, community.uuid)"
         routerLinkActive="nav-link-active" class="nav-link" ariaCurrentWhenActive="page">
        <app-user-role-indicator [communityId]="community"></app-user-role-indicator>
      </a>
    </div>
    <div class="flex-shrink-0">
      <ul class="nav navbar-nav nav-pills p-lg-3 text-center">
        <!--        &lt;!&ndash;       todo:  dedicated to intoduce community&ndash;&gt;-->
        <li class="nav-item">
          <a [routerLink]="CommunityRoutes.community(community.creationTimestamp, community.uuid)"
             routerLinkActive="nav-link-active" class="nav-link d-flex align-items-center" aria-current="page">
            <span class="material-symbols-rounded notranslate me-1 me-md-2">home</span>
            Home
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="CommunityRoutes.blog(community.creationTimestamp, community.uuid)"
             routerLinkActive="nav-link-active" class="nav-link d-flex align-items-center" aria-current="page">
            <span class="material-symbols-rounded notranslate me-1 me-md-2">contextual_token</span>
            Blog
          </a>
        </li>
        @if (isCommunityCreator()) {
          <li class="nav-item">
            <a [routerLink]="CommunityRoutes.membership(community.creationTimestamp, community.uuid)"
               routerLinkActive="nav-link-active" class="nav-link d-flex align-items-center" aria-current="page">
              <span class="material-symbols-rounded notranslate me-1 me-md-2">award_star</span>
              Plans
            </a>
          </li>
        }
        <li class="nav-item">
          <a [routerLink]="CommunityRoutes.products(community.creationTimestamp, community.uuid)"
             routerLinkActive="nav-link-active" class="nav-link d-flex align-items-center" aria-current="page">
            <span class="material-symbols-rounded notranslate me-1 me-md-2">box</span>
            Products
          </a>
        </li>
        <hr style="color: var(--hover-color)">
        <!--        todo: maybe replace by products-->
        <li class="nav-item">
          <a [routerLink]="CommunityRoutes.feeds(community.creationTimestamp, community.uuid)"
             routerLinkActive="nav-link-active" class="nav-link d-flex align-items-center" aria-current="page">
            <span class="material-symbols-rounded notranslate me-1 me-md-2">contract</span>
            Feeds
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="CommunityRoutes.collections(community.creationTimestamp, community.uuid)"
             routerLinkActive="nav-link-active" class="nav-link d-flex align-items-center" aria-current="page">
            <span class="material-symbols-rounded notranslate me-1 me-md-2">list_alt</span>
            Collections
          </a>
        </li>
        <hr style="color: var(--hover-color)">
      </ul>
    </div>
    <div class="flex-grow-1 overflow-auto w-100">
      <ul class="nav navbar-nav nav-pills p-lg-3">
        <!--        <div class="overflow-y-auto" style="max-height: 65vh">-->
        <ng-content select="[sidenav]"></ng-content>
        <!--        </div>-->
      </ul>
    </div>

    <!--    <div class="mb-0 row row-cols-1 row-cols-md-3">-->
    <!--    <p class="mb-0">-->
    <!--      <a-->
    <!--        href="/legal"-->
    <!--        target="_blank"-->
    <!--        style="font-size: 10px; color: grey; text-decoration: none;"-->
    <!--        rel="noopener noreferrer">-->
    <!--        Legal Notes-->
    <!--      </a>-->
    <!--    </p>-->
    <p class="mb-0 mt-2 flex-shrink-0">
      <span style="color: #818181">Powered by </span>
      <!--      <br>-->
      <a href="https://keldysh.io" target="_blank" style="color: var(--hover-color)">keldysh.io</a>
    </p>
    <div class="mb-0 row mt-1 g-0">
      <div class="col" style="font-size: 7px; line-height: 1;">
        <a
          href="/privacy"
          target="_blank"
          style="font-size: 7px; color: grey; text-decoration: none;"
          rel="noopener noreferrer">
          Privacy
        </a>
      </div>
      <div class="col" style="font-size: 7px; line-height: 1; margin-left: 2px; margin-right: 2px">
        <a
          href="/terms"
          target="_blank"
          style="font-size: 7px; color: grey; text-decoration: none;"
          rel="noopener noreferrer">
          Terms
        </a>
      </div>
      <div class="col" style="font-size: 7px; line-height: 1;">
        <a
          href="/disclaimer"
          target="_blank"
          style="font-size: 7px; color: grey; text-decoration: none;"
          rel="noopener noreferrer">
          Legal
        </a>
      </div>
    </div>
    <!--  <div class="dropdown">-->
    <!--    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">-->
    <!--      <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">-->
    <!--      <strong>mdo</strong>-->
    <!--    </a>-->
    <!--    <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">-->
    <!--      <li><a class="dropdown-item" href="#">New project...</a></li>-->
    <!--      <li><a class="dropdown-item" href="#">Settings</a></li>-->
    <!--      <li><a class="dropdown-item" href="#">Profile</a></li>-->
    <!--      <li>-->
    <!--        <hr class="dropdown-divider">-->
    <!--      </li>-->
    <!--      <li><a class="dropdown-item" href="#">Sign out</a></li>-->
    <!--    </ul>-->
    <!--  </div>-->
  </nav>

  <!-- Main Content -->
  <!--  works too -->
  <!--  <div id="communityMainContent" class="main-content flex-grow-1 overflow-y-auto">-->
  <div #scrollToTop appShowScrollBarOnScroll id="communityMainContent"
       class="main-content col overflow-y-auto overflow-x-hidden">
    <div class="px-0 px-md-3 pt-3">
      <ng-content select="[main]"></ng-content>
    </div>
  </div>
</div>
<app-scroll-to-top-button [scrollContainer]="scrollToTop"></app-scroll-to-top-button>
