import { Resource, ResourceId, RootResourceId } from '../community/domain/resource';
import { Feed } from '../community/domain/feed';
import { Collection } from '../community/domain/collection';
import { ContentElement } from '../community/domain/content-element';
import { ContentDescriptor } from '../api/product/domain/content-descriptor';
import { ContentKind } from '../api/user/domain/content-kind';
import { Currency } from '../api/product/domain/currency';
import { CommunityUserRole } from '../api/user/domain/community-user-role';
import { AccessLevels } from '../api/resource/resource-access.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { COMMUNITY_CREATION_TIMESTAMP, COMMUNITY_UUID, CREATION_TIMESTAMP, UUID } from '../community/community.routes';

export class ResourceUtil {
  static isCollection(resource: Resource<Feed | Collection>): resource is Resource<Collection> {
    return (resource as Resource<Collection>).data.chapters !== undefined;
  }

  static toContentDescriptor(resource: Resource<ContentElement>): ContentDescriptor {
    return {
      resourceId: resource,
      kind: ResourceUtil.isCollection(resource) ? ContentKind.collection : ContentKind.feed
    };
  }

  // static toContentElement(resource: ContentDescriptor): Resource<ContentElement> {
  //   return {
  //     resourceId: resource,
  //     kind: ResourceUtil.isCollection(resource) ? ContentKind.collection : ContentKind.feed
  //   };
  // }

  static getCurrencySymbol(currency: Currency): string {
    if (currency === Currency.USD) {
      return '$';
    }
    if (currency === Currency.EUR) {
      return '€';
    }
    if (currency === Currency.CHF) {
      return 'CHF';
    }
    return '';
  }

  static hasUserAccess(role: CommunityUserRole | undefined, accessLevel: AccessLevels | undefined): boolean {
    if (role === CommunityUserRole.visitor) {
      return accessLevel === AccessLevels.unrestricted;
    } else if (role === CommunityUserRole.freeFollower) {
      return accessLevel === AccessLevels.unrestricted
        || accessLevel === AccessLevels.free;
    } else if (role === CommunityUserRole.basicFollower) {
      return accessLevel === AccessLevels.unrestricted
        || accessLevel === AccessLevels.free
        || accessLevel === AccessLevels.freePlus
        || accessLevel === AccessLevels.basic;
    } else if (role === CommunityUserRole.premiumFollower) {
      return accessLevel === AccessLevels.unrestricted
        || accessLevel === AccessLevels.free
        || accessLevel === AccessLevels.freePlus
        || accessLevel === AccessLevels.basic
        || accessLevel === AccessLevels.basicPlus
        || accessLevel === AccessLevels.premium;
    } else if (role === CommunityUserRole.creator) {
      return true;
    }

    return false;
  }

  static hasToBeBought(resourceAccessLevel: AccessLevels): boolean {
    return resourceAccessLevel === AccessLevels.freePlus
      || resourceAccessLevel === AccessLevels.basicPlus
      || resourceAccessLevel === AccessLevels.premiumPlus;
  }

  static getRootResourceIdFromUrl(route: ActivatedRouteSnapshot): RootResourceId | null {
    const communityCreationTimestamp = Number(route.paramMap.get(COMMUNITY_CREATION_TIMESTAMP) ?? '0');
    const communityUuid = route.paramMap.get(COMMUNITY_UUID)!;

    if (!communityUuid || communityCreationTimestamp == undefined) {
      return null;
    }

    return {
      uuid: communityUuid,
      creationTimestamp: communityCreationTimestamp
    }
  }

  static getResourceIdFromUrl(route: ActivatedRouteSnapshot): ResourceId | null {
    const communityUuid = route.paramMap.get(COMMUNITY_UUID);
    const resourceCreationTimestamp = Number(route.paramMap.get(CREATION_TIMESTAMP) ?? '0');
    const resourceUuid = route.paramMap.get(UUID);

    if (!communityUuid || resourceCreationTimestamp == undefined || !resourceUuid) {
      return null;
    }

    return {
      assignmentId: communityUuid,
      uuid: resourceUuid,
      creationTimestamp: resourceCreationTimestamp
    }
  }

  static getResourceId(resource: Resource<any>): ResourceId {
    return {
      uuid: resource.uuid,
      creationTimestamp: resource.creationTimestamp,
      assignmentId: resource.assignmentId
    };
  }
}
