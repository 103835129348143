import { MarkdownTag } from '../post/editor/markdown-tag';
import { Price } from '../../api/product/domain/price';
import { MathUtils } from 'three';
import { Currency } from '../../api/product/domain/currency';
import { Period } from '../../api/product/domain/period';
import { ElementData } from '../../generic-element/elementData';
import { Offering } from '../../api/product/domain/offering';

export const SubscriptionPlanDefaultDescription: ElementData[] = [
  {
    // content: '*Subscription plan is not configured yet by the creators of the community.*',
    content: '',
    kind: MarkdownTag.markdown
  }
];

export const SubscriptionPlanDefaultOffering: Offering[] = [
  {
    description: '',
    active: true,
    prices: new Map<string, Price>()
      .set(MathUtils.generateUUID(), {
        description: 'Monatlich',
        amount: 0,
        currency: Currency.USD,
        period: Period.month,
        tryoutPeriodInDays: 0
      })
      .set(MathUtils.generateUUID(), {
        description: 'Jährlich',
        amount: 0,
        currency: Currency.USD,
        period: Period.year,
        tryoutPeriodInDays: 0
      })
  }
];
