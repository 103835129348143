<!--<div *ngFor="let post of posts">-->
<!--  <app-post-view [elements]="post.elements"></app-post-view>-->
<!--  <hr class="my-5 mx-auto ruler">-->
<!--</div>-->

<!--<div *ngIf="feedResource">-->
<div *hasWritePermission class="p-4 d-flex justify-content-around">
  <button (click)="createPost()" class="btn btn-primary rounded-circle">
    <i class="fa fa-plus"></i>
  </button>
</div>
@if (posts?.length) {
  <section class="section py-3" *ngFor="let post of posts">
    <div class="container py-5 post-container">
      <div class="row align-items-center">
        <div class="col-lg-10 offset-lg-1">
          <div class="">
            <app-post-standalone [postResource]="post"></app-post-standalone>
          </div>
<!--          <br>-->
          <div class="d-flex justify-content-end">
          <span class="mt-1 mt-mb-3"
                style="color: var(--hover-color); font-style: italic">{{ post.creationTimestamp | date }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
} @else {
  <div class="text-center fst-italic">No posts existing yet.</div>
}
<div #bottom style="height: 2vh"></div> <!-- Sentinel element -->
<!--</div>-->
