<app-community>
  <li sidenav *ngFor="let feed of feeds" class="nav-link">
    <a [href]="CommunityRoutes.feeds(community.creationTimestamp, community.uuid) +'#feed-'+ feed.resource.uuid" class="nav-link"
       routerLinkActive="nav-link-active" aria-current="page">{{ feed.resource.data.title }}</a>
  </li>
  <!-- Main Content -->
  <div main class="container">
    <h1 class="display-5 text-center pt-3 pb-5">Feeds</h1>
<!--    <div class="py-3"></div>-->
    <div *hasWritePermission class="p-5 d-flex justify-content-around">
      <button (click)="create()" class="btn btn-primary rounded-circle">
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <section class="section pt-lg-5 pb-5" *ngFor="let feed of feeds">
      <div *hasWritePermission class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary" (click)="setAccess(feed.resource)">Access Setup</button>
        <button type="button" class="btn btn-primary" (click)="edit(feed.resource)">Edit</button>
      </div>
      <div id="feed-{{feed.resource.uuid}}" class="container-fluid py-5 post-container router-link rounded-6" (click)="onFeedClick(feed)">
        <div class="row align-items-center">
          <div class="col-lg-10 offset-lg-1">
              <div class="d-flex justify-content-end mb-2">
                <app-access-level-indicator [accessRights]="feed.access"></app-access-level-indicator>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-4">
                <h1 class="display-6 mb-0">{{ feed.resource.data.title }}</h1>
              </div>
              <app-post-view *ngIf="feed.resource.data.description" [post]="feed.resource.data.description"></app-post-view>
          </div>
        </div>
      </div>
    </section>
  </div>
</app-community>
