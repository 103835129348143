<div class="content-container">
  @for (resource of selectableResources(); track resource.resource.uuid) {
    <div class="content-card rounded-3" (click)="toggleSelection(resource)" [class.selected]="resource.isSelected">
      <div class="content">
        <div class="title">{{ resource.resource.data.title }}</div>
        <!--            <div class="content-text">{{ element.data.content.elements}}</div>-->
        <div class="content-text">
          <app-post-view [post]="resource.resource.data.description"></app-post-view>
        </div>
        <div class="check-container">
          @if (!resource.isSelected) {
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px"
                   fill="#434143"><path
                d="M480.07-100q-78.43 0-147.67-29.92-69.24-29.92-120.89-81.54-51.64-51.63-81.58-120.84Q100-401.51 100-479.93q0-78.84 29.92-148.21t81.54-120.68q51.63-51.31 120.84-81.25Q401.51-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.43-29.92 147.67-29.92 69.24-81.21 120.89-51.29 51.64-120.63 81.58Q558.9-100 480.07-100Zm-.07-50.26q137.79 0 233.77-96.18 95.97-96.18 95.97-233.56 0-137.79-95.97-233.77-95.98-95.97-233.77-95.97-137.38 0-233.56 95.97-96.18 95.98-96.18 233.77 0 137.38 96.18 233.56T480-150.26ZM480-480Z"/></svg>
            </span>
          }
          @if (resource.isSelected) {
            @if (showSelectionOrder()) {
              <div class="selection-index d-flex justify-content-center align-items-center">{{resource.selectionIndex}}</div>
            } @else {
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px"
                     fill="#434143"><path
                  d="M480.07-100q-79.66 0-149.03-29.38t-120.81-80.8q-51.44-51.42-80.83-120.76Q100-400.28 100-479.93q0-79.25 29.39-148.62 29.38-69.37 80.81-120.81 51.43-51.44 120.78-81.04Q400.33-860 480-860q68.08 0 127.86 21.44 59.78 21.43 108.34 59.23l-35.94 36.79q-41.75-32.23-92.25-49.72-50.5-17.48-108.01-17.48-138.69 0-234.22 95.52-95.52 95.53-95.52 234.22t95.52 234.22q95.53 95.52 234.22 95.52t234.22-95.52q95.52-95.53 95.52-234.22 0-27.03-4.05-52.72-4.05-25.69-11.9-50.02l39.13-39.52q13.31 33.16 20.19 68.75Q860-517.92 860-480q0 79.67-29.59 149.02-29.58 69.35-81 120.78-51.42 51.43-120.76 80.81Q559.31-100 480.07-100Zm-58.33-210.92-153.59-154 36.62-36.87 116.97 116.97 401.39-401.59 37.28 36.62-438.67 438.87Z"/></svg>
              </span>
            }
          }
        </div>
      </div>
      <div class="footer">
        {{ getElementType(resource) }}
      </div>
    </div>
  }
</div>
