import { Injectable } from '@angular/core';
import { EditorService } from '../../editor.service';
import { ModalServiceStandAlone } from '../../../elements/generic-modal/modal-service-standalone.service';
import { InviteCommunityCreatorEditorComponent } from './invite-community-creator-editor.component';
import { RootResource } from '../../domain/resource';
import { CommunityProfile } from '../../domain/community-profile';

@Injectable({
  providedIn: 'root'
})
export class InviteCommunityCreatorInteractionService extends EditorService<RootResource<CommunityProfile>, InviteCommunityCreatorEditorComponent> {
  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, InviteCommunityCreatorEditorComponent, 'modal-dialog-centered');
  }
}
