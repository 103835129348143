import {NavigableElement} from "./navigable-element";
import {Resource} from "./resource";
import {ContentElement, ContentElements} from "./content-element";

export interface Collection extends ContentElement {

  // allow to groupBy
  kind: string;

  // actually part of type, also allowing to alter order <- restrict size
  chapters: Resource<NavigableElement>[];

  //List from database
  // posts: StandalonePost[];
}

export class Collections {

  static empty(): Collection {
    const element = ContentElements.empty();
    return {
      title: element.title,
      description: element.description,
      kind: 'unspecified',
      chapters: []
    }
  }
}
