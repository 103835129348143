import {
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  signal,
  ViewChild
} from '@angular/core';
import {CommunityBaseComponent} from "../../community-base/community-base.component";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {CommunityNavigationComponent} from "../../community-navigation/community-navigation.component";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {PostViewComponent} from "../../post/view/post-view.component";
import {Resource, Resources} from "../../domain/resource";
import {ProfileService} from "../../profile/profile.service";
import {AccessLevels, AccessSetupData, ResourceAccessService} from "../../../api/resource/resource-access.service";
import {Product} from '../../../api/product/domain/product';
import {Bundle} from '../../../api/product/domain/bundle';
import {ProductService} from '../product.service';
import {PostStandaloneComponent} from '../../post/standalone/post-standalone.component';
import {Post} from '../../domain/post';
import {
  catchError,
  concatMap,
  distinctUntilChanged, EMPTY, filter,
  forkJoin,
  map,
  Observable,
  of, pipe,
  ReplaySubject,
  Subject,
  Subscription,
  switchMap, take,
  tap, throwError
} from 'rxjs';
import {PostService} from '../../post/post.service';
import {PostInteractionService} from '../../post/post-interaction.service';
import {Instant} from '../../domain/instant';
import {HasWritePermissionDirective} from '../../access-control/has-write-permission.directive';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import {Feed} from '../../domain/feed';
import {FeedService} from '../../feed/feed.service';
import {CollectionService} from '../../collection/collection.service';
import {Collection} from '../../domain/collection';
import {CommunityRoutes, PURCHASE_SUCCESS_QUERY_PARAM} from '../../community.routes';
import {ResourceUtil} from '../../../utils/resource-util';
import {ProductInteractionService} from '../product-buy-modal/product-buy-interaction.service';
import {ContentKind} from '../../../api/user/domain/content-kind';
import {UserContentApiService} from '../../../api/user/user-content-api.service';
import {Offering} from '../../../api/product/domain/offering';
import {loadStripe} from '@stripe/stripe-js/pure';
import {environment} from '../../../../environments/environment';
import {
  AccessLevelIndicatorComponent
} from '../../../elements/access-level-indicator/access-level-indicator.component';
import {
  ProductAccessIndicatorComponent
} from '../../../elements/product-access-indicator/product-access-indicator.component';
import {Accessibility} from '../../../api/product/domain/accessibility';
import {Store} from '@ngrx/store';
import {MayAccess} from '../../../api/resource/domain/may-access';
import {SubscriptionPlan} from '../../../api/membership/domain/subscription-plan';
import {GetAccessToResourceModalComponent} from '../product-suggestions-modal/get-access-to-resource-modal.component';
import {
  GetAccessToResourceInteractionService
} from '../product-suggestions-modal/get-access-to-resource-interaction.service';
import {
  CommunityMembershipInteractionService
} from '../community-membership-modal/community-membership-interaction.service';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {ResourceWithAccess} from '../../feed/domain/resource-with-access';
import {HttpErrorResponse} from '@angular/common/http';
import {LoginModalService} from '../../../login/login-modal/login-modal.service';
import {CommunityApi} from "../../community.api";
import mayAccess = CommunityApi.mayAccess;

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    CommunityNavigationComponent,
    NgForOf,
    PostViewComponent,
    NgIf,
    DatePipe,
    PostStandaloneComponent,
    HasWritePermissionDirective,
    AccessLevelIndicatorComponent,
    ProductAccessIndicatorComponent,
    RouterLink
  ],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss'
})
export class ProductComponent extends CommunityBaseComponent implements OnDestroy {
  private readonly productService = inject(ProductService);
  private readonly feedService = inject(FeedService);
  private readonly collectionService = inject(CollectionService);
  private readonly resourceAccessService = inject(ResourceAccessService);
  private readonly productInteractionService = inject(ProductInteractionService);
  private readonly communityMembershipInteractionService = inject(CommunityMembershipInteractionService);
  private readonly userContentApiService = inject(UserContentApiService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly loginModalService = inject(LoginModalService);
  private readonly stripePromise = loadStripe(environment.stripeKey);

  product = toSignal<Resource<Product<Bundle>>>(this.productService.get(this.contentResourceId));

  productResources = toSignal<Resource<Feed | Collection>[]>(toObservable(this.product)
    .pipe(
      filter(product => !!product),
      switchMap(product => this.getFeedsAndCollections(product!))
    )
  );
  resourceAccessRights = toSignal<MayAccess[]>(toObservable(this.productResources)
    .pipe(
      filter(resources => !!resources),
      switchMap(resources => this.getResourceAccesses(resources!))
    )
  );

  productAccessRight = toSignal<MayAccess>(
    toObservable(this.product)
      .pipe(
        filter(product => !!product),
        switchMap(resourceId =>
          this.authenticationService.isAuthenticated().pipe(
            takeUntilDestroyed(this.destroyRef),
            filter(isAuthenticated => isAuthenticated === true),
            switchMap(() => this.productService.mayPurchaseProduct(resourceId!)),
            // catchError((error: HttpErrorResponse) => {
            //   if (error.status === 401) {
            //     return EMPTY; // todo: if this is not returned a lot of errors happen and the ui doesn't load properly. -> Find out why, this code might not be the right one to handle the situation
            //   } else {
            //     return throwError(() => error);
            //   }
            // })
          )
        )
      )
  );

  resources = computed<ResourceWithAccess<Feed | Collection>[]>(() => {
    if (this.productResources()) {
      return this.productResources()!.map((resource, index) => {
        return {
          resource: resource,
          access: this.resourceAccessRights()?.length ? this.resourceAccessRights()![index] : undefined
        } as ResourceWithAccess<Feed | Collection>
      })
    }

    return [];
  });

  requiredSubscriptionPlan = computed<SubscriptionPlan | undefined>(() => {
    if (this.productAccessRight()?.accessibility === Accessibility.AVAILABLE || this.productAccessRight()?.accessibility === Accessibility.NOT_AVAILABLE) {
      const minimumAccessLevel =
        // todo: Make this entirely failsafe by using the NO_MEMBERSHIP approach over undefined ?!
        // (
        Object.entries(this.productAccessRight()!.accessLevel)
          .find(([key, value]) => (value === Accessibility.AVAILABLE || value === Accessibility.INCLUDED))
      // ?? Object.entries(SubscriptionPlan.NO_MEMBERSHIP));

      return minimumAccessLevel ? minimumAccessLevel[0] as SubscriptionPlan : undefined;
    }
    return undefined;
  });

  // userRole = toSignal<CommunityUserRole>(this.store.select(selectCommunityRole));
  // userRole$ = toObservable(this.userRole);

  // hasAccessToProduct = computed(() => {
  //   if (this.productAccessRight()) {
  //     return this.productAccessRight()?.mayPurchase === Accessibility.INCLUDED || this.userRole() === CommunityUserRole.creator;
  //   }
  //   return false;
  // });

  showPurchaseSuccess = signal<boolean>(false);
  showPurchaseError = signal<boolean>(false);

  posts: Resource<Post>[] = [];
  loadFromResource!: Subject<any>;// = new ReplaySubject<Instant>(1);
  sliceSize = 25;
  fullyLoaded!: boolean;

  postsSubscription!: Subscription;
  // for infinite scroll
  observer!: IntersectionObserver;

  @ViewChild('bottom') bottom!: ElementRef;

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    accessService: ResourceAccessService,
    private postService: PostService,
    private postInteractionService: PostInteractionService,
    private router: Router
  ) {
    effect(() => {
      // console.log('#####', this.resources())
      // console.log('product', this.product());
      // console.log('productResources', this.productResources());
      // console.log('resourceAccessRights', this.resourceAccessRights());
      // console.log('productAccessRight', this.productAccessRight());
      // console.log('requiredSubscriptionPlan', this.requiredSubscriptionPlan());
    });
    // loadStripe.setLoadParameters({advancedFraudSignals: false});
    super(route, profileService, accessService);

    route.queryParams
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(queryParams => {
        this.showPurchaseSuccess.set(!!queryParams[PURCHASE_SUCCESS_QUERY_PARAM]);
      })
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.posts = [];
    if (this.observer) {
      this.observer.disconnect();
    }
    // this.loadFromResource.unsubscribe();
    // this.postsSubscription.unsubscribe();
  }

  onBuyClick() {
    this.authenticationService.isAuthenticated()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        switchMap(isAuthenticated => {
          const p = this.product();
          if (isAuthenticated && p) {
            return this.productService.mayPurchaseProduct(ResourceUtil.getResourceId(p)).pipe(
              tap(mayAccess => {
                if (mayAccess!.accessibility === Accessibility.AVAILABLE) {
                  this.productInteractionService.openModal(
                    this.product()?.data.title + ' kaufen',
                    this.product()?.data.offerings || null,
                    (offering) => this.processCheckout(offering)
                  );
                } else if (mayAccess!.accessibility === Accessibility.NOT_AVAILABLE) {
                  this.communityMembershipInteractionService.openModal('Update Membership', mayAccess, () => {
                  });
                }
              }));
          } else {
            this.loginModalService.signUpOrLoginAndDo(() => this.onBuyClick());
            return EMPTY;
          }
        }))
      .subscribe();
  }

  onResourceClick(resource: Resource<Feed | Collection>, resourceAccess: MayAccess | undefined) {
    if (!resourceAccess) {
      return
    }

    this.authenticationService
      .isAuthenticated()
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          if (resourceAccess.accessibility === Accessibility.INCLUDED) {
            this.routeToFeedOrCollection(resource);
          } else {
            this.onBuyClick();
          }
        } else {
          if (resourceAccess.accessibility === Accessibility.INCLUDED) {
            this.routeToFeedOrCollection(resource);
          } else {
            this.loginModalService.signUpOrLoginAndDo( () => this.onBuyClick());
          }
        }
      });
  }

  load() {
    console.log('loading product ...')
    this.fullyLoaded = false;
    this.loadFromResource = new ReplaySubject<Instant>(1);
    this.postsSubscription = this.loading();
    // todo: this should not be required
    this.loadMore();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    }, {threshold: 1.0});

    if (this.bottom) {
      this.observer.observe(this.bottom.nativeElement);
    }
  }

  loadMore() {
    console.log("loading more ... ")
    this.loadFromResource.next({
      assignmentId: this.product()!.uuid,
      timestamp: this.posts.length > 0 ? this.posts[this.posts.length - 1].creationTimestamp : Number.MAX_SAFE_INTEGER
    });
  }

  // todo: check if this may cause any race conditions and hence duplicates
  loading() {
    return this
      .loadFromResource
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        concatMap(instant => {
          // console.log("loading from : " + instant.timestamp);
          if (!this.fullyLoaded) {
            return this.postService
              .getSlice(instant, this.sliceSize, false)
              .pipe(
                map(posts => posts ? posts : []),
                tap(posts => this.fullyLoaded = posts.length < this.sliceSize)
              );
          } else {
            console.log("reached end");
            return of();
          }
        })
      )
      .subscribe((posts: Resource<Post>[]) => {
        this.posts.push(...posts);
      });
  }

  private getFeedsAndCollections(product: Resource<Product<Bundle>>): Observable<Resource<Feed | Collection>[]> {
    return forkJoin(
      product.data.resources.contentResources.map(resource => {
        if (resource.kind === ContentKind.feed) {
          return this.feedService.get(resource.resourceId);
        } else {
          return this.collectionService.get(resource.resourceId);
        }
      })
    )
  }

  private getResourceAccesses(resources: Resource<Feed | Collection>[]): Observable<MayAccess[]> {
    return this.authenticationService.isAuthenticated()
      .pipe(
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return forkJoin(resources.map(resource => this.resourceAccessService
              .getMayAccess(resource).pipe(
                catchError((error) => {
                  if (error.status === 401) {
                    return of();
                  }
                  throw error;
                })
              ))
            )
          } else {
            return forkJoin(resources.map(
              resource => this.resourceAccessService.getMayAccessPublic(resource)
            ))
          }
        })
      )
  }

  private getAccessLevels(product: Resource<Product<Bundle>>): Observable<AccessSetupData[]> {
    return forkJoin(product?.data.resources.contentResources
      .map(resource => this.resourceAccessService.getAccessLevel(resource.resourceId)));
  }

  private async processCheckout(offering: Offering[]) {
    this.showPurchaseError.set(false);
    this.showPurchaseSuccess.set(false);

    const stripe = await this.stripePromise;

    // todo: the checkout process should be implemented at only one place, i.e. a service
    this.userContentApiService
      .purchaseBundle(this.product()!, offering[0].prices.keys().next().value, this.communityId) // Todo return value of modal should not be the same as it's input value. Then only the price or even just the price uuid should be returned from the modal here
      .subscribe(async session => {
        const {error} = await stripe?.redirectToCheckout({sessionId: session.id})!;
        if (error) {
          console.error("Stripe Checkout Error:", error);
          this.showPurchaseError.set(true);
        }
      })
  }

  private routeToFeedOrCollection(resource: Resource<Feed | Collection>) {
    void this.router.navigate([this.getResourceRoute(resource)]);
  }

  private getResourceRoute(resource: Resource<Feed | Collection>): string {
    if (ResourceUtil.isCollection(resource)) {
      return CommunityRoutes.collection(this.community.creationTimestamp, this.community.uuid, resource.creationTimestamp, resource.uuid);
    } else {
      return CommunityRoutes.feed(this.community.creationTimestamp, this.community.uuid, resource.creationTimestamp, resource.uuid);
    }
  }

  protected readonly Accessibility = Accessibility;
  protected readonly CommunityRoutes = CommunityRoutes;
  protected readonly AccessLevel = AccessLevels;
}
