<form>
  <div class="form-group row g-1 mb-3">
    <div class="col-md-4">
      <label for="userEmail">User email *</label>
      <input id="userEmail" class="form-control" [(ngModel)]="userEmail" name="userEmail" required email>
    </div>
    <div class="col-md-4">
      <label for="firstName">First name</label>
      <input id="firstName" class="form-control" [(ngModel)]="firstName" name="firstName">
    </div>
    <div class="col-md-4">
      <label for="name">Name</label>
      <input id="name" class="form-control" [(ngModel)]="name" name="name">
    </div>
  </div>
  <div class="form-group row g-1 mb-3">
    <div class="col-md-6">
      <label for="userPlan">User plan *</label>
      <select id="userPlan" class="form-select" [(ngModel)]="userPlan" name="userPlan" required>
        <option *ngFor="let plan of Object.keys(SubscriptionPlan)" [value]="plan">{{ plan }}</option>
      </select>
    </div>
    <div class="col-md-6">
      <label for="subscriptionEndDate">Subscription end date</label>
      <input #datePickerInput id="subscriptionEndDate" class="form-control" [(ngModel)]="subscriptionEndDate"
             name="subscriptionEndDate">
    </div>
  </div>
  <div>
    <div>Grant access to content</div>
    <app-resource-selector [resources]="(contentElements$ | async) || []"
                           (selectionChange)="onResourceSelectionChange($event)"></app-resource-selector>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="isSubmitDisabled()">Save</button>
  </div>
</form>
