<div class="d-flex justify-content-end mt-2">
  <button type="button" class="btn btn-secondary me-1" #callToActionButton (click)="addCallToActionButton()">Call To Action Button
  </button>
  <button type="button" class="btn btn-secondary me-1" #linkButton (click)="addLinkButton()">Link Button
  </button>
  <button type="button" class="btn btn-secondary me-1" #youTubeButton (click)="addYouTubeVideo()">YouTube Video
  </button>
  <button type="button" class="btn btn-secondary me-1" (click)="addDownloadButton()">PDF Download</button>
  <button type="button" class="btn btn-secondary me-1" (click)="addImage()">Image</button>
  <button type="button" class="btn btn-secondary me-1" (click)="addAudio()">Audio</button>
  <button type="button" class="btn btn-secondary" #addVideoButton (click)="addVideo()">Video</button>
</div>
<div class="form-group">
  <textarea id="content" class="form-control" appShowScrollBarOnScroll [(ngModel)]="markdownContent"
            (ngModelChange)="onChange()" name="content" rows="30" required></textarea>
</div>
@if (showCallToActionButtonForm) {
  <app-link-button-form [referenceElement]="callToActionButton" (linkButtonAdded)="onCallToActionButtonAdded($event)"
                        (closed)="onCallToActionButtonFormClosed()"></app-link-button-form>
}
@if (showLinkButtonForm) {
  <app-link-button-form [referenceElement]="linkButton" (linkButtonAdded)="onLinkButtonAdded($event)"
                        (closed)="onLinkButtonFormClosed()"></app-link-button-form>
}
@if (showYouTubeUrlForm) {
  <app-youtube-url-form [referenceElement]="youTubeButton" (urlAdded)="onYouTubeUrlAdded($event)"
                        (closed)="onYouTubeUrlFormClosed()"></app-youtube-url-form>
}
@if (showVideoForm) {
  <app-video-form [referenceElement]="addVideoButton" (videoAdded)="onVideoFormAdded($event)"
                        (closed)="onVideoFormClosed()"></app-video-form>
}
