import {Injectable} from '@angular/core';
import {CollectionEditorComponent} from "./editor/collection-editor.component";
import {ModalServiceStandAlone} from "../../elements/generic-modal/modal-service-standalone.service";
import {Collection} from "../domain/collection";
import {ResourceInteractionService} from "../resource-Interaction.service";
import {CollectionService} from "./collection.service";

@Injectable({
  providedIn: 'root'
})
export class CollectionInteractionService extends ResourceInteractionService<Collection, CollectionEditorComponent> {

  constructor(modalService: ModalServiceStandAlone, collectionService: CollectionService) {
    super(modalService, collectionService, 'Collection', CollectionEditorComponent, 'modal-dialog-centered modal-xxl');
  }
}
