import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GenericElementComponent} from "../../../generic-element/generic-element.component";
import {NgForOf, NgIf} from "@angular/common";
import {Post} from "../../domain/post";

@Component({
  selector: 'app-post-view',
  standalone: true,
  imports: [
    FormsModule,
    GenericElementComponent,
    NgForOf,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './post-view.component.html',
  styleUrl: './post-view.component.scss'
})
export class PostViewComponent { // implements OnInit {

  @Input() post: Post | undefined;
  // @Input() postReference!: ResourceId;

  constructor(){ //, private postService: PostService) {
  }

  // // ngOnInit(): void {
  // //   if (!this.postResource) {
  // //     if (this.postReference) {
  // //       this.postService.get(this.postReference).subscribe(r => this.postResource = r);
  // //     } else {
  // //       alert('Post resource missing. Congratulations, you found a bug. Please report.')
  // //     }
  // //   }
  // }
}
