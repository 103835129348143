<div class="d-flex justify-content-end" *hasWritePermission>
  <div class="btn btn-primary" type="button" (click)="onEditClick()">Edit</div>
</div>
<div class="card-container d-flex flex-column gap-3 h-100 py-5 px-2 px-xl-4 px-xxl-5 rounded-5">
  @if (isActive()) {
    <app-text-tile class="indicator">Dein Plan</app-text-tile>
  }
  @if (showRequired()) {
    <app-text-tile class="indicator"><i class="fa fa-certificate"></i> Benötigt</app-text-tile>
  }
  <div class="title text-center">{{ title() }}</div>
  @if (showDescription()) {
    <div class="description small">
      <app-generic-element [data]="subscriptionPlan().data.description.elements[0]"></app-generic-element>
    </div>
  }
  <div class="prices-container mt-auto d-flex justify-content-around gap-3">
    @if (plan() === SubscriptionPlan.FREE) {
      <button class="join-free-button btn btn-primary mx-auto" type="button" (click)="onJoinFreeClick()" [disabled]="joinDisabled()">Join Free</button>
    } @else {
      @for (price of subscriptionPlan().data.offerings[0].prices | keyvalue: originalOrder; track price.key) {
        <div class="price-container d-flex flex-column">
          <div class="price-title">{{ price.value.description }}</div>
          <div class="price mt-2">
            <span class="amount">{{ (price.value.amount | currency: '' : '' : '1.0-2' || '-') }} {{ getCurrencySymbol(price.value.currency) }} </span>
            <br>
            <span class="period">/ Monat</span>
          </div>
          <div class="price-info my-2">
            {{ price.value.period === Period.year ? 'jährliche Abrechnung' : 'monatliche Abrechnung' }}
          </div>
          <button class="join-button btn btn-primary mt-3" type="button" (click)="onJoinClick(price)" [disabled]="joinDisabled()">Join</button>
        </div>
      }
    }
  </div>
</div>
