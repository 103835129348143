export enum MarkdownTag {
  markdown = 'markdown',
  text = 'text',
  video = 'video',
  image = 'image',
  audio = 'audio',
  downloadButton = 'downloadButton',
  youTubeVideo = 'youTubeVideo',
  callToActionButton = 'callToActionButton',
  linkButton = 'linkButton'
}
