<form>
  <div class="form-group">
    <label for="title">Title</label>
    <input id="title" class="form-control mb-3" [(ngModel)]="chapter.title" name="title" required>
    <app-post-editor #postEditor [post]="chapter.content"
                     [accessDelegate]="resourceAccessService.getContentDelegate.bind(resourceAccessService)"
                     (hasChanges)="onPostEditorHasChanges($event)"></app-post-editor>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
  </div>
</form>
