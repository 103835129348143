import {inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from '@angular/router';
import {ResourceUtil} from '../utils/resource-util';
import {CommunityRoutes} from '../community/community.routes';
import {RouteUtil} from '../utils/route-util';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthenticationService {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  // private readonly resourceAccessService = inject(ResourceAccessService);

  logoutAndRoute() {
    this.logout()
      .then(() => this.reloadOrRouteToCommunityHome());
  }

  abstract signUp(email: string, password: string, firstName?: string, name?: string): Observable<void>;

  abstract login(email: string, password: string): Promise<void>;

  abstract logout(): Promise<void>;

  abstract resetPassword(email: string): Promise<void>;

  abstract isAuthenticated(): Observable<boolean>;

  // todo: rethink this again. At the moment, simplified to avoid circular dependency with AccessService
  private reloadOrRouteToCommunityHome() {
    const routeWithParameters = RouteUtil.getRootRouteWithParameters(this.activatedRoute);
    const resourceId = ResourceUtil.getResourceIdFromUrl(routeWithParameters.snapshot);
    if (resourceId) {
      const rootResourceId = ResourceUtil.getRootResourceIdFromUrl(routeWithParameters.snapshot);
      void this.router.navigate([CommunityRoutes.community(rootResourceId!.creationTimestamp, rootResourceId!.uuid)]).then()
      // this.resourceAccessService.getAccessLevel(resourceId)
      //   .pipe(
      //     take(1)
      //   ).subscribe(accessLevelData => {
      //   if (ResourceUtil.hasUserAccess(CommunityUserRole.visitor, accessLevelData.accessLevel)) {
      //     window.location.reload();
      //   } else {
      //     const rootResourceId = ResourceUtil.getRootResourceIdFromUrl(routeWithParameters.snapshot);
      //     void this.router.navigate([CommunityRoutes.community(rootResourceId!.creationTimestamp, rootResourceId!.uuid)])
      //   }
      // })
    } else {
      window.location.reload();
    }
  }
}
