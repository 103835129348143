import { Component, inject, OnInit } from '@angular/core';
import { EditorComponent } from '../../editor-component';
import { ModalExchange } from '../../../elements/generic-modal/modal-exchange';
import { Offering, Offerings } from '../../../api/product/domain/offering';
import { Price } from '../../../api/product/domain/price';
import { KeyValue, KeyValuePipe } from '@angular/common';
import { Period } from '../../../api/product/domain/period';
import { Currency } from '../../../api/product/domain/currency';
import { SubscriptionService } from '../../community-subscription/subscription.service';

@Component({
  selector: 'app-product-buy-modal',
  standalone: true,
  imports: [
    KeyValuePipe
  ],
  templateUrl: './product-buy-modal.component.html',
  styleUrl: './product-buy-modal.component.scss'
})
export class ProductBuyModalComponent implements EditorComponent<Offering[]>, OnInit {
  private readonly subscriptionService = inject(SubscriptionService);
  protected readonly Period = Period;

  data: ModalExchange<Offering[], Offering[]> | undefined;
  noPricesDefined = false;
  prices!: Map<string, Price>;

  ngOnInit() {
    if (!this.data?.input?.length || !this.data?.input?.some(offering => offering.prices?.size)) {
      this.noPricesDefined = true;
      this.prices = new Map();
    } else {
      this.prices = this.data.input[0].prices;
    }
  }

  getPriceTitle(price: Price): string {
    if (price.period === Period.oneTime) {
      if (price.tryoutPeriodInDays) {
        return `Einmal-Kauf: ${price.tryoutPeriodInDays} Monate`;
      } else {
        return 'Einmal-Kauf: unbegrenzte Zeitdauer';
      }
    } else if (price.period === Period.month) {
      return 'Monatlich';
    } else if (price.period === Period.year) {
      return  'Jährlich';
    }

    return '';
  }

  getCurrencySymbol(currency: Currency): string {
    return this.subscriptionService.getCurrencySymbol(currency);
  }

  onBuyClick(price: KeyValue<string, Price>) {
    console.log('clicked Buy', price);
    const returnData = [Offerings.empty()];
    returnData[0].prices.set(price.key, price.value);
    this.data?.callback(returnData);
  }
}
