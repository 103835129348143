import { Injectable } from '@angular/core';
import { EditorService } from '../../editor.service';
import { Resource } from '../../domain/resource';
import { ModalServiceStandAlone } from '../../../elements/generic-modal/modal-service-standalone.service';
import { Product } from '../../../api/product/domain/product';
import { CommunityMembership } from '../../../api/membership/domain/community-membership';
import { SubscriptionEditorComponent } from './subscription-editor.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionInteractionService extends EditorService<Resource<Product<CommunityMembership>>, SubscriptionEditorComponent> {
  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, SubscriptionEditorComponent, 'modal-dialog-centered');
  }
}
