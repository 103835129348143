import {Injectable} from '@angular/core';
import {EditorService} from "../../editor.service";
import {ChapterEditorComponent} from "./chapter-editor.component";
import {ModalServiceStandAlone} from "../../../elements/generic-modal/modal-service-standalone.service";
import {Chapter} from "./Chapter";

@Injectable({
  providedIn: 'root'
})
export class ChapterEditorService extends EditorService<Chapter, ChapterEditorComponent> {

  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, ChapterEditorComponent, 'modal-dialog-centered modal-xxl');
  }
}
