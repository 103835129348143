import { Injectable } from '@angular/core';
import {ResourceService} from "../resource.service";
import {Post} from "../domain/post";

@Injectable({
  providedIn: 'root'
})
export abstract class PostService extends ResourceService<Post> {

  constructor() {
    super()
  }
}
