import { ContentDescriptor } from './content-descriptor';

export interface Bundle {
  contentResources: ContentDescriptor[];
}

export class Bundles {
  static empty(): Bundle {
    return {
      contentResources: []
    };
  }
}
