import { Component, OnInit } from '@angular/core';
import {
    CommunitySubscriptionComponent
} from '../../community-subscription/community-subscription/community-subscription.component';
import { NgIf } from '@angular/common';
import { PostViewComponent } from '../../post/view/post-view.component';
import { EditorComponent } from '../../editor-component';
import { MayAccess } from '../../../api/resource/domain/may-access';
import { SubscriptionPlan } from '../../../api/membership/domain/subscription-plan';
import { ModalExchange } from '../../../elements/generic-modal/modal-exchange';
import { Accessibility } from '../../../api/product/domain/accessibility';

@Component({
  selector: 'app-community-membership-modal',
  standalone: true,
    imports: [
        CommunitySubscriptionComponent,
        NgIf,
        PostViewComponent
    ],
  templateUrl: './community-membership-modal.component.html',
  styleUrl: './community-membership-modal.component.scss'
})
export class CommunityMembershipModalComponent implements EditorComponent<MayAccess>, OnInit {

  data: ModalExchange<MayAccess, MayAccess> | undefined;
  accessRights!: MayAccess;
  requiredPlan!: SubscriptionPlan;

  ngOnInit() {
    if (this.data?.input) {
      this.accessRights = this.data.input;
      this.requiredPlan = Object.entries(this.accessRights.accessLevel)
        .find(([key, value]) => value === Accessibility.AVAILABLE || value === Accessibility.INCLUDED)![0] as SubscriptionPlan
    }
  }
}
