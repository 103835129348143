import {Observable} from "rxjs";
import {RootResource, RootResourceId} from "./domain/resource";

export abstract class RootResourceService<T> {

  abstract getSlice(timestamp: number, count: number, ascending: boolean): Observable<RootResource<T>[]>;

  getAll(ascending: boolean): Observable<RootResource<T>[]> {
    const start = ascending ? 0 : Number.MAX_SAFE_INTEGER;
    return this.getSlice(start, Number.MAX_SAFE_INTEGER, ascending)
  }

  abstract get(resourceId: RootResourceId): Observable<RootResource<T>>;

  abstract create(t: T): Observable<RootResource<T>>;

  abstract save(resource: RootResource<T>): Observable<RootResource<T>>;

}
