import { Component, inject } from '@angular/core';
import { EditorComponent } from '../../editor-component';
import { ModalExchange } from '../../../elements/generic-modal/modal-exchange';
import { RootResource } from '../../domain/resource';
import { CommunityProfile } from '../../domain/community-profile';
import { FormsModule } from '@angular/forms';
import { ValidatorUtil } from '../../../utils/validator-util';
import { AccessSetupApiService } from '../../../api/user/access-setup-api.service';
import { UserApiService } from '../../../api/user/user-api.service';
import { InviteUser } from '../../../api/user/domain/invite-user';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-invite-community-creator-editor',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './invite-community-creator-editor.component.html',
  styleUrl: './invite-community-creator-editor.component.scss'
})
export class InviteCommunityCreatorEditorComponent implements EditorComponent<RootResource<CommunityProfile>> {
  private readonly userAccessApiService = inject(AccessSetupApiService);
  private readonly userApiService = inject(UserApiService);
  data!: ModalExchange<RootResource<CommunityProfile>, RootResource<CommunityProfile>>;
  userEmail!: string;
  // firstName?: string;
  // name?: string;

  isSubmitDisabled(): boolean {
    return !ValidatorUtil.isEmail(this.userEmail);
  }

  submit() {
    const userInviteData: InviteUser = {
      // firstName: this.firstName,
      // name: this.name,
      email: this.userEmail,
      communityId: this.data.input!
    }

    this.userApiService.invite(userInviteData)
      .pipe(
        switchMap(() => this.userAccessApiService.addCreatorToCommunity(this.userEmail, this.data.input!))
      )
      .subscribe();

    this.data.callback(this.data.input!);
  }
}
