import { Offering } from './offering';
import { Post, Posts } from '../../../community/domain/post';
import { Bundle, Bundles } from './bundle';

export interface Product<T> {
  title: string;
  description: Post; // todo: this should be "content". Then Product would also extend ContentElement
  resources: T;
  offerings: Offering[];
}

export class Products {
  static emptyBundle(): Product<Bundle> {
    return {
      title: '',
      description: Posts.empty(),
      resources: Bundles.empty(),
      offerings: []
    }
  }
}
