import {ModalServiceStandAlone} from "../elements/generic-modal/modal-service-standalone.service";
import {EditorComponent} from "./editor-component";

export abstract class EditorService<TInputData, TEditorComponent extends EditorComponent<TInputData>> {

  protected constructor(
    private modalService: ModalServiceStandAlone,
    private component: new (...args: any[]) => TEditorComponent,
    private dialogClasses: string
  ) {
  }

  // todo: this should probably better return Observable<T> instead of a callback
  openModal(title: string, input: TInputData | null, action: (result: TInputData) => void) {
    this.modalService.open<TEditorComponent>(
      this.component,
      {
        title: title,
        dialogClasses: this.dialogClasses,
        exchange: {
          data: {
            input: input,
            callback:
              (t: TInputData) => {
                this.modalService.closeBySave().then(() => action(t));
              }
          }
        } as Partial<TEditorComponent>
      }
    );
  }
}
