<form>
  <ul class="nav nav-tabs" id="productEditorTabs" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="cover-tab" data-bs-toggle="tab" data-bs-target="#cover-tab-pane" type="button"
              role="tab" aria-controls="cover-tab-pane" aria-selected="true">Cover
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="content-tab" data-bs-toggle="tab" data-bs-target="#content-tab-pane" type="button"
              role="tab" aria-controls="content-tab-pane" aria-selected="false">Content
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="offers-tab" data-bs-toggle="tab" data-bs-target="#offers-tab-pane" type="button"
              role="tab" aria-controls="offers-tab-pane" aria-selected="false">Offers
      </button>
    </li>
  </ul>
  <div class="tab-content" id="productEditorTabsContent">
    <div class="cover-tab tab-pane fade show active" id="cover-tab-pane" role="tabpanel" aria-labelledby="cover-tab"
         tabindex="0">
      <div class="description text-black-50 mt-2 mb-3">
        Style das Cover für dein Produkt um deinen Followern ein Überblick über die Inhalte zu geben. Das Cover wird auf
        der Products Seite zu sehen sein.
      </div>
      <div class="form-group">
        <label for="title">Title</label>
        <input id="title" class="form-control" [(ngModel)]="product.title" name="title" required>
      </div>
      <div class="post-editor">
        <app-post-editor
          #postEditor
          [post]="product.description"
          [accessDelegate]="resourceAccessService.getCommunityDelegate.bind(resourceAccessService)"
          (hasChanges)="onPostEditorHasChanges($event)"
        ></app-post-editor>
      </div>
    </div>
    <div class="content-tab tab-pane fade" id="content-tab-pane" role="tabpanel" aria-labelledby="content-tab"
         tabindex="0">
      <div class="description text-black-50 mt-2 mb-3">
        Wähle hier den Inhalt deines Produktes aus. Dir stehen alle Feeds und Collections der Community zur Verfügung.
      </div>

      <div>Select Content</div>
      <app-resource-selector [resources]="(allCommunityResources | async) || []" [showSelectionOrder]="true"
                             [selectedResourceUuids]="initialSelectedResourceUuids"
                             (selectionChange)="onResourceSelectionChange($event)"></app-resource-selector>
    </div>
    <div class="offer-tab tab-pane fade" id="offers-tab-pane" role="tabpanel" aria-labelledby="offers-tab" tabindex="0">
      <div class="description text-black-50 mt-2 mb-3">
        Erstelle hier Angebote für dein Produkt. Du hast die Möglichkeit Einmal-Käufe oder Abonnements anzubieten und
        Angebote zu aktivieren und deaktivieren.
      </div>

      <div class="offer-title fw-medium">Angebot</div>
      <label for="offer-1-title">Title *</label>
      <input id="offer-1-title" class="form-control" [(ngModel)]="product.offerings[0].description" name="offer-1-title"
             required>

      <div class="form-group row g-1 mb-3">
        <div class="col-md-3">
          <label for="offer-1-price-1-amount">Preis *</label>
          <input id="offer-1-price-1-amount" class="form-control" [(ngModel)]="price.amount"
                 name="offer-1-price-1-amount" required email>
        </div>
        <div class="col-md-3">
          <label for="offer-1-price-1-currency">Währung *</label>
          <select id="offer-1-price-1-currency" class="form-select" [(ngModel)]="price.currency"
                  name="offer-1-price-1-currency" required>
            <option *ngFor="let item of Object.values(Currency)" [value]="item">{{ item }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="offer-1-price-1-type">Typ *</label>
          <select id="offer-1-price-1-type" class="form-select" [(ngModel)]="price.period" name="offer-1-price-1-type"
                  required>
            <option *ngFor="let item of Object.values(Period)" [value]="item">{{ item }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="offer-1-price-1-period-amount">Zeitraum in Monaten</label>
          <input id="offer-1-price-1-period-amount" class="form-control" [(ngModel)]="price.tryoutPeriodInDays"
                 name="offer-1-price-1-period-amount" aria-describedby="period-amount-help-1" [disabled]="isPeriodDisabled(price)">
          <div id="period-amount-help-1" class="form-text">Leer oder 0 = Unbegrenzt</div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
  </div>
</form>
