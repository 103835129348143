import {ModalServiceStandAlone} from "../elements/generic-modal/modal-service-standalone.service";
import {Resource, RootResource} from "./domain/resource";
import {EditorComponent} from "./editor-component";
import {ResourceService} from "./resource.service";
import {EditorService} from "./editor.service";
import {RootResourceService} from "./root-resource.service";
import { catchError, tap, throwError } from "rxjs";
import { inject } from '@angular/core';
import { NotificationService } from '../services/notification.service';

export abstract class RootResourceInteractionService<T, S extends EditorComponent<T>> extends EditorService<T, S> {
  private readonly notificationService = inject(NotificationService);

  protected constructor(
    modalService: ModalServiceStandAlone,
    private resourceService: RootResourceService<T>,
    private resourceName: string,
    component: new (...args: any[]) => S,
    dialogClasses: string
  ) {
    super(modalService, component, dialogClasses)
  }

  create(doWithSubmitResponse: (response: RootResource<T>) => void, initialData?: T) {
    this.openModal(
      'Create ' + this.resourceName,
      initialData || null,
      modalData => this.resourceService.create(modalData)
        .pipe(
          catchError(err => {
            this.notificationService.showGeneralSaveError();
            this.create(doWithSubmitResponse, modalData);
            return throwError(() => err);
          })
        ).subscribe(doWithSubmitResponse)
    )
  }

  edit(existing: RootResource<T>, consumer: (response: RootResource<T>) => void) {
    this.openModal(
      'Edit ' + this.resourceName,
      existing?.data,
      modalData => {
        const requestData: RootResource<T> = {creationTimestamp: existing.creationTimestamp, uuid: existing.uuid, data: modalData};
        this.resourceService
          .save(requestData)
          .pipe(
            catchError(err => {
              this.notificationService.showGeneralSaveError();
              this.edit(requestData, consumer);
              return throwError(() => err);
            })
          )
          .subscribe(consumer)
      }
    )
  }
}
