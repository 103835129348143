import {Injectable} from '@angular/core';
import {FeedEditorComponent} from "./editor/feed-editor.component";
import {ModalServiceStandAlone} from "../../elements/generic-modal/modal-service-standalone.service";
import {Feed} from "../domain/feed";
import {ResourceInteractionService} from "../resource-Interaction.service";
import {FeedService} from "./feed.service";

@Injectable({
  providedIn: 'root'
})
export class FeedInteractionService extends ResourceInteractionService<Feed, FeedEditorComponent> {

  constructor(modalService: ModalServiceStandAlone, feedService: FeedService) {
    super(modalService, feedService, 'Feed', FeedEditorComponent, 'modal-dialog-centered modal-xxl');
  }
}
