<app-community>
  <div main class="container">
    <div *hasWritePermission class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-1" (click)="onInviteCommunityCreatorButtonClick(community)">Invite Creator</button>
      <button type="button" class="btn btn-secondary me-1" (click)="onManageUserAccessButtonClick(community)">Manage User Access</button>
      <button type="button" class="btn btn-primary" (click)="edit(community)">Edit</button>
    </div>
    <h1 class="display-5 py-5 text-center">{{ community?.data?.title }}</h1>
    <!--    todo: maybe use something like a more button here-->
    <div *ngIf="community" class="pt-lg-5 pb-5">
      <app-post-view [post]="community.data.description"></app-post-view>
    </div>
  </div>
</app-community>
