import {ElementData} from "../../generic-element/elementData";

export interface Post {
  //todo: might be moved elsewhere, since not required in feeds and descriptions
  // title: string;
  elements: ElementData[];
}

export class Posts {

  static empty(): Post {
    return {elements: []};
  }
}

