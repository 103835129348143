import {Injectable} from '@angular/core';
import {ProfileEditorComponent} from "./editor/profile-editor.component";
import {ModalServiceStandAlone} from "../../elements/generic-modal/modal-service-standalone.service";
import {CommunityProfile} from "../domain/community-profile";
import {ProfileService} from "./profile.service";
import {RootResourceInteractionService} from "../root-resource-Interaction.service";

@Injectable({
  providedIn: 'root'
})
export class ProfileInteractionService extends RootResourceInteractionService<CommunityProfile, ProfileEditorComponent> {

  constructor(modalService: ModalServiceStandAlone, profileService: ProfileService) {
    super(modalService, profileService, 'Community', ProfileEditorComponent, 'modal-dialog-centered modal-xxl');
  }
}
