import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EditorComponent} from "../../editor-component";
import {ModalExchange} from "../../../elements/generic-modal/modal-exchange";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PostEditorComponent} from "../../post/editor/post-editor.component";
import {Chapter} from "./Chapter";
import {Posts} from "../../domain/post";
import {ResourceAccessService} from "../../../api/resource/resource-access.service";
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-chapter',
  standalone: true,
  imports: [
    FormsModule,
    PostEditorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './chapter-editor.component.html',
  styleUrl: './chapter-editor.component.scss'
})
export class ChapterEditorComponent implements OnInit, EditorComponent<Chapter> {
  private postEditorHasChanges: boolean = false;

  @Input() data!: ModalExchange<Chapter, Chapter>;
  @ViewChild('postEditor') postEditor!: PostEditorComponent;

  chapter: Chapter = {title: '', content: Posts.empty()};

  constructor(
    protected resourceAccessService: ResourceAccessService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.chapter = Object.assign({}, this.data.input);
    }
  }

  submit() {
    this.data.callback({
      title: this.chapter.title,
      content: this.postEditor.generatePost()
    });
  }

  onPostEditorHasChanges(hasChanges: boolean) {
    this.postEditorHasChanges = hasChanges
  }

  beforeCancel(): boolean {
    if (this.hasChanges()) {
      return this.notificationService.showConfirmation();
    }
    return true;
  }

  private hasChanges(): boolean {
    return this.postEditorHasChanges || this.chapter.title !== (this.data?.input?.title || '');
  }
}
