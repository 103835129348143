@if (!showJoinButton()) {
  <app-text-tile [theme]="theme()" [roundedEnds]="true">{{ userRoleStr() }}</app-text-tile>
} @else {
<!--  todo: keep hover color after click-->
  <div class="join-button mt-2 d-flex justify-content-center align-items-center highlight-border">
    <span class="">Join</span>
  </div>
<!--  <a [routerLink]="CommunityRoutes.membership(communityId().creationTimestamp, communityId().uuid)"-->
<!--     class="join-button mt-2 d-flex justify-content-center align-items-center highlight-border"-->
<!--  >-->
<!--    <span class="">Join</span>-->
<!--  </a>-->
}
