import { Component, DestroyRef, inject } from '@angular/core';
import { CommunityNavigationComponent } from "../../community-navigation/community-navigation.component";
import { CommonModule, NgForOf } from "@angular/common";
import { PostViewComponent } from "../../post/view/post-view.component";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { CommunityBaseComponent } from "../../community-base/community-base.component";
import { Resource } from "../../domain/resource";
import { forkJoin, Observable, of, Subscription, switchMap, tap } from "rxjs";
import { Collection } from "../../domain/collection";
import { CollectionService } from "../collection.service";
import { CollectionInteractionService } from "../collection-interaction.service";
import { CommunityRoutes } from "../../community.routes";
import { ProfileService } from "../../profile/profile.service";
import { ResourceAccessInteractionService } from "../../access-control/resource-access-interaction.service";
import { Feed } from "../../domain/feed";
import { AccessLevels, ResourceAccessService } from "../../../api/resource/resource-access.service";
import { HasWritePermissionDirective } from "../../access-control/has-write-permission.directive";
import { UserContentApiService } from '../../../api/user/user-content-api.service';
import {
  AccessLevelIndicatorComponent
} from '../../../elements/access-level-indicator/access-level-indicator.component';
import { NotificationService } from '../../../services/notification.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CommunityUserRole } from '../../../api/user/domain/community-user-role';
import { selectCommunityRole } from '../../../store/community/community.selectors';
import { Store } from '@ngrx/store';
import {
  GetAccessToResourceInteractionService
} from '../../product/product-suggestions-modal/get-access-to-resource-interaction.service';
import { ResourceWithAccess } from '../../feed/domain/resource-with-access';
import { Accessibility } from '../../../api/product/domain/accessibility';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { LoginModalService } from '../../../login/login-modal/login-modal.service';
import { MayAccess } from '../../../api/resource/domain/may-access';

@Component({
  selector: 'app-collections',
  standalone: true,
  imports: [
    CommonModule,
    CommunityNavigationComponent,
    NgForOf,
    PostViewComponent,
    RouterLink,
    HasWritePermissionDirective,
    RouterLinkActive,
    AccessLevelIndicatorComponent
  ],
  templateUrl: './collections.component.html',
  styleUrl: './collections.component.scss'
})
export class CollectionsComponent extends CommunityBaseComponent {
  private readonly store = inject(Store);
  private readonly resourceAccessService = inject(ResourceAccessService);
  private readonly getResourceAccessInteractionService = inject(GetAccessToResourceInteractionService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly loginModalService = inject(LoginModalService);

  CommunityRoutes = CommunityRoutes;
  collections!: ResourceWithAccess<Collection>[];
  accessLevel?: AccessLevels;

  userRole = toSignal<CommunityUserRole>(this.store.select(selectCommunityRole));

  loadSubscription!: Subscription;

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private collectionService: CollectionService,
    private collectionInteractionService: CollectionInteractionService,
    private accessInteractionService: ResourceAccessInteractionService,
    accessService: ResourceAccessService,
    private router: Router,
    // private userContentApiService: UserContentApiService,
    private notificationService: NotificationService
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.load();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
  }

  onCollectionClick(collection: ResourceWithAccess<Collection>) {
    // if (!collection.access) { // todo: show loader until access is available instead of doing nothing
    //   return;
    // }

    this.routeToCollection(collection.resource);

    // this.authenticationService.isAuthenticated()
    //   .pipe(
    //     takeUntilDestroyed(this.destroyRef)
    //   )
    //   .subscribe(isAuthenticated => {
    //     if (isAuthenticated) {
    //       if (!collection.access || collection.access.accessibility === Accessibility.INCLUDED) {
    //         this.routeToCollection(collection.resource);
    //       } else {
    //         this.getResourceAccessInteractionService.openModal(collection.resource.data.title, collection, () => {});
    //       }
    //     } else {
    //       if (collection.access.accessibility === Accessibility.INCLUDED) {
    //         this.routeToCollection(collection.resource);
    //       } else {
    //         this.loginModalService.signUpOrLogin();
    //         // this.loginModalService.loginAndRoute(
    //         //   CommunityRoutes.collection(this.community.creationTimestamp, this.community.uuid, collection.resource.creationTimestamp, collection.resource.uuid)
    //         // );
    //       }
    //     }
    //   });
  }

  createCollection() {
    this.collectionInteractionService.create(this.community.uuid, collectionResource => {
      // firstValueFrom(this.postService.create(collectionResource.id, Posts.empty()))
      //   .then(r => this.loadCollections())
      this.load();
      this.setAccess(collectionResource)
    })
  }

  edit(collectionResource: Resource<Collection>) {
    this.collectionInteractionService.edit(collectionResource, r => this.load());
  }

  setAccess(feedResource: Resource<Feed>) {
    this.accessInteractionService.setAccessLevel(feedResource, newAccessLevel => {
      this.accessLevel = newAccessLevel;
    }); //this.load());
  }

  load() {
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();

    this.loadSubscription = this.withCommunity()
      // this.profileService.activeCommunity()
      .pipe(
        switchMap(c => this.collectionService.load(c.uuid)),
        tap(collections => this.collections = collections.map(collection =>
          ({resource: collection} as ResourceWithAccess<Collection>))
        ),
        switchMap(collections => this.authenticationService.isAuthenticated()),
        switchMap(isAuthenticated => this.loadResourceAccesses(isAuthenticated))
      )
      .subscribe()
  }

  private loadResourceAccesses(isAuthenticated: boolean): Observable<void> {
    const loader = isAuthenticated
      ? this.resourceAccessService.getMayAccess.bind(this.resourceAccessService)
      : this.resourceAccessService.getMayAccessPublic.bind(this.resourceAccessService);
    return forkJoin(this.collections.map(feed => loader(feed.resource)))
      .pipe(
        tap(accessRights =>
          this.collections.forEach((feed, index) => feed.access = accessRights[index])),
        switchMap(accessRights => of(void 0))
      )
  }

  private routeToCollection(collection: Resource<Collection>) {
    this.router.navigate([CommunityRoutes.collection(this.community.creationTimestamp, this.community.uuid, collection.creationTimestamp, collection.uuid)])
      .then(
      //   (hasEnteredRoute) => {
      //   if (hasEnteredRoute) {
      //     this.userContentApiService.setUserOpenedCollection(collection).subscribe();
      //   }
      // }
      );
  }
}
