<form>
  <div class="form-group mb-3">
<!--    <div class="mb-3">-->
<!--      <label for="name">Name</label>-->
<!--      <input id="name" class="form-control" [(ngModel)]="name" name="name">-->
<!--    </div>-->
<!--    <div class="mb-3">-->
<!--      <label for="firstName">First name</label>-->
<!--      <input id="firstName" class="form-control" [(ngModel)]="firstName" name="firstName">-->
<!--    </div>-->
    <div class="mb-3">
      <label for="userEmail">User email *</label>
      <input type="email" id="userEmail" class="form-control" [(ngModel)]="userEmail" name="userEmail" required email>
    </div>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="isSubmitDisabled()">Save</button>
  </div>
</form>
