import { Component, signal } from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SubscriptionCardComponent } from './subscription-card/subscription-card.component';
import { CommunityNavigationComponent } from '../community-navigation/community-navigation.component';
import { CommunityBaseComponent } from '../community-base/community-base.component';
import { ActivatedRoute } from '@angular/router';
import { ResourceAccessService } from '../../api/resource/resource-access.service';
import { SubscriptionPlan } from '../../api/membership/domain/subscription-plan';
import { loadStripe } from '@stripe/stripe-js/pure';
import { MEMBERSHIP_REQUIRED_PLAN_QUERY_PARAM, PURCHASE_SUCCESS_QUERY_PARAM } from '../community.routes';
import { NgIf } from '@angular/common';
import { CommunitySubscriptionComponent } from './community-subscription/community-subscription.component';

@Component({
  selector: 'app-community-subscription-page',
  standalone: true,
  imports: [
    SubscriptionCardComponent,
    CommunityNavigationComponent,
    NgIf,
    CommunitySubscriptionComponent
  ],
  templateUrl: './community-subscription-page.component.html',
  styleUrl: './community-subscription-page.component.scss'
})
export class CommunitySubscriptionPageComponent extends CommunityBaseComponent {
  showPurchaseSuccess = signal<boolean>(false);
  activePlan = signal<SubscriptionPlan>(SubscriptionPlan.NO_MEMBERSHIP);
  requiredPlan = signal<SubscriptionPlan>(SubscriptionPlan.NO_MEMBERSHIP);


  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    accessService: ResourceAccessService
  ) {
    // loadStripe.setLoadParameters({advancedFraudSignals: false});
    super(route, profileService, accessService);

    route.queryParams
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(queryParams => {
        const purchaseSuccessStr = queryParams[PURCHASE_SUCCESS_QUERY_PARAM];
        if (purchaseSuccessStr) {
          this.showPurchaseSuccess.set(Object.values(SubscriptionPlan).some(plan => plan === purchaseSuccessStr.toUpperCase()));
          this.activePlan.set((SubscriptionPlan as any)[purchaseSuccessStr.toUpperCase()]);
        }
        const requiredPlanStr = queryParams[MEMBERSHIP_REQUIRED_PLAN_QUERY_PARAM];
        if (requiredPlanStr) {
          this.requiredPlan.set((SubscriptionPlan as any)[requiredPlanStr.toUpperCase()]);
        }
      })
  }
}
