<form>
  <div class="form-group">
    <label for="title">Title</label>
    <input id="title" class="form-control" [(ngModel)]="profile.title" name="title" required>
    <div class="d-flex align-items-end py-3">
      <div *ngIf="profile?.logoLink" class="navbar-brand text-center logo-container me-1">
        <img ngSrc="{{profile?.logoLink}}" alt="{{profile?.title}}" class="logo" height="120" width="500">
      </div>
      <button type="button" class="btn btn-secondary"
              (click)="addLogo()">{{ profile?.logoLink ? 'Change Logo' : 'Upload Logo' }}
      </button>
    </div>
    <label for="shortDescription">Short Description</label>
    <input id="shortDescription" class="form-control" [(ngModel)]="profile.shortDescription" name="shortDescription"
           required>
    <app-post-editor #postEditor [post]="profile.description"
                     [accessDelegate]="resourceAccessService.getCommunityDelegate.bind(resourceAccessService)"
                     (hasChanges)="onPostEditorHasChanges($event)"></app-post-editor>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
  </div>
</form>
