import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {LoginMetaData} from "./login-modal.component";

@Injectable({
  providedIn: 'root'
})
export class LoginModalService {

  private _loginRequest = new Subject<LoginMetaData>();

  // private modalVisible = new BehaviorSubject<boolean>(false);

  signUpOrLoginAndRoute(url: string) {
    this._loginRequest.next({isSignUpMode: true, requestedUrl: url, doAfterLogin: null})
  }

  signUpOrLoginAndDo(doAfterLogin: () => void) {
    this._loginRequest.next({isSignUpMode: true, requestedUrl: null, doAfterLogin: doAfterLogin})
  }

  signUpOrLogin(): void {
    this._loginRequest.next({isSignUpMode: true, requestedUrl: null, doAfterLogin: null});
    // this.modalVisible.next(true);
  }

  loginAndRoute(url: string) {
    this._loginRequest.next({isSignUpMode: false, requestedUrl: url, doAfterLogin: null})
  }

  loginAndDo(doAfterLoging: () => void) {
    this._loginRequest.next({isSignUpMode: false, requestedUrl: null, doAfterLogin: doAfterLoging})
  }

  login(): void {
    this._loginRequest.next({isSignUpMode: false, requestedUrl: null, doAfterLogin: null});
    // this.modalVisible.next(true);
  }

  get loginRequest() {
    return this._loginRequest.asObservable();
  }

  // hideModal(): void {
  //   this.modalVisible.next(false);
  // }

  // getModalState() {
  //   return this.modalVisible.asObservable();
  // }
}
