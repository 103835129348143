import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';
import { CommunityMembership } from '../../api/membership/domain/community-membership';
import { Product } from '../../api/product/domain/product';

@Injectable({
  providedIn: 'root'
})
export abstract class CommunitySubscriptionService extends ResourceService<Product<CommunityMembership>> {

}
