<div *ngIf="data().kind === MarkdownTag.text">
  <p [innerHTML]="data().content"></p>
</div>
<div class="markdown-element" *ngIf="data().kind === MarkdownTag.markdown">
  <markdown [data]="data().content" [start]=""></markdown>
</div>
<div *ngIf="data().kind === MarkdownTag.video" class="py-3">
  <video height="auto" width="100%" preload="none" controls controlsList="nodownload noplaybackrate"
         disablePictureInPicture [appLazyLoadMedia]="videoData().videoUrl"
         (click)="$event.stopPropagation()"
         (contextmenu)="$event.preventDefault()"
         [poster]="videoData().thumbnailUrl">
    <!--  <video min-width="500" height="auto" controls controlslist="play timeline volume" >-->
    <source>
    Your browser does not support the video tag.
  </video>

  <!--  <app-video-js-player [options]="{-->
  <!--    fluid: true,-->
  <!--    aspectRatio: '16:9',-->
  <!--    autoplay: true,-->
  <!--    sources: [-->
  <!--      {-->
  <!--        src: data().content,-->
  <!--        type: 'video/mp4'-->
  <!--      }-->
  <!--    ]-->
  <!--  }"></app-video-js-player>-->

</div>
<div *ngIf="data().kind === MarkdownTag.image" class="py-3">
  <img [src]="data().content" height="auto" width="100%" loading="lazy"
       (contextmenu)="$event.preventDefault()"
  >
</div>
<div *ngIf="data().kind === MarkdownTag.audio" class="video-margin py-3">
  <audio style="width: 100%" preload="metadata" controls controlsList="nodownload noplaybackrate"
         [appLazyLoadMedia]="data().content"
         (click)="$event.stopPropagation()"
         (contextmenu)="$event.preventDefault()"
  >
    <source>
    Your browser does not support the audio tag.
  </audio>
</div>
<div *ngIf="data().kind === MarkdownTag.downloadButton" class="py-3">
  <a class="btn btn-primary" role="button" [href]="data().content" download>Download</a>
</div>


<!--<div *ngIf="data().kind === MarkdownTag.youTubeVideo" class="iframe-container">-->
<!--  <app-modal-->
<!--    #youTubeModal-->
<!--    [title]="'YouTube Video'"-->
<!--    [dialogClasses]="'modal-lg'"-->
<!--    [content]="youtubeContent">-->
<!--  </app-modal>-->

<!--  <ng-template #youtubeContent>-->
<!--    <iframe [src]="sanitizedYouTubeUrl()"-->
<!--            class="w-100"-->
<!--            style="aspect-ratio: 16/9;"-->
<!--            title="YouTube video player" frameborder="0"-->
<!--            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
<!--            referrerpolicy="strict-origin-when-cross-origin"-->
<!--            allowfullscreen></iframe>-->
<!--    &lt;!&ndash;    <iframe&ndash;&gt;-->
<!--    &lt;!&ndash;      [src]="sanitizedYouTubeUrl()"&ndash;&gt;-->
<!--    &lt;!&ndash;      class="w-100"&ndash;&gt;-->
<!--    &lt;!&ndash;      style="aspect-ratio: 16/9;"&ndash;&gt;-->
<!--    &lt;!&ndash;      title="YouTube video player"&ndash;&gt;-->
<!--    &lt;!&ndash;      frameborder="0"&ndash;&gt;-->
<!--    &lt;!&ndash;      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"&ndash;&gt;-->
<!--    &lt;!&ndash;      allowfullscreen>&ndash;&gt;-->
<!--    &lt;!&ndash;    </iframe>&ndash;&gt;-->
<!--  </ng-template>-->

<!--  <button type="button" class="btn btn-primary" (click)="openYouTubeModal()">Watch Video</button>-->
<!--</div>-->
<div *ngIf="data().kind === MarkdownTag.youTubeVideo" class="iframe-container">
  <iframe [src]="sanitizedYouTubeUrl()"
          class=""
          title="YouTube video player" frameborder="0"
          allow="encrypted-media; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen></iframe>
</div>
<!--allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
<!--<div *ngIf="data().kind === MarkdownTag.youTubeVideo" class="d-flex justify-content-center">-->
<!--  &lt;!&ndash; Button to open modal &ndash;&gt;-->
<!--  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#videoModal">-->
<!--    Watch Video-->
<!--  </button>-->

<!--  &lt;!&ndash; Modal &ndash;&gt;-->
<!--  <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">-->
<!--    <div class="modal-dialog modal-lg">-->
<!--      <div class="modal-content">-->
<!--        <div class="modal-header">-->
<!--          <h5 class="modal-title" id="videoModalLabel">YouTube Video</h5>-->
<!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--        </div>-->
<!--        <div class="modal-body">-->
<!--          <iframe [src]="sanitizedYouTubeUrl()"-->
<!--                  class="w-100"-->
<!--                  title="YouTube video player" frameborder="0"-->
<!--                  referrerpolicy="strict-origin-when-cross-origin"-->
<!--                  allowfullscreen style="aspect-ratio: 16/9;"></iframe>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--          referrerpolicy="strict-origin-when-cross-origin"-->
<!--          allow="encrypted-media; picture-in-picture"-->

<!--<div *ngIf="data().kind === MarkdownTag.youTubeVideo" class="iframe-container">-->
<!--  <iframe [src]="sanitizedYouTubeUrl()"-->
<!--          class="video-padding"-->
<!--          title="YouTube video player" frameborder="0"-->
<!--          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
<!--          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
<!--</div>-->

<div *ngIf="data().kind === MarkdownTag.linkButton" class="py-3">
  <a class="btn btn-primary" role="button"
     [href]="linkButtonData().sanitizedUrl"
     [style.background-color]="linkButtonData().color ?? ''"
     [style.color]="linkButtonData().textColor ?? ''"
     style="border: transparent"
     (click)="$event.stopPropagation()">{{ linkButtonData().buttonText }}</a>
</div>
<div *ngIf="data().kind === MarkdownTag.callToActionButton" class="text-center py-3">
  <a class="call-to-action-button btn btn-primary btn-lg" role="button"
     [href]="linkButtonData().sanitizedUrl"
     [style.background-color]="linkButtonData().color ?? ''"
     [style.color]="linkButtonData().textColor ?? ''"
     style="border: transparent"
     (click)="$event.stopPropagation()">{{ linkButtonData().buttonText }}</a>
</div>
