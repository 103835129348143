import {Component, computed, DestroyRef, inject, input, output} from '@angular/core';
import {CommunityMembership} from '../../../api/membership/domain/community-membership';
import {Product} from '../../../api/product/domain/product';
import {Resource} from '../../domain/resource';
import {SubscriptionPlan} from '../../../api/membership/domain/subscription-plan';
import {GenericElementComponent} from '../../../generic-element/generic-element.component';
import {CurrencyPipe, DecimalPipe, KeyValue, KeyValuePipe} from '@angular/common';
import {Price} from '../../../api/product/domain/price';
import {Currency} from '../../../api/product/domain/currency';
import {HasWritePermissionDirective} from '../../access-control/has-write-permission.directive';
import {SubscriptionJoinOutput} from './subscription-join-output';
import {Period} from '../../../api/product/domain/period';
import {SubscriptionService} from '../subscription.service';
import {
  SubscriptionPlanDefaultDescription,
  SubscriptionPlanDefaultOffering
} from '../subscription-plan-defaults.config';
import {SubscriptionCardState} from './subscription-card-state';
import {AccessLevelIndicatorComponent} from '../../../elements/access-level-indicator/access-level-indicator.component';
import {TextTileComponent} from '../../../elements/text-tile/text-tile.component';
import {CloneUtil} from '../../../utils/clone-util';
import {AuthenticationService} from "../../../authentication/authentication.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LoginModalService} from "../../../login/login-modal/login-modal.service";
import {take} from "rxjs";

@Component({
  selector: 'app-subscription-card',
  standalone: true,
  imports: [
    GenericElementComponent,
    KeyValuePipe,
    HasWritePermissionDirective,
    AccessLevelIndicatorComponent,
    TextTileComponent,
    DecimalPipe,
    CurrencyPipe
  ],
  templateUrl: './subscription-card.component.html',
  styleUrl: './subscription-card.component.scss'
})
export class SubscriptionCardComponent {
  private readonly subscriptionService = inject(SubscriptionService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly loginModalService = inject(LoginModalService);
  private readonly destroyRef = inject(DestroyRef);
  protected readonly SubscriptionPlan = SubscriptionPlan;
  protected readonly Period = Period;

  subscriptionPlan = input.required<Resource<Product<CommunityMembership>>>({
    // @ts-ignore
    transform: (subscriptionPlan: Resource<Product<CommunityMembership>>) => {
      if (!subscriptionPlan.data.description.elements?.length) {
        subscriptionPlan.data.description.elements = CloneUtil.deepClone(SubscriptionPlanDefaultDescription);
      }
      if (!subscriptionPlan.data.offerings?.length || !subscriptionPlan.data.offerings[0]?.prices?.size) {
        subscriptionPlan.data.offerings = CloneUtil.deepClone(SubscriptionPlanDefaultOffering);
      }
      return subscriptionPlan;
    }
  });

  state = input<SubscriptionCardState>(SubscriptionCardState.default);
  showRequired = input<boolean>(false);
  showDescription = input<boolean>(true);

  plan = computed<SubscriptionPlan>(() => this.subscriptionPlan().data.resources.plan);
  title = computed<string>(() => this.subscriptionService.getSubscriptionPlanStrings(this.plan()));
  joinDisabled = computed<boolean>(() =>
    this.state() === SubscriptionCardState.disabled || this.state() === SubscriptionCardState.active)
  isActive = computed<boolean>(() => this.state() === SubscriptionCardState.active)

  join = output<SubscriptionJoinOutput>();
  edit = output<Resource<Product<CommunityMembership>>>();

  originalOrder = () => 0;

  onJoinFreeClick() {
    this.authenticationService.isAuthenticated()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1)
      )
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.joinFree()
        } else {
          this.loginModalService.signUpOrLoginAndDo(() => this.joinFree());
        }
      });
  }

  joinFree() {
    this.join.emit({product: this.subscriptionPlan(), price: null, plan: SubscriptionPlan.FREE});
  }

  onJoinClick(price: KeyValue<string, Price>) {
    this.authenticationService.isAuthenticated()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1)
      )
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.joinPurchase(price);
        } else {
          this.loginModalService.signUpOrLoginAndDo(() => this.joinPurchase(price));
        }
      });
  }

  joinPurchase(price: KeyValue<string, Price>) {
    this.join.emit({product: this.subscriptionPlan(), price, plan: this.plan()});
  }

  onEditClick() {
    this.edit.emit(this.subscriptionPlan());
  }

  getCurrencySymbol(currency: Currency): string {
    return this.subscriptionService.getCurrencySymbol(currency);
  }

  protected readonly Map = Map;
}
