import { Injectable } from '@angular/core';
import { ModalServiceStandAlone } from '../../../elements/generic-modal/modal-service-standalone.service';
import { EditorService } from '../../editor.service';
import { MayAccess } from '../../../api/resource/domain/may-access';
import { CommunityMembershipModalComponent } from './community-membership-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CommunityMembershipInteractionService extends EditorService<MayAccess, CommunityMembershipModalComponent> {

  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, CommunityMembershipModalComponent, 'modal-dialog-centered modal-xxl');
  }
}
