import { ActivatedRoute } from '@angular/router';

export class RouteUtil {
  static getRootRouteWithParameters(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
