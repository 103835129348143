import { Price } from './price';

export interface Offering {
  description: string;
  prices: Map<string, Price>;
  active: boolean;
}

export class Offerings {
  static empty(): Offering {
    return {
      description: '',
      prices: new Map(),
      active: false
    };
  }
}
