import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {ModalComponent} from './modal.component';
import { EditorComponent } from '../../community/editor-component';

interface ModalOptions<T> {
  title?: string;
  dialogClasses?: string | string[] | Set<string> | { [klass: string]: any };
  exchange?: Partial<T>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalServiceStandAlone {
  private modalComponentRef?: ComponentRef<ModalComponent<any>>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  open<T extends object>(component: new (...args: any[]) => EditorComponent<any>, options?: ModalOptions<T>) {
    if (!this.modalComponentRef) {
      // Dynamically create and attach the modal component to the DOM
      const factory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
      this.modalComponentRef = factory.create(this.injector);
      this.appRef.attachView(this.modalComponentRef.hostView);

      const domElem = (this.modalComponentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }

    const {title = 'Title', dialogClasses = '', exchange} = options || {};

    setTimeout(() => {
      this.modalComponentRef!.instance.title = title;
      this.modalComponentRef!.instance.dialogClasses = dialogClasses;
      this.modalComponentRef!.instance.open();
      this.modalComponentRef!.instance.loadComponent(component, exchange);
    });
  }

  async closeBySave(): Promise<void> {
    if (this.modalComponentRef) {
      return this.modalComponentRef.instance.closeBySave();
    }
  }
}
