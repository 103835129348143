import {ModalServiceStandAlone} from "../elements/generic-modal/modal-service-standalone.service";
import {Resource} from "./domain/resource";
import {EditorComponent} from "./editor-component";
import {ResourceService} from "./resource.service";
import {EditorService} from "./editor.service";
import { catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { NotificationService } from '../services/notification.service';

export abstract class ResourceInteractionService<T, S extends EditorComponent<T>> extends EditorService<T, S> {
  private readonly notificationService = inject(NotificationService);

  protected constructor(
    modalService: ModalServiceStandAlone,
    private resourceService: ResourceService<T>,
    private resourceName: string,
    component: new (...args: any[]) => S,
    dialogClasses: string
  ) {
    super(modalService, component, dialogClasses)
  }

  public create(assignmentId: string, doWithSubmitResponse: (response: Resource<T>) => void, initialData?: T) {
    this.openModal(
      'Create ' + this.resourceName,
      initialData || null,
      modalData => {
        this.resourceService.create(assignmentId, modalData)
          .pipe(
            catchError(err => {
              this.notificationService.showGeneralSaveError();
              this.create(assignmentId, doWithSubmitResponse, modalData);
              return throwError(() => err);
            })
          )
          .subscribe(doWithSubmitResponse);
      }
    )
  }

  public edit(existing: Resource<T>, consumer: (response: Resource<T>) => void) {
    this.openModal(
      'Edit ' + this.resourceName,
      existing?.data,
      modalData => {
        const requestData: Resource<T> = {
          assignmentId: existing.assignmentId,
          creationTimestamp: existing.creationTimestamp,
          uuid: existing.uuid,
          data: modalData
        };
        this.resourceService
          .save(requestData)
          .pipe(
            catchError(err => {
              this.notificationService.showGeneralSaveError();
              this.edit(requestData, consumer);
              return throwError(() => err);
            })
          )
          .subscribe(consumer)
      }
    )
  }
}
