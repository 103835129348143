<app-community>
  @for (product of products; track product.uuid) {
    <li sidenav class="nav-link">
      <a [href]="CommunityRoutes.products(community.creationTimestamp, community.uuid) +'#product-'+ product.uuid"
         class="nav-link" routerLinkActive="nav-link-active" aria-current="page">{{ product.data.title }}</a>
    </li>
  }
  <!-- Main Content -->
  <div main class="container">
    <h1 class="display-5 text-center pt-3 pb-5">Products</h1>
<!--    <div class="py-3"></div>-->
    <div *hasWritePermission class="p-5 d-flex justify-content-around">
      <button (click)="create()" class="btn btn-primary rounded-circle">
        <i class="fa fa-plus"></i>
      </button>
    </div>
    @for (product of products; track product.uuid) {
      <section class="section pt-lg-5 pb-5">
        <div *hasWritePermission class="d-flex justify-content-end">
          <button type="button" class="btn btn-primary" (click)="edit(product)">Edit</button>
        </div>
        <div id="product-{{product.uuid}}" class="container-fluid py-5 post-container router-link rounded-6" (click)="onProductClick(product)">
          <div class="row align-items-center">
            <div class="col-lg-10 offset-lg-1">
                <h1 class="display-6">{{ product.data.title }}</h1>
                <app-post-view *ngIf="product.data.description" [post]="product.data.description"></app-post-view>
            </div>
          </div>
        </div>
      </section>
    }
  </div>
</app-community>
