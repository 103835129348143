<div id="terms" #terms class="py-5 overflow-auto container">
  <div class="pt-5" style="font-size: 12px; color: #333; line-height: 1.5;">
    <h1 style="font-size: 16px; color: #2c3e50; margin-bottom: 10px;">Allgemeine Geschäftsbedingungen (AGB) für Leonardo</h1>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">1. Geltungsbereich</h2>
    <p>1.1 Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Nutzung der Plattform
      <strong>Leonardo</strong>, betrieben von Dr. Peter Machon, Bool 1, 8574 Lengwil, Schweiz (im Folgenden „Plattformbetreiber“).
    </p>
    <p>1.2 Die Plattform ermöglicht Communities und deren Mitgliedern (im Folgenden „Creators“), Inhalte hochzuladen, auszuzeichnen, und über
      <strong>Leonardo</strong> zu verkaufen. Kunden erwerben Zugriffsrechte auf diese Inhalte.</p>
    <p>1.3 Diese AGB gelten weltweit für alle Kunden, Communities und Creators, unabhängig von ihrem Standort. Für alle Verträge gilt Schweizer Recht, soweit keine zwingenden gesetzlichen Vorschriften im Wohnsitzland der Beteiligten entgegenstehen.</p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">2. Vertragsgegenstand</h2>
    <p>2.1 <strong>Leonardo</strong> dient als technische Infrastruktur für:</p>
    <ul style="margin-left: 20px;">
      <li>Kunden, die Zugriffsrechte auf Inhalte erwerben.</li>
      <li>Communities und Creators, die Inhalte hochladen, auspreisen und verkaufen können.</li>
    </ul>
    <p>2.2 Der Plattformbetreiber übernimmt die Zahlungsabwicklung und sorgt für die Auszahlung der Umsätze an die Communities und deren Creators, nach Abzug der Plattformbeteiligung und anfallender Gebühren.</p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">3. Vertragsabschluss</h2>
    <p>3.1
      <strong>Für Kunden:</strong> Der Vertrag über den Erwerb von Zugriffsrechten kommt durch Abschluss des Bestellvorgangs auf der Plattform zustande.
    </p>
    <p>3.2 <strong>Für Creators:</strong> Durch die Nutzung von
      <strong>Leonardo</strong> erklären sich Creators mit diesen AGB sowie der Plattformbeteiligung einverstanden.</p>
    <p>3.3
      <strong>Sofortige Bereitstellung:</strong> Nach erfolgreicher Zahlung erhalten Kunden sofortigen Zugriff auf die erworbenen Inhalte oder Zugriffsrechte. Creators erhalten Umsatzauszahlungen gemäß den Regelungen in Abschnitt 7.
    </p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">4. Ausschluss des Rücktrittsrechts</h2>
    <p>4.1
      <strong>Kein Rücktrittsrecht für digitale Inhalte:</strong> Nach schweizerischem Recht (Art. 40f OR) und der europäischen Verbraucherrechterichtlinie (Art. 16 lit. m) erlischt das Rücktrittsrecht, sobald:
    </p>
    <ul style="margin-left: 20px;">
      <li>die Bereitstellung der Zugriffsrechte unmittelbar nach Vertragsabschluss erfolgt und</li>
      <li>der Kunde ausdrücklich zustimmt, dass die Leistung sofort bereitgestellt wird.</li>
    </ul>
    <p>4.2 Durch Abschluss des Bestellvorgangs verzichtet der Kunde ausdrücklich auf ein Rücktrittsrecht.</p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">5. Abonnements und Einmalkäufe</h2>
    <p>5.1
      <strong>Abonnements:</strong> Abonnements haben feste Laufzeiten und verlängern sich automatisch um die ursprüngliche Laufzeit, wenn sie nicht vor Ablauf gekündigt werden.
    </p>
    <p>5.2
      <strong>Einmalkäufe:</strong> Einmalkäufe berechtigen den Kunden zu einem zeitlich begrenzten oder unbefristeten Zugriff auf die erworbenen Inhalte.
    </p>
    <p>5.3
      <strong>Kündigung von Abonnements:</strong> Kunden können Abonnements jederzeit zum Ende der laufenden Periode kündigen. Eine Rückerstattung für nicht genutzte Zugriffszeiten erfolgt nicht.
    </p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">6. Inhalte und Verantwortung der Creators</h2>
    <p>6.1
      <strong>Eigenverantwortlichkeit:</strong> Die Creators sind eigenständig für die Inhalte verantwortlich, die sie auf
      <strong>Leonardo</strong> hochladen und zum Verkauf anbieten. Dies umfasst die rechtliche und inhaltliche Prüfung, einschließlich der Einhaltung von Urheberrechten, Datenschutzgesetzen und weiteren gesetzlichen Vorgaben.
    </p>
    <p>6.2 <strong>Rechteübertragung:</strong> Creators räumen dem Plattformbetreiber das Recht ein, ihre Inhalte über
      <strong>Leonardo</strong> zu vermarkten, ohne dass dies zu einer Eigentumsübertragung der Inhalte führt.</p>
    <p>6.3
      <strong>Rechtswidrige Inhalte:</strong> Der Plattformbetreiber behält sich das Recht vor, Inhalte, die gegen geltendes Recht, die AGB oder ethische Grundsätze verstoßen, zu entfernen.
    </p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">7. Zahlungsabwicklung und Umsatzverteilung</h2>
    <p>7.1 <strong>Zahlungsabwicklung über Leonardo:</strong> Alle Verkäufe und Zahlungsprozesse werden über
      <strong>Leonardo</strong> abgewickelt. Der Plattformbetreiber zieht die Zahlungen von Kunden ein und sorgt für die Auszahlung an die Communities und deren Creators.
    </p>
    <p>7.2 <strong>Plattformbeteiligung:</strong> Falls nichts anderes vereinbart ist, behält
      <strong>Leonardo</strong> 20 % des Netto-Umsatzes als Beteiligung ein.</p>
    <p>7.3
      <strong>Aufteilung unter Creators:</strong> Sofern keine abweichende Regelung besteht, wird der verbleibende Umsatz nach Abzug der Plattformbeteiligung und Gebühren zu gleichen Teilen unter den Creators einer Community aufgeteilt.
    </p>
    <p>7.4
      <strong>Gebühren und Abzüge:</strong> Zusätzlich zur Plattformbeteiligung können Gebühren Dritter (z. B. Zahlungsdienstleister) von den Umsätzen abgezogen werden.
    </p>
    <p>7.5
      <strong>Auszahlungsregelungen:</strong> Die Auszahlungen an Communities und Creators erfolgen in regelmäßigen Abständen und in der vom Plattformbetreiber angegebenen Währung.
    </p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">8. Haftung</h2>
    <p>8.1 Der Plattformbetreiber haftet ausschließlich für die ordnungsgemäße Bereitstellung der Plattform und der Zahlungsabwicklung.</p>
    <p>8.2 Für die Inhalte, die von Communities oder Creators bereitgestellt werden, übernimmt der Plattformbetreiber keine Haftung. Die Verantwortung liegt allein bei den Creators.</p>
    <p>8.3 Der Plattformbetreiber haftet nicht für Umsatzausfälle aufgrund technischer Störungen oder rechtlicher Probleme der Inhalte.</p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">9. Gerichtsstand und anwendbares Recht</h2>
    <p>9.1 Für alle Streitigkeiten im Zusammenhang mit diesen AGB gilt ausschließlich Schweizer Recht.</p>
    <p>9.2 Der Gerichtsstand ist Lengwil, Schweiz.</p>
    <p>9.3 Für internationale Kunden und Creators gelten die zwingenden gesetzlichen Bestimmungen ihres Wohnsitzlandes, soweit sie mit dem Schweizer Recht nicht im Widerspruch stehen.</p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">10. Datenschutz</h2>
    <p>10.1 Die Verarbeitung personenbezogener Daten erfolgt gemäß der Datenschutzerklärung des Plattformbetreibers.</p>
    <p>10.2 Der Plattformbetreiber gibt Kundendaten nur an die Communities oder Creators weiter, soweit dies zur Vertragserfüllung erforderlich ist.</p>
    <h2 style="font-size: 14px; color: #2c3e50; margin-top: 20px;">11. Schlussbestimmungen</h2>
    <p>11.1 Änderungen dieser AGB werden den Creators und Kunden vorab mitgeteilt und gelten als akzeptiert, wenn diesen nicht innerhalb von 14 Tagen widersprochen wird.</p>
    <p>11.2 Sollten einzelne Bestimmungen dieser AGB unwirksam sein, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</p>
  </div>
</div>
