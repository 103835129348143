import {Observable} from 'rxjs';
import {CommunityProfile} from '../../community/domain/community-profile';
import { ResourceId, RootResource, RootResourceId } from '../../community/domain/resource';
import {ContentItemWithCommunity} from "../../secure/domain/content-item-with-community";
import { StripeCheckoutData } from './domain/stripe-checkout-data';
import { SubscriptionPlan } from '../membership/domain/subscription-plan';

export abstract class UserContentApiService {
  abstract getFollowedCommunities(): Observable<RootResource<CommunityProfile>[]>;
  abstract getFollowedContent(): Observable<ContentItemWithCommunity[]>;
  abstract getCommunitiesWithCreatorRights(): Observable<RootResource<CommunityProfile>[]>;
  abstract setUserOpenedFeed(resource: ResourceId): Observable<void>;
  abstract setUserOpenedCollection(resource: ResourceId): Observable<void>;

  abstract joinFreeCommunitySubscription(productId: ResourceId, community: RootResourceId): Observable<void>;
  abstract purchaseCommunitySubscription(productId: ResourceId, offeringPriceUuid: string | null, plan: SubscriptionPlan, community: RootResourceId): Observable<StripeCheckoutData>;
  abstract purchaseBundle(productId: ResourceId, offeringPriceUuid: string | null, community: RootResourceId): Observable<StripeCheckoutData>;
}
