import { createSelector } from '@ngrx/store';
import { AppState } from '../app-state';

export const selectCommunityRole = createSelector(
  (state: AppState) => state.community.userRights.role,
  role => role
);
export const selectUserCommunitySubscriptionPlan = createSelector(
  (state: AppState) => state.community.userRights.subscriptionPlan,
  plan => plan
);
