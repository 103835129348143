import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {CommonModule} from "@angular/common";
import {CommunityNavigationComponent} from "../community-navigation/community-navigation.component";
import {PostViewComponent} from "../post/view/post-view.component";
import {CommunityBaseComponent} from "../community-base/community-base.component";
import {FeedComponent} from "../feed/view/feed.component";
import {RootResource} from "../domain/resource";
import {ProfileService} from "../profile/profile.service";
import {ProfileInteractionService} from "../profile/profile-interaction.service";
import {CommunityProfile} from "../domain/community-profile";
import {ResourceAccessService} from "../../api/resource/resource-access.service";
import {HasWritePermissionDirective} from "../access-control/has-write-permission.directive";
import {ManageUserAccessInteractionService} from '../profile/manage-user-access-editor/manage-user-access-interaction.service';
import {
  InviteCommunityCreatorInteractionService
} from '../profile/invite-community-creator-editor/invite-community-creator-interaction.service';

@Component({
  selector: 'app-community-home',
  standalone: true,
  imports: [
    CommonModule,
    PostViewComponent,
    CommunityNavigationComponent,
    RouterLink,
    FeedComponent,
    HasWritePermissionDirective
  ],
  templateUrl: './community-home.component.html',
  styleUrl: './community-home.component.scss'
})
export class CommunityHomeComponent extends CommunityBaseComponent implements OnInit, OnDestroy {

  // mainFeed!: Resource<Feed>;
  // communityProfileSubscription!: Subscription;

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private profileInteractionService: ProfileInteractionService,
    private manageUserAccessInteractionService: ManageUserAccessInteractionService,
    private inviteCommunityCreatorInteractionService: InviteCommunityCreatorInteractionService,
    // private feedService: FeedService,
    accessService: ResourceAccessService
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

 override ngOnDestroy(): void {
    super.ngOnDestroy();
    // this.communityProfileSubscription.unsubscribe();
  }

  edit(resource: RootResource<CommunityProfile>) {
    this.profileInteractionService.edit(resource, r => super.loadCommunity());
  }

  onManageUserAccessButtonClick(resource: RootResource<CommunityProfile>) {
    this.manageUserAccessInteractionService.openModal(
      'Manage user access to content', resource, () => void 0);
  }

  onInviteCommunityCreatorButtonClick(resource: RootResource<CommunityProfile>) {
    this.inviteCommunityCreatorInteractionService.openModal(
      'Invite community creator', resource, () => console.log('Invite community creator editor callback'));
  }
}
