import { Injectable } from '@angular/core';

export const ALLOWED_YOUTUBE_URLS = [
  'https://youtube.com',
  'https://www.youtube.com',
  'https://youtu.be',
  'https://www.youtu.be',
  'https://m.youtube.com',
  'https://youtube-nocookie.com',
  'https://www.youtube-nocookie.com',
];

@Injectable({
  providedIn: 'root'
})
export class YoutubeUrlService {

  extractYouTubeID(url: string): string | null {
    const regExp = /(?:youtube\.com\/(?:.*v=|embed\/|shorts\/)|youtu\.be\/|www\.youtu\.be\/|m\.youtube\.com\/(?:.*v=|embed\/)|youtube-nocookie\.com\/(?:.*v=|embed\/)|www\.youtube-nocookie\.com\/(?:.*v=|embed\/))([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  }

  getYouTubeEmbedUrl(videoId: string): string {
    return 'https://www.youtube-nocookie.com/embed/' + this.extractYouTubeID(videoId);
  }
}
