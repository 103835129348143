import {MayAccess} from "../../api/resource/domain/may-access";

export interface Resource<T> extends RootResource<T> {
  assignmentId: string;
  // data: T
}

export interface ResourceId extends RootResourceId {
  assignmentId: string;
}

export interface RootResource<T> extends RootResourceId {
  data: T;
}

export interface RootResourceId {
  creationTimestamp: number;
  uuid: string;
}

export abstract class Resources {

  static resource<T>(resourceId: ResourceId, data: T): Resource<T> {
    return {
      assignmentId: resourceId.assignmentId,
      creationTimestamp: resourceId.creationTimestamp,
      uuid: resourceId.uuid,
      data: data
    }
  }

  static rootResource<T>(resourceId: RootResourceId, data: T): RootResource<T> {
    return {
      creationTimestamp: resourceId.creationTimestamp,
      uuid: resourceId.uuid,
      data: data
    }
  }

  // todo: misplaced?!
  static resourceWithAccess(resourceId: ResourceId, access: MayAccess) {
    return {
      resource: Resources.resource(resourceId, null),
      access: access
    }
  }
}
