<div class="youtube-url-container" [style.top]="topPosition()" [style.left]="leftPosition()">
  <button type="button" class="btn-close" (click)="onCloseButtonClick()"></button>
  <form class="row g-3">
    <div class="col-auto">
      <label for="url">YouTube Url *</label>
      <input type="text" id="url" class="form-control" [(ngModel)]="url" name="url" required>
    </div>
    <div class="col-auto d-flex align-items-end">
      <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="isSubmitDisabled()">Add</button>
    </div>
  </form>
  @if (showNoYouTubeLinkError()) {
    <div class="alert alert-danger mt-3" role="alert">
      We couldn't understand your YouTube video link "{{url()}}". Please use a link that contains the YouTube video id
      and starts like one of the following options:<br>
      @for (link of ALLOWED_YOUTUBE_URLS; track link) {
        {{link}}<br>
      }
    </div>
  }
</div>
