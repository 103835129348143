<app-community>
  <div main class="container">
    <div class="sticky-top d-flex justify-content-between align-items-start bg-white pt-3">
      <div class="mb-4">
        <h1 class="display-6 mb-0 pe-1">{{ product()?.data?.title }}</h1>
        @if (requiredSubscriptionPlan()) {
          <app-product-access-indicator
            [productPurchasePlan]="requiredSubscriptionPlan()!"></app-product-access-indicator>
        }
      </div>
      @if (productAccessRight()?.accessibility !== Accessibility.INCLUDED) {
<!--        <button class="btn btn-primary" type="button" style="color: #d8428cfe;" (click)="onBuyClick()">Buy</button>-->
<!--        <button class="btn btn-primary" type="button" style="color: var(&#45;&#45;hover-color);" (click)="onBuyClick()">Buy</button>-->
<!--        <button class="btn btn-primary" type="button" (click)="onBuyClick()"><span class="h5 highlight-text-sparse">Buy</span></button>-->
        <button class="btn btn-primary" type="button" (click)="onBuyClick()">
        <span class="h6" style="color: var(--hover-color)">Buy</span>
      </button>
      }
      <!--      @else if (productAccessRight()?.mayPurchase === Accessibility.NOT_AVAILABLE) {-->
      <!--        <button class="btn btn-primary" type="button"-->
      <!--                [routerLink]="CommunityRoutes.membership(community.creationTimestamp, community.uuid)">Update-->
      <!--          Subscription to Buy-->
      <!--        </button>-->
      <!--      }-->
    </div>
    @if (showPurchaseSuccess()) {
      <div class="alert alert-success animated fadeIn text-center">
        Thank you for your purchase. You now have access to the contents of this product.
      </div>
    }
    @if (showPurchaseError()) {
      <div class="alert alert-danger animated fadeIn text-center">
        There was an error with the purchase checkout. That shouldn't have happened, we are very sorry. Please contact
        support: contact&#64;keldysh.io
      </div>
    }
    <div class="py-3"></div>
    @if (resources()?.length) {
      @for (resource of resources(); track resource.resource.uuid) {
        <section class="section py-3">
          <div id="resource-{{resource.resource.uuid}}"
               class="container position-relative py-5 post-container router-link rounded-6"
               (click)="onResourceClick(resource.resource, resource.access)">
            <!--            @if (!resource.hasAccess) {-->
            <!--              <div class="access-indicator px-2 pt-1">-->
            <!--                <i class="fa fa-lock fs-3"></i>-->
            <!--              </div>-->
            <!--            }-->
            <div class="row align-items-center">
              <div class="col-lg-10 offset-lg-1">
                <div class="p-4">
                  @if (resource.access) {
                    <div class="d-flex justify-content-end mb-2">
                      <app-access-level-indicator [accessRights]="resource.access"></app-access-level-indicator>
                    </div>
                  }
                  <div class="d-flex justify-content-between align-items-start mb-4">
                    <h1 class="display-6 mb-0">{{ resource.resource.data.title }}</h1>
                  </div>
                  @if (resource.resource.data.description) {
                    <app-post-view [post]="resource.resource.data.description"></app-post-view>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <div #bottom style="height: 2vh"></div> <!-- Sentinel element -->
    }
  </div>
</app-community>
