import { Injectable } from '@angular/core';
import { ModalServiceStandAlone } from '../../../elements/generic-modal/modal-service-standalone.service';
import { GetAccessToResourceModalComponent } from './get-access-to-resource-modal.component';
import { EditorService } from '../../editor.service';
import { ResourceWithAccess } from '../../feed/domain/resource-with-access';

@Injectable({
  providedIn: 'root'
})
export class GetAccessToResourceInteractionService extends EditorService<ResourceWithAccess<unknown>, GetAccessToResourceModalComponent> {

  constructor(modalService: ModalServiceStandAlone) {
    super(modalService, GetAccessToResourceModalComponent, 'modal-dialog-centered modal-xxl');
  }
}
