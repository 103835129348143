import {Component} from '@angular/core';
import {CommunityBaseComponent} from "../../community-base/community-base.component";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import {CommunityNavigationComponent} from "../../community-navigation/community-navigation.component";
import {CommonModule} from "@angular/common";
import {Resource} from "../../domain/resource";
import {ProductService} from "../product.service";
import {ProductInteractionService} from "../product-interaction.service";
import {PostViewComponent} from "../../post/view/post-view.component";
import {Subscription, switchMap} from "rxjs";
import {CommunityRoutes} from "../../community.routes";
import {ProfileService} from "../../profile/profile.service";
import {ResourceAccessInteractionService} from "../../access-control/resource-access-interaction.service";
import {ResourceAccessService} from "../../../api/resource/resource-access.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";
import { Product } from '../../../api/product/domain/product';
import { Bundle } from '../../../api/product/domain/bundle';

@Component({
  selector: 'app-products',
  standalone: true,
    imports: [
        CommonModule,
        CommunityNavigationComponent,
        RouterLink,
        PostViewComponent,
        HasWritePermissionDirective,
        RouterLinkActive
    ],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent extends CommunityBaseComponent {
  CommunityRoutes = CommunityRoutes;

  products!: Resource<Product<Bundle>>[];
  loadSubscription!: Subscription;

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private productService: ProductService,
    private productInteractionService: ProductInteractionService,
    private accessInteractionService: ResourceAccessInteractionService,
    accessService: ResourceAccessService,
    private router: Router
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.load();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
  }

  onProductClick(product: Resource<Product<Bundle>>) {
    void this.router.navigate([CommunityRoutes.product(this.community.creationTimestamp, this.community.uuid, product.creationTimestamp, product.uuid)]);
  }

  create() {
    this.productInteractionService.create(this.community.uuid, productResource => {
      this.load();
    })
  }

  edit(productResource: Resource<Product<Bundle>>) {
    this.productInteractionService.edit(productResource, r => this.load());
  }

  load() {
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
    // this.profileService.activeCommunity()
    this.loadSubscription = this.withCommunity()
      .pipe(switchMap(c => this.productService.load(c.uuid)))
      // todo: remove the reverse, it's just a hack for now.
      .subscribe(products => this.products = products.reverse())
  }

}
