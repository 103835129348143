import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appShowScrollBarOnScroll]',
  standalone: true
})
export class ShowScrollBarOnScrollDirective {
  private timer!: ReturnType<typeof setTimeout>;

  constructor(
    private readonly elementRef: ElementRef
  ) {
    // No need to remove event listener manually. Will get picked up by garbage collector when the DOM element gets
    // removed (and no reference to it exists, which normally should be the case)
    this.elementRef.nativeElement.addEventListener('scroll', () => this.onElementScroll());
    // this.elementRef.nativeElement.addEventListener('mouseenter', () => this.addShowScrollBarClass());
    // this.elementRef.nativeElement.addEventListener('mouseleave', () => this.removeShowScrollBarClass());
  }

  onElementScroll() {
    this.addShowScrollBarClass();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.removeShowScrollBarClass();
    }, 3000);
  }

  private addShowScrollBarClass() {
    this.elementRef.nativeElement.classList.add('show-scroll-bar');
  }

  private removeShowScrollBarClass() {
    this.elementRef.nativeElement.classList.remove('show-scroll-bar');
  }
}
