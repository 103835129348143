import {Injectable} from '@angular/core';
import {FullDelegate} from "../domain/instant";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class FileUploadService {
  abstract uploadFile(file: File, delegate?: FullDelegate | null): Observable<{ url: string }>;
}
