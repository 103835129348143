import {
  Component,
  computed,
  ElementRef,
  inject,
  input,
  model,
  OnInit,
  output,
  signal,
  viewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoFormData } from './video-form-data';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-video-form',
  standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule
    ],
  templateUrl: './video-form.component.html',
  styleUrl: './video-form.component.scss'
})
export class VideoFormComponent implements OnInit {
  private static maxThumbnailFileSize = 500 * 1024;

  private readonly notificationService = inject(NotificationService);

  isSubmitDisabled = signal<boolean>(true);

  referenceElement = input.required<HTMLElement>();
  videoAdded = output<VideoFormData>();
  closed = output<void>();

  videoInput = viewChild.required<ElementRef<HTMLInputElement>>('videoInput');
  thumbnailInput = viewChild.required<ElementRef<HTMLInputElement>>('thumbnailInput');

  topPosition = computed<string>(() => {
    const elementRect = this.referenceElement().getBoundingClientRect();
    let top = elementRect.bottom;

    const container = this.referenceElement().offsetParent;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      top = elementRect.bottom - containerRect.top;
    }

    return top + 4 + 'px';
  });

  rightPosition = computed<string>(() => {
    const elementRect = this.referenceElement().getBoundingClientRect();
    let right = elementRect.right;

    const container = this.referenceElement().offsetParent;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      right = containerRect.right - elementRect.right;
    }

    return right + 'px';
  });


  ngOnInit() {
    this.videoInput().nativeElement.click();
  }

  onVideoInputChange() {
    this.isSubmitDisabled.set(!this.videoInput().nativeElement.files?.length);
  }

  onThumbnailInputChange() {
    if (!this.thumbnailInput().nativeElement.files?.length) {
      return;
    }

    const thumbnail = this.thumbnailInput().nativeElement.files![0];
    if (thumbnail.size > VideoFormComponent.maxThumbnailFileSize) {
      this.notificationService.show('The thumbnail file size is too big. At the moment only ' + (VideoFormComponent.maxThumbnailFileSize / 1024) + ' KB are allowed.');
      this.thumbnailInput().nativeElement.value = '';
    }
  }

  submit() {
    this.videoAdded.emit({
      video: this.videoInput().nativeElement.files![0],
      thumbnail: this.thumbnailInput().nativeElement.files?.length ? this.thumbnailInput().nativeElement.files![0] : undefined
    });
  }

  onCloseButtonClick() {
    this.closed.emit();
  }
}
