<div class="p-5">
  @if (noPricesDefined) {
    <div>No buy options configured at the moment.</div>
  }
  <div class="prices-container text-center p-5">
    @for (price of prices | keyvalue; track price.key) {
      <div class="mb-5">
      <div class="fw-semibold">{{getPriceTitle(price.value)}}</div>
      <div class="">
        <div class="price fw-semibold">
          <span class="fs-4">{{price.value.amount}} {{getCurrencySymbol(price.value.currency)}}</span>
          @if (price.value.period !== Period.oneTime) {
            <span class="fs-6"> / Monat</span>
          }
        </div>
      </div>
      @if (price.value.period !== Period.oneTime) {
        <div class="small">jährliche Abrechnung</div>
      }
        <button class="btn btn-primary" type="button" (click)="onBuyClick(price)">{{ price.value.period === Period.oneTime ? 'Kaufen' : 'Abonnieren' }}</button>
      </div>
    }
  </div>
</div>
