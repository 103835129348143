<div id="disclaimer" #disclaimer class="py-5 overflow-auto container">

  <h1 class="pt-5">Impressum</h1>

  <h2>Verantwortlich für die Plattform</h2>
  <p>
    <strong>Dr. Peter Machon</strong><br>
    Bool 1<br>
    8574 Lengwil<br>
    Schweiz<br>
    E-Mail: <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>
  </p>

  <h2>Haftungsausschluss</h2>
  <h3>Haftung für Inhalte</h3>
  <p>
    Die Inhalte auf dieser Webseite werden teilweise von Dritten (den Communities) bereitgestellt. Diese Communities können sich weltweit befinden und sind eigenständig für die Inhalte verantwortlich, die sie auf der Plattform veröffentlichen. Als Plattformbetreiber stellen wir lediglich die technische Infrastruktur bereit, um den Communities das Veröffentlichen von Inhalten und deren Verkauf zu ermöglichen.
  </p>
  <p>
    Wir überprüfen die von den Communities bereitgestellten Inhalte nicht inhaltlich auf Rechtmäßigkeit, Qualität oder Eignung. Wir übernehmen daher keine Haftung für die Richtigkeit, Vollständigkeit oder Aktualität dieser Inhalte. Sollten uns Rechtsverletzungen durch Inhalte gemeldet werden, werden wir diese umgehend entfernen.
  </p>

  <h3>Haftung für Links</h3>
  <p>
    Unser Angebot kann Links zu externen Webseiten Dritter enthalten, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
  </p>

  <h3>Urheberrecht und Schutz der Inhalte</h3>
  <p>
    Die auf der Plattform veröffentlichten Inhalte, einschließlich der von den Communities bereitgestellten Inhalte, sind urheberrechtlich geschützt. Jegliche Vervielfältigung, Bearbeitung, Verbreitung oder Nachahmung dieser Inhalte ist ohne ausdrückliche Zustimmung der jeweiligen Rechteinhaber untersagt.
  </p>
  <p>
    Die Nutzung der Inhalte ist ausschließlich im Rahmen der durch die Plattform ermöglichten Käufe gestattet. Downloads oder Kopien dieser Inhalte für den privaten oder kommerziellen Gebrauch außerhalb der Plattform sind nicht zulässig.
  </p>
  <p>
    Sollten Sie Urheberrechtsverletzungen feststellen, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir die betroffenen Inhalte umgehend entfernen.
  </p>

  <h2>Verkäufe und Abwicklung</h2>
  <p>
    Alle Käufe und die Abwicklung von Transaktionen erfolgen direkt über die Plattform. Wir agieren dabei als technische Schnittstelle zwischen den Käufern und den Communities, die die Inhalte bereitstellen. Der gesamte Zahlungsprozess wird zentral über die Plattform abgewickelt.
  </p>
  <p>
    Die Communities sind jedoch für den Inhalt der angebotenen Produkte und deren Rechtskonformität eigenständig verantwortlich. Bei Fragen zu einem gekauften Inhalt wenden Sie sich bitte an die entsprechende Community, die als Anbieter des Inhalts fungiert.
  </p>

  <h2>Gerichtsstand und anwendbares Recht</h2>
  <p>
    Diese Plattform wird von der Schweiz aus betrieben. Für alle rechtlichen Belange im Zusammenhang mit der Plattform gilt das Schweizer Recht. Der Gerichtsstand ist Lengwil, Schweiz. Wenn Benutzer oder Communities aus anderen Ländern auf die Plattform zugreifen, sind sie selbst dafür verantwortlich, dass die Nutzung der Plattform und die veröffentlichten Inhalte mit den Gesetzen ihres Landes übereinstimmen.
  </p>

  <h2>Kontakt</h2>
  <p>
    Für Fragen zu rechtlichen oder technischen Aspekten dieser Plattform kontaktieren Sie bitte:<br>
    <strong>Dr. Peter Machon</strong><br>
    E-Mail: <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>
  </p>
</div>
