import { Component, computed, inject, model, OnInit } from '@angular/core';
import { AsyncPipe, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PostViewComponent } from '../../post/view/post-view.component';
import { Currency } from '../../../api/product/domain/currency';
import { Product } from '../../../api/product/domain/product';
import { CommunityMembership } from '../../../api/membership/domain/community-membership';
import { EditorComponent } from '../../editor-component';
import { Resource } from '../../domain/resource';
import { ModalExchange } from '../../../elements/generic-modal/modal-exchange';
import { SubscriptionService } from '../subscription.service';
import { SubscriptionPlan } from '../../../api/membership/domain/subscription-plan';

@Component({
  selector: 'app-subscription-editor',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    NgForOf,
    PostViewComponent
  ],
  templateUrl: './subscription-editor.component.html',
  styleUrl: './subscription-editor.component.scss'
})
export class SubscriptionEditorComponent implements EditorComponent<Resource<Product<CommunityMembership>>>, OnInit {
  private readonly subscriptionService = inject(SubscriptionService);
  protected readonly Currency = Currency;
  protected readonly Object = Object;

  isFreeSubscription = false;

  data!: ModalExchange<Resource<Product<CommunityMembership>>, Resource<Product<CommunityMembership>>>;

  monthlyPrice = model<number>();
  monthlyPriceCurrency = model<Currency>();
  yearlyPrice = model<number>();
  yearlyPriceCurrency = model<Currency>();
  description = model<string>();

  isSubmitDisabled = computed<boolean>(() => {
    if (this.isFreeSubscription) {
      return false;
    } else {
      return (!this.monthlyPrice() && this.monthlyPrice() !== 0)
        || this.yearlyPrice() == undefined
        || !this.monthlyPriceCurrency()
        || !this.monthlyPriceCurrency();
    }
  });

  ngOnInit() {
    this.isFreeSubscription = this.data.input?.data.resources.plan === SubscriptionPlan.FREE;
    this.setFormData();
  }

  submit() {
    this.data.callback(this.getEditedSubscription());
  }

  private setFormData() {
    if (!this.isFreeSubscription) {
      this.monthlyPrice.set(this.subscriptionService.getMonthlyPrice(this.data.input!).amount || 0);
      this.monthlyPriceCurrency.set(this.subscriptionService.getMonthlyPrice(this.data.input!).currency || Currency.USD);
      this.yearlyPrice.set(this.subscriptionService.getYearlyPrice(this.data.input!).amount || 0);
      this.yearlyPriceCurrency.set(this.subscriptionService.getYearlyPrice(this.data.input!).currency || Currency.USD);
    }
    this.description.set(this.data.input?.data.description.elements[0].content || '');
  }

  private getEditedSubscription(): Resource<Product<CommunityMembership>> {
    const editedSubscription: Resource<Product<CommunityMembership>> = Object.assign({}, this.data.input);
    if (!this.isFreeSubscription) {
      this.subscriptionService.getMonthlyPrice(editedSubscription).amount = this.monthlyPrice()!;
      this.subscriptionService.getMonthlyPrice(editedSubscription).currency = this.monthlyPriceCurrency()!;
      this.subscriptionService.getYearlyPrice(editedSubscription).amount = this.yearlyPrice()!;
      this.subscriptionService.getYearlyPrice(editedSubscription).currency = this.yearlyPriceCurrency()!;
    }
    editedSubscription.data.description.elements[0].content = this.description() || '';

    return editedSubscription;
  }
}
