import { Component, computed, HostBinding, inject } from '@angular/core';
import { NgIf, NgOptimizedImage } from "@angular/common";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { CommunityProfile } from "../domain/community-profile";
import { RootResource } from "../domain/resource";
import { CommunityRoutes } from "../community.routes";
import { ProfileService } from "../profile/profile.service";
import { ScrollToTopButtonComponent } from '../../elements/scroll-to-top-button/scroll-to-top-button.component';
import {
  ShowScrollBarOnScrollDirective
} from '../../elements/show-scroll-bar-on-scroll/show-scroll-bar-on-scroll.directive';
import { UserRoleIndicatorComponent } from '../../elements/user-role-indicator/user-role-indicator.component';
import { Store } from '@ngrx/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectCommunityRole } from '../../store/community/community.selectors';
import { CommunityUserRole } from '../../api/user/domain/community-user-role';
import {AppRoutes} from "../../app.routes";

@Component({
  selector: 'app-community',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    NgIf,
    ScrollToTopButtonComponent,
    RouterLinkActive,
    ShowScrollBarOnScrollDirective,
    UserRoleIndicatorComponent
  ],
  templateUrl: './community-navigation.component.html',
  styleUrl: './community-navigation.component.scss'
})
export class CommunityNavigationComponent {
  private readonly store = inject(Store);

  @HostBinding('class') class = 'd-flex w-100';
  community!: RootResource<CommunityProfile>;

  userRole = toSignal(this.store.select(selectCommunityRole));
  isCommunityCreator = computed<boolean>(() => !!this.userRole() && this.userRole() === CommunityUserRole.creator);

  constructor(profileService: ProfileService) {
    profileService.activeCommunity().subscribe(c => this.community = c);
  }

  protected readonly CommunityRoutes = CommunityRoutes;
  protected readonly length = length;
    protected readonly AppRoutes = AppRoutes;
}
