<form>
  @if (!isFreeSubscription) {
    <div class="form-group row align-items-end g-1 mb-3">
      <div class="col-md-6">
        <label for="monthlyPrice"><b>Monatlicher</b> Preis für <b>monatliche</b> Mitgliedschaft *</label>
        <input id="monthlyPrice" class="form-control" [(ngModel)]="monthlyPrice"
               name="monthlyPrice" type="number" [disabled]="isFreeSubscription" required>
      </div>
      <div class="col-md-6">
        <label for="monthlyPriceCurrency">Währung *</label>
        <select id="monthlyPriceCurrency" class="form-select" [(ngModel)]="monthlyPriceCurrency"
                name="monthlyPriceCurrency" [disabled]="isFreeSubscription" required>
          <option *ngFor="let item of Object.keys(Currency)" [value]="item">{{ item }}</option>
        </select>
      </div>
    </div>
    <div class="form-group row align-items-end g-1 mb-3">
      <div class="col-md-6">
        <label for="yearlyPrice"><b>Monatlicher</b> Preis für <b>jährliche</b> Mitgliedschaft *</label>
        <input id="yearlyPrice" class="form-control" [(ngModel)]="yearlyPrice"
               name="yearlyPrice" type="number" [disabled]="isFreeSubscription" required>
      </div>
      <div class="col-md-6">
        <label for="yearlyPriceCurrency">Währung *</label>
        <select id="yearlyPriceCurrency" class="form-select" [(ngModel)]="yearlyPriceCurrency" name="yearlyPriceCurrency"
                [disabled]="isFreeSubscription" required>
          <option *ngFor="let item of Object.keys(Currency)" [value]="item">{{ item }}</option>
        </select>
      </div>
    </div>
  }
  <div>
    <label for="description">Beschreibung</label>
    <textarea id="description" class="form-control" [(ngModel)]="description" name="description"></textarea>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="isSubmitDisabled()">Save</button>
  </div>
</form>
