import { ContentAccessGrant } from './domain/content-access-grant';
import { Observable } from 'rxjs';
import { CommunityMembershipAccessGrant } from './domain/community-membership-access-grant';
import { RootResourceId } from '../../community/domain/resource';

export abstract class AccessSetupApiService {
  abstract grantAccessToFeed(contentElement: ContentAccessGrant): Observable<void>;
  abstract grantAccessToCollection(contentElement: ContentAccessGrant): Observable<void>;
  abstract assignSubscription(subscription: CommunityMembershipAccessGrant): Observable<void>;
  abstract addCreatorToCommunity(userEmail: string, communityId: RootResourceId): Observable<void>;
}
