import { Component, ElementRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  tap,
  throwError
} from "rxjs";
import {CommonModule} from "@angular/common";
import {PostViewComponent} from "../../post/view/post-view.component";
import {Instant} from "../../domain/instant";
import {Post} from "../../domain/post";
import {Resource, RootResourceId} from "../../domain/resource";
import {PostInteractionService} from "../../post/post-interaction.service";
import {PostService} from "../../post/post.service";
import {PostStandaloneComponent} from "../../post/standalone/post-standalone.component";
import {ResourceAccessService} from "../../../api/resource/resource-access.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";
import { feeds } from '../../mock/mocks';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginModalService } from '../../../login/login-modal/login-modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-feed',
  standalone: true,
  imports: [CommonModule, PostViewComponent, PostStandaloneComponent, HasWritePermissionDirective],
  templateUrl: './feed.component.html',
  styleUrl: './feed.component.scss'
})
export class FeedComponent implements OnDestroy {
  private readonly loginModalService = inject(LoginModalService);
  private readonly router = inject(Router);

  private _feedResource!: RootResourceId;
  @ViewChild('bottom') bottom!: ElementRef;

  @Input()
  set feedResource(value: RootResourceId) {
    this._feedResource = value;
    this.load();
  }

  get feedResource() {
    return this._feedResource;
  }

  posts: Resource<Post>[] = [];
  loadFromResource!: Subject<any>;// = new ReplaySubject<Instant>(1);
  sliceSize = 25;
  fullyLoaded!: boolean;

  postsSubscription!: Subscription;
  // for infinite scroll
  observer!: IntersectionObserver;

  constructor(
    private postService: PostService,
    private postInteractionService: PostInteractionService,
    private accessService: ResourceAccessService
  ) {
  }

  load() {
    console.log('loading feed ...')
    this.fullyLoaded = false;
    this.loadFromResource = new ReplaySubject<Instant>(1);
   this.postsSubscription = this.loading();
    // todo: this should not be required
    this.loadMore();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    }, {threshold: 1.0});

    if (this.bottom) {
      this.observer.observe(this.bottom.nativeElement);
    }
  }

  ngOnDestroy() {
    this.posts = [];
    if (this.observer) {
      this.observer.disconnect();
    }
    this.loadFromResource.unsubscribe();
    this.postsSubscription.unsubscribe();
  }

  createPost() {
    this.postInteractionService.create(this.feedResource.uuid, post => this.reload())
  }

  reload() {
    this.ngOnDestroy();
    // this.posts = [];
    this.load();
    // this.loadMore();
  }

  loadMore() {
    console.log("loading more ... ")
    this.loadFromResource.next({
      assignmentId: this.feedResource.uuid,
      timestamp: this.posts.length > 0 ? this.posts[this.posts.length - 1].creationTimestamp : Number.MAX_SAFE_INTEGER
    });
  }

  // todo: check if this may cause any race conditions and hence duplicates
  loading() {
    return this
      .loadFromResource
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        concatMap(instant => {
          // console.log("loading from : " + instant.timestamp);
          if (!this.fullyLoaded) {
            return this.postService
              .getSlice(instant, this.sliceSize, false)
              .pipe(
                map(posts => posts ? posts : []),
                tap(posts => this.fullyLoaded = posts.length < this.sliceSize),
                catchError((error: HttpErrorResponse) => {
                  if (error.status === 401) {
                    this.loginModalService.signUpOrLoginAndRoute(this.router.url);
                  }
                  return throwError(() => error);
                })
              );
          } else {
            console.log("reached end");
            return of();
          }
        })
      )
      .subscribe((posts: Resource<Post>[]) => {
        this.posts.push(...posts);
      });
  }

  // ngOnInit(): void {
  //   this.loadMore();
  //
  //   // const id = this.route.snapshot.paramMap.get('id');
  //   // if (id) {
  //   // this is stupid
  //   // this.communityService.activeCommunity().subscribe(community => this.community = community);
  //   // this.communityService.getMainFeed().subscribe(feed => this.mainFeed = feed);
  //   // this.communityService.getCollections().subscribe(collections => this.collections = collections);
  //   // } else {
  //   //   alert('Error: communityId is required!');
  //   // }
  //   // });
  // }
}
