import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {ResourceAccessService} from "../../api/resource/resource-access.service";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[hasWritePermission]',
  standalone: true,
})
export class HasWritePermissionDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accessService: ResourceAccessService
  ) {
    this.checkPermission();
  }

  private checkPermission() {
    this.accessService.mayWrite()
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(hasPermission => {
      this.viewContainer.clear();
      if (hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
