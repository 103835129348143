import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalExchange } from "../../../elements/generic-modal/modal-exchange";
import { EditorComponent } from "../../editor-component";
import { PostEditorComponent } from "../../post/editor/post-editor.component";
import { ResourceAccessService } from "../../../api/resource/resource-access.service";
import { NotificationService } from '../../../services/notification.service';
import { Product, Products } from '../../../api/product/domain/product';
import { Bundle } from '../../../api/product/domain/bundle';
import { CloneUtil } from '../../../utils/clone-util';
import { ResourceSelectorComponent } from '../resource-selector/resource-selector.component';
import { FeedService } from '../../feed/feed.service';
import { CollectionService } from '../../collection/collection.service';
import { ContentElement } from '../../domain/content-element';
import { Resource } from '../../domain/resource';
import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { ProfileService } from '../../profile/profile.service';
import { AsyncPipe, NgForOf } from '@angular/common';
import { SubscriptionPlan } from '../../../api/membership/domain/subscription-plan';
import { Currency } from '../../../api/product/domain/currency';
import { Period } from '../../../api/product/domain/period';
import { ResourceUtil } from '../../../utils/resource-util';
import { Price, Prices } from '../../../api/product/domain/price';
import { Offerings } from '../../../api/product/domain/offering';
import { MathUtils } from 'three';

@Component({
  selector: 'app-product-editor',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PostEditorComponent,
    ResourceSelectorComponent,
    AsyncPipe,
    NgForOf
  ],
  templateUrl: './product-editor.component.html',
  styleUrl: './product-editor.component.scss'
})
export class ProductEditorComponent implements EditorComponent<Product<Bundle>>, OnInit {
  private readonly communityService = inject(ProfileService);
  private readonly feedService = inject(FeedService);
  private readonly collectionService = inject(CollectionService);

  private postEditorHasChanges: boolean = false;
  private selectedResources: Resource<ContentElement>[] = [];
  private firstPriceUuid: string | undefined;

  allCommunityResources: Observable<Resource<ContentElement>[]> = this.communityService.activeCommunity()
    .pipe(
      switchMap(community => combineLatest([
        this.collectionService.getAll(community.uuid, true),
        this.feedService.getAll(community.uuid, true)
      ])),
      map(([feeds, collections]) => [...collections, ...feeds]),
    );


  @Input() data!: ModalExchange<Product<Bundle>, Product<Bundle>>;
  @ViewChild('postEditor') postEditor!: PostEditorComponent;

  product!: Product<Bundle>;
  price!: Price;
  initialSelectedResourceUuids: string[] = [];

  constructor(
    protected resourceAccessService: ResourceAccessService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.product = CloneUtil.deepClone(this.data.input);
      this.initialSelectedResourceUuids = this.product.resources.contentResources.map(resource => resource.resourceId.uuid);
      this.allCommunityResources
        .pipe(
          take(1)
        ).subscribe(resources => {
          this.selectedResources = resources.filter(resource => this.initialSelectedResourceUuids.some(uuid => uuid === resource.uuid));
      });
    } else {
      this.product = Products.emptyBundle();
    }
    if (!this.product.offerings?.length) {
      this.product.offerings = [Offerings.empty()];
    }
    if (this.product.offerings[0].prices?.size) {
      this.price = this.product.offerings[0].prices.values().next().value;
      this.firstPriceUuid = this.product.offerings[0]?.prices.keys().next().value;
    } else {
      this.price = Prices.empty();
    }
  }

  submit() {
    this.product.description = this.postEditor.generatePost()

    this.product.resources.contentResources = [];

    this.selectedResources.forEach(resource => {
      this.product.resources.contentResources.push(ResourceUtil.toContentDescriptor(resource));
    });

    if (!this.product.offerings || !this.product.offerings[0]) {
      this.product.offerings = [Offerings.empty()];
    }
    this.product.offerings[0].prices.set(this.firstPriceUuid || MathUtils.generateUUID(), this.price);

    this.data.callback(this.product);
  }

  onPostEditorHasChanges(hasChanges: boolean) {
    this.postEditorHasChanges = hasChanges
  }

  onResourceSelectionChange(resources: Resource<ContentElement>[]) {
    this.selectedResources = resources;
  }

  isPeriodDisabled(price: Price): boolean {
    return price.period === Period.oneTime;
  }

  beforeCancel(): boolean {
    if (this.hasChanges()) {
      return this.notificationService.showConfirmation();
    }
    return true;
  }

  private hasChanges(): boolean {
    return this.postEditorHasChanges || this.product.title !== (this.data?.input?.title || '');
  }

  protected readonly SubscriptionPlan = SubscriptionPlan;
  protected readonly Object = Object;
  protected readonly Currency = Currency;
  protected readonly Period = Period;
}
