import {Injectable} from '@angular/core';
import {ResourceService} from "../resource.service";
import {Feed} from "../domain/feed";
import {map, Observable} from "rxjs";
import {Resource} from "../domain/resource";

@Injectable({
  providedIn: 'root'
})
export abstract class FeedService extends ResourceService<Feed> {

  protected constructor() {
    super()
  }

  load(assignmentId: string): Observable<Resource<Feed>[]> {
    // return this.profileService.activeCommunity().pipe(
    //   switchMap(c =>
    return this.getAll(assignmentId, false);
    //     .pipe(
    //       // tap(feeds => console.log(feeds)),
    //       map(feeds => feeds.filter(feed => feed.id != mainFeedId))
    //     // )
    //   // )
    // );
  }
}
