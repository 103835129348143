<div class="px-5 pt-0 pb-3">
  @if (showLoading) {
    <div class="overlay text-center my-2">
      <i class="fa fa-refresh spinner"></i>
    </div>
  }
  <div class="message-box text-center px-5 py-2 rounded-4">
    @if (showProductsSection && showMembershipSection) {
      Diesen Inhalt kannst du mit einer {{ requiredPlan }} Mitgliedschaft als Teil eines der unten stehenden Produkte kaufen.
    } @else if (!showProductsSection && showMembershipSection) {
      Auf diesen und auf viele weitere Inhalte kannst du mit einer {{ requiredPlan }} Mitgliedschaft zugreifen.
    } @else if (showProductsSection && !showMembershipSection) {
      Diesen Inhalt kannst du als Teil eines der unten stehenden Produkte kaufen.
    }
  </div>
  @if (showMembershipSection) {
  <div class="membership-section mt-5">
    <h6 class="text-center">Mitgliedschaft anpassen</h6>
<!--    <div class=" d-flex justify-content-center">-->
<!--      <button class="btn btn-primary" (click)="onChangeMembershipClick()">Mitgliedschaft anpassen</button>-->
<!--    </div>-->
    <app-community-subscription [requiredPlan]="requiredPlan" [showDescription]="false"
                                (subscribedToFeeSubscription)="onSubscribedToFeeSubscription()"></app-community-subscription>
  </div>
  }
  @if (showProductsSection) {
    <div class="products-section mt-5">
      <h6 class="text-center">In Produkten enthalten</h6>
      @if (!products?.length) {
        <div class="text-center">Momentan kann dieser Inhalt leider nicht erworben werden.</div>
      }
      @for (product of products; track product.uuid) {
        <section class="section py-5">
          <div id="product-{{product.uuid}}" class="container py-5 post-container router-link rounded-5"
               (click)="onProductClick(product)">
            <div class="row align-items-center">
              <div class="col-lg-10 offset-lg-1">
                <div class="p-4">
                  <h1 class="display-6">{{ product.data.title }}</h1>
                  <app-post-view *ngIf="product.data.description" [post]="product.data.description"></app-post-view>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </div>
  }
</div>
