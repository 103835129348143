import {CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {ResourceAccessService} from '../api/resource/resource-access.service';
import {ResourceUtil} from '../utils/resource-util';
import {map, mergeMap, of, take} from 'rxjs';
import {LoginModalService} from '../login/login-modal/login-modal.service';
import {AuthenticationService} from '../authentication/authentication.service';
import {Accessibility} from "../api/product/domain/accessibility";
import {Resources} from "../community/domain/resource";
import {
  GetAccessToResourceInteractionService
} from "../community/product/product-suggestions-modal/get-access-to-resource-interaction.service";



export const resourceAccessGuard: CanActivateFn = (route, state) => {
  const resourceAccessService = inject(ResourceAccessService);
  const authenticationService = inject(AuthenticationService);
  const loginModalService = inject(LoginModalService);
  const getAccessToResourceInteractionService = inject(GetAccessToResourceInteractionService);
// private readonly store = inject(Store);
// private readonly notificationService = inject(NotificationService);

    const resourceId = ResourceUtil.getResourceIdFromUrl(route);
    // const rootResourceId = ResourceUtil.getRootResourceIdFromUrl(route);

    if (!resourceId) {
      console.error('Resource id not found in url. ResourceAccessGuard can only be used on resources!');
      return of(true);
      // return true;
    }

    return authenticationService
      .isAuthenticated()
      .pipe(
        take(1),
        mergeMap(isAuthenticated => isAuthenticated
          ? resourceAccessService.getMayAccess(resourceId)
            .pipe(
              map(mayAccess => {
                if (mayAccess.accessibility === Accessibility.INCLUDED)
                  return true;
                else {
                  getAccessToResourceInteractionService.openModal(
                    'Get Access',
                    Resources.resourceWithAccess(resourceId, mayAccess),
                    () => {
                    }
                  );
                  return false;
                }
              })
            )
          : resourceAccessService.getMayAccessPublic(resourceId)
            .pipe(
              map(mayAccess => {
                if (mayAccess.accessibility === Accessibility.INCLUDED)
                  return true;
                else {
                  loginModalService.signUpOrLoginAndRoute(state.url);
                  return false;
                }
              })
            )
        )
      )

    // return combineLatest([
    //   this.resourceAccessService.getAccessLevel(resourceId),
    //   this.authenticationService.isAuthenticated(),
    //   this.resourceAccessService.getUserRightsByCommunity(rootResourceId)]) // todo: this info should be taken from the store, not again from the backend
    //   .pipe(
    //     map(([accessLevelData, isUserAuthenticated, userRights]) => {
    //       const userRole = CommunityUtil.getCommunityUserRole(userRights);
    //
    //       if (accessLevelData.accessLevel === AccessLevels.unrestricted) {
    //         return true;
    //       } else if (isUserAuthenticated) {
    //         if (ResourceUtil.hasUserAccess(userRole, accessLevelData.accessLevel)) {
    //           return true;
    //         } else {
    //           if (userRole !== CommunityUserRole.visitor)
    //             this.notificationService.show('You don\'t have access to this resource. Please update your community membership to get access.');
    //
    //           return false;
    //         }
    //       } else {
    //         this.loginModalService.signUpOrLoginAndRoute(state.url);
    //         return false;
    //       }
    //     })
    //   )
}
