import {Injectable} from '@angular/core';
import {ResourceService} from "../resource.service";
import {Collection} from "../domain/collection";
import {Observable, switchMap} from "rxjs";
import {Resource} from "../domain/resource";
import {ProfileService} from "../profile/profile.service";

@Injectable({
  providedIn: 'root'
})
export abstract class CollectionService extends ResourceService<Collection> {

  protected constructor() {
    super()
  }

  load(assignmentId: string): Observable<Resource<Collection>[]> {
    // return this.profileService.activeCommunity().pipe(
    //   switchMap(c =>
    return this.getAll(assignmentId, false);
    // .pipe(
    //   tap(collections => console.log(collections)),
    // )
    // )
    // );
  }
}
