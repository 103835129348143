import {Component, HostBinding, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";
import {CommunityProfile} from "../../domain/community-profile";
import {RootResource} from "../../domain/resource";
import {ProfileService} from "../profile.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";
import {CommunityCardComponent} from "../../../secure/community-card/community-card.component";

@Component({
  selector: 'app-discover',
  standalone: true,
  imports: [CommonModule, RouterLink, HasWritePermissionDirective, CommunityCardComponent],
  templateUrl: './discover.component.html',
  styleUrl: './discover.component.scss'
})
export class DiscoverComponent implements OnInit {

  @HostBinding('class') class = 'd-flex w-100';

  communities!: RootResource<CommunityProfile>[];

  constructor(private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.profileService
      .getCommunities()
      .subscribe(
        communities => this.communities = communities
      )
  }
}
