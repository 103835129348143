<div class="video-form-container" [style.top]="topPosition()" [style.right]="rightPosition()">
  <button type="button" class="btn-close" (click)="onCloseButtonClick()"></button>
  <form class="">
    <div class="mb-3">
      <label for="video" class="form-label">Video *</label>
      <input #videoInput class="form-control" type="file" accept="video/*" id="video" name="video" (change)="onVideoInputChange()" required>
    </div>
    <div class="mb-3">
      <label for="thumbnail">Video Thumbnail (optional)</label>
      <input #thumbnailInput class="form-control" type="file" accept="image/*" id="thumbnail" name="thumbnail" (change)="onThumbnailInputChange()">
    </div>
    <div class="mb-3 d-flex justify-content-end">
      <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="isSubmitDisabled()">Add</button>
    </div>
  </form>
</div>
