<form>
  <div class="form-group">
    <label for="accessLevel">Access Level *</label>
    <select id="accessLevel" class="form-select" [(ngModel)]="accessLevel" name="accessLevel" required>
<!--      todo: add description of access levels-->
      <option *ngFor="let level of Object.keys(AccessLevels)" [value]="level">{{ getDisplayText(level) }}</option>
    </select>
<!--    <input id="accessLevel" class="form-control" [(ngModel)]="data?.input" name="accessLevel" required>-->
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="button" class="btn btn-primary" (click)="submit()">Set</button>
  </div>
</form>

