import { Component, computed, input, model, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkButtonData } from './link-button-data';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-link-button-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './link-button-form.component.html',
  styleUrl: './link-button-form.component.scss'
})
export class LinkButtonFormComponent {
  link = model<string>('');
  buttonText = model<string>('');
  buttonColor = model<string>('');
  buttonTextColor= model<string>('');

  referenceElement = input.required<HTMLElement>();
  linkButtonAdded = output<LinkButtonData>();
  closed = output<void>();

  topPosition = computed<string>(() => { // todo: extract into own container component, because same code is used multiple times
    const elementRect = this.referenceElement().getBoundingClientRect();
    let top = elementRect.bottom;

    const container = this.referenceElement().offsetParent;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      top = elementRect.bottom - containerRect.top;
    }

    return top + 4 + 'px';
  });

  leftPosition = computed<string>(() => { // todo: extract into own container component, because same code is used multiple times
    const elementRect = this.referenceElement().getBoundingClientRect();
    let left = elementRect.left;

    const container = this.referenceElement().offsetParent;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      left = elementRect.left - containerRect.left;
    }

    return left + 'px';
  });

  isSubmitDisabled = computed<boolean>(() => {
    return !this.link() || !this.buttonText();
  });

  submit() {
    this.linkButtonAdded.emit({
      buttonText: this.buttonText(),
      link: this.link(),
      color: this.buttonColor(),
      textColor : this.buttonTextColor()
    });
  }

  onCloseButtonClick() {
    this.closed.emit();
  }
}
