import {Component, DestroyRef, inject} from '@angular/core';
import {CommunityBaseComponent} from "../../community-base/community-base.component";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {CommunityNavigationComponent} from "../../community-navigation/community-navigation.component";
import {FeedComponent} from "../view/feed.component";
import {Feed} from "../../domain/feed";
import {CommonModule} from "@angular/common";
import {Resource} from "../../domain/resource";
import {FeedService} from "../feed.service";
import {FeedInteractionService} from "../feed-interaction.service";
import {PostViewComponent} from "../../post/view/post-view.component";
import {forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {CommunityRoutes} from "../../community.routes";
import {ProfileService} from "../../profile/profile.service";
import {ResourceAccessInteractionService} from "../../access-control/resource-access-interaction.service";
import {AccessLevels, ResourceAccessService} from "../../../api/resource/resource-access.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";
import {UserContentApiService} from '../../../api/user/user-content-api.service';
import {
  AccessLevelIndicatorComponent
} from '../../../elements/access-level-indicator/access-level-indicator.component';
import {NotificationService} from '../../../services/notification.service';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {CommunityUserRole} from '../../../api/user/domain/community-user-role';
import {selectCommunityRole} from '../../../store/community/community.selectors';
import {Store} from '@ngrx/store';
import {
  GetAccessToResourceInteractionService
} from '../../product/product-suggestions-modal/get-access-to-resource-interaction.service';
import {ProductService} from '../../product/product.service';
import {Accessibility} from '../../../api/product/domain/accessibility';
import {ResourceWithAccess} from '../domain/resource-with-access';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {MayAccess} from '../../../api/resource/domain/may-access';
import {LoginModalService} from '../../../login/login-modal/login-modal.service';
import {LoadingService} from '../../../loading/loading.service';

@Component({
  selector: 'app-feeds',
  standalone: true,
  imports: [
    CommonModule,
    CommunityNavigationComponent,
    FeedComponent,
    RouterLink,
    PostViewComponent,
    HasWritePermissionDirective,
    RouterLinkActive,
    AccessLevelIndicatorComponent
  ],
  templateUrl: './feeds.component.html',
  styleUrl: './feeds.component.scss'
})
export class FeedsComponent extends CommunityBaseComponent {
  private readonly store = inject(Store);
  private readonly resourceAccessService = inject(ResourceAccessService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly destroyRef = inject(DestroyRef);

  CommunityRoutes = CommunityRoutes;

  feeds!: ResourceWithAccess<Feed>[];
  loadSubscription!: Subscription;

  userRole = toSignal<CommunityUserRole>(this.store.select(selectCommunityRole));

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private feedService: FeedService,
    private feedInteractionService: FeedInteractionService,
    // private profileService: ProfileService,
    // private postService: PostService
    private accessInteractionService: ResourceAccessInteractionService,
    accessService: ResourceAccessService,
    private router: Router,
    // private userContentApiService: UserContentApiService,
    private notificationService: NotificationService
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.load();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
  }

  onFeedClick(feed: ResourceWithAccess<Feed>) {
    // if (!feed.access) { // todo: show loader until access is available instead of doing nothing
    //   return;
    // }
    this.routeToFeed(feed.resource);
    // this.authenticationService.isAuthenticated()
    //   .pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe(isAuthenticated => this.routeToFeed(feed.resource));
    // {
    //     if (isAuthenticated) {
    //       if (!feed.access || feed.access.accessibility === Accessibility.INCLUDED) {
    //         this.routeToFeed(feed.resource);
    //       } else {
    //         this.getAccessToResourceInteractionService.openModal(feed.resource.data.title, feed, () => {
    //         });
    //       }
    //     } else {
    //       if (feed.access.accessibility === Accessibility.INCLUDED) {
    //         this.routeToFeed(feed.resource);
    //       } else {
    //         this.loginModalService.signUpOrLogin();
    //         // this.loginModalService.loginAndRoute(
    //         //   CommunityRoutes.feed(this.community.creationTimestamp, this.community.uuid, feed.resource.creationTimestamp, feed.resource.uuid)
    //         // );
    //       }
    //     }
    //   });
  }

  create() {
    this.feedInteractionService.create(this.community.uuid, feedResource => {
      this.load();
      this.setAccess(feedResource);
    })
  }

  edit(feedResource: Resource<Feed>) {
    this.feedInteractionService.edit(feedResource, r => this.load());
  }

  setAccess(feedResource: Resource<Feed>) {
    this.accessInteractionService.setAccessLevel(feedResource, newAccessLevel => {
    }); //this.load());
  }

  load() {
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
    // this.profileService.activeCommunity()
    this.loadSubscription = this.withCommunity()
      .pipe(
        switchMap(community => this.feedService.load(community.uuid)),
        tap(feeds => this.feeds = feeds.map(feed => ({resource: feed} as ResourceWithAccess<Feed>))),
        switchMap(feeds => this.authenticationService.isAuthenticated()),
        switchMap(isAuthenticated => this.loadResourceAccesses(isAuthenticated))
      )
      .subscribe()
  }

  private loadResourceAccesses(isAuthenticated: boolean): Observable<void> {
    const loader = isAuthenticated
      ? this.resourceAccessService.getMayAccess.bind(this.resourceAccessService)
      : this.resourceAccessService.getMayAccessPublic.bind(this.resourceAccessService);
    return forkJoin(this.feeds.map(feed => loader(feed.resource)))
      .pipe(
        tap(accessRights =>
          this.feeds.forEach((feed, index) => feed.access = accessRights[index])),
        switchMap(accessRights => of(void 0))
      )
  }

  private routeToFeed(resource: Resource<Feed>) {
    this.router.navigate([CommunityRoutes.feed(this.community.creationTimestamp, this.community.uuid, resource.creationTimestamp, resource.uuid)])
      .then(
      //   (hasEnteredRoute) => {
      //   if (hasEnteredRoute) {
      //     this.userContentApiService.setUserOpenedFeed(resource).subscribe();
      //   }
      // }
      );
  }
}
