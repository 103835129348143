import {Injectable} from '@angular/core';
import {Post} from "../domain/post";
import {ModalServiceStandAlone} from "../../elements/generic-modal/modal-service-standalone.service";
import {ResourceInteractionService} from "../resource-Interaction.service";
import {PostService} from "./post.service";
import {PostEditorModalComponent} from "./editor-modal/post-editor-modal.component";

@Injectable({
  providedIn: 'root'
})
export class PostInteractionService extends ResourceInteractionService<Post, PostEditorModalComponent> {

  constructor(modalService: ModalServiceStandAlone, postService: PostService) {
    super(modalService, postService, 'Post', PostEditorModalComponent, 'modal-dialog-centered modal-xxl');
  }
}
