import { Component, effect, input, signal } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-scroll-to-top-button',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './scroll-to-top-button.component.html',
  styleUrl: './scroll-to-top-button.component.scss'
})
export class ScrollToTopButtonComponent {
  showScrollButton = signal<boolean>(false);

  scrollContainer = input.required<HTMLElement>();

  constructor() {
    effect(() => {
      if (this.scrollContainer()) {
        // No need to remove event listener manually. Will get picked up by garbage collector when the DOM element gets
        // removed (and no reference to it exists, which normally should be the case)
        this.scrollContainer().addEventListener('scroll', () => this.onElementScroll());
      }
    });
  }

  onElementScroll() {
    if (this.scrollContainer()) {
      const scrollPosition = this.scrollContainer().scrollTop;
      this.showScrollButton.set(scrollPosition > 300);
    }
  }

  scrollToTop() {
    if (this.scrollContainer()) {
      this.scrollContainer().scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
