import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  viewChild
} from '@angular/core';
import { EditorComponent } from '../../editor-component';
import { ModalExchange } from '../../../elements/generic-modal/modal-exchange';
import { CommunityProfile } from '../../domain/community-profile';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgOptimizedImage } from '@angular/common';
import { PostEditorComponent } from '../../post/editor/post-editor.component';
import { SubscriptionPlan } from '../../../api/membership/domain/subscription-plan';
import { Resource, RootResource } from '../../domain/resource';
import flatpickr from "flatpickr";
import { FeedService } from '../../feed/feed.service';
import { CollectionService } from '../../collection/collection.service';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentElement } from '../../domain/content-element';
import { PostViewComponent } from '../../post/view/post-view.component';
import Instance = flatpickr.Instance;
import { AccessSetupApiService } from '../../../api/user/access-setup-api.service';
import { CommunityMembershipAccessGrant } from '../../../api/user/domain/community-membership-access-grant';
import { ContentAccessGrant } from '../../../api/user/domain/content-access-grant';
import { ValidatorUtil } from '../../../utils/validator-util';
import { UserApiService } from '../../../api/user/user-api.service';
import { InviteUser } from '../../../api/user/domain/invite-user';
import { ResourceSelectorComponent } from '../../product/resource-selector/resource-selector.component';
import { ResourceUtil } from '../../../utils/resource-util';

@Component({
  selector: 'app-manage-user-access-editor',
  standalone: true,
  imports: [
    FormsModule,
    NgOptimizedImage,
    PostEditorComponent,
    ReactiveFormsModule,
    NgForOf,
    AsyncPipe,
    PostViewComponent,
    ResourceSelectorComponent
  ],
  templateUrl: './manage-user-access-editor.component.html',
  styleUrl: './manage-user-access-editor.component.scss'
})
export class ManageUserAccessEditorComponent implements EditorComponent<RootResource<CommunityProfile>>, OnInit, AfterViewInit, OnDestroy {
  private flatPickr!: Instance;
  private readonly feedService = inject(FeedService);
  private readonly collectionService = inject(CollectionService);
  private readonly userAccessApiService = inject(AccessSetupApiService);
  private readonly userApiService = inject(UserApiService);

  protected readonly Object = Object;
  protected readonly SubscriptionPlan = SubscriptionPlan;

  data!: ModalExchange<RootResource<CommunityProfile>, RootResource<CommunityProfile>>;

  userEmail!: string;
  firstName?: string;
  name?: string;
  userPlan!: SubscriptionPlan;
  subscriptionEndDate!: string;
  selectedElements: Resource<ContentElement>[] = [];
  contentElements$!: Observable<Resource<ContentElement>[]>;

  datePickerInput = viewChild<ElementRef>('datePickerInput');

  ngOnInit() {
    this.contentElements$ = combineLatest([
      this.feedService.load(this.data.input?.uuid!),
      this.collectionService.load(this.data.input?.uuid!)
    ])
      .pipe(
        map(([feeds, collections]) => [...collections, ...feeds])
      );
  }

  ngAfterViewInit() {
    this.flatPickr = flatpickr(this.datePickerInput()?.nativeElement, {
      dateFormat: 'd.m.Y',
      minDate: new Date()
    });
  }

  ngOnDestroy() {
    this.flatPickr.destroy();
  }

  isSubmitDisabled(): boolean {
    return !ValidatorUtil.isEmail(this.userEmail) || !this.userPlan;
  }

  onResourceSelectionChange(resources: Resource<ContentElement>[]) {
    this.selectedElements = resources;
  }

  submit() {
    const userInviteData: InviteUser = {
      firstName: this.firstName,
      name: this.name,
      email: this.userEmail,
      communityId: this.data.input!
    }
    this.userApiService.invite(userInviteData)
      .subscribe(() => {
        this.sendAssignSubscription();
        this.sendGrantAccessToSelectedElements();
        this.data.callback(this.data.input!);
      });
  }

  private sendAssignSubscription() {
    const subscription: CommunityMembershipAccessGrant = {
      userEmail: this.userEmail,
      communityId: {
        creationTimestamp: this.data.input?.creationTimestamp!,
        uuid: this.data.input?.uuid!
      },
      subscriptionData: {
        plan: this.userPlan,
        expiryTimestamp: this.getExpirationDateTimestamp()
      }
    };

    this.userAccessApiService.assignSubscription(subscription).subscribe();
  }

  private sendGrantAccessToSelectedElements() {
    this.selectedElements.forEach(element => {
      const contentElement: ContentAccessGrant = {
        userEmail: this.userEmail,
        resourceId: {
          uuid: element.uuid,
          creationTimestamp: element.creationTimestamp,
          assignmentId: element.assignmentId
        }
      };

      if (ResourceUtil.isCollection(element)) {
        this.userAccessApiService.grantAccessToCollection(contentElement).subscribe();
      } else {
        this.userAccessApiService.grantAccessToFeed(contentElement).subscribe();
      }
    });
  }

  private getExpirationDateTimestamp(): number | null {
    if (this.flatPickr.selectedDates?.length) {
      return this.flatPickr.selectedDates[0].getTime();
    }

    return null;
  }
}
