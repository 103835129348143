import {Injectable} from '@angular/core';
import {ModalServiceStandAlone} from "../../elements/generic-modal/modal-service-standalone.service";
import {ResourceInteractionService} from "../resource-Interaction.service";
import {ProductService} from "./product.service";
import { Product } from '../../api/product/domain/product';
import { Bundle } from '../../api/product/domain/bundle';
import { ProductEditorComponent } from './product-editor/product-editor.component';

@Injectable({
  providedIn: 'root'
})
export class ProductInteractionService extends ResourceInteractionService<Product<Bundle>, ProductEditorComponent> {

  constructor(modalService: ModalServiceStandAlone, productService: ProductService) {
    super(modalService, productService, 'Product', ProductEditorComponent, 'modal-dialog-centered modal-xxl');
  }
}
