import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { TextTileComponent } from '../text-tile/text-tile.component';
import { Theme } from '../text-tile/theme';
import { Store } from '@ngrx/store';
import { selectCommunityRole } from '../../store/community/community.selectors';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommunityUserRole } from '../../api/user/domain/community-user-role';
import { CommunityRoutes } from '../../community/community.routes';
import { RouterLink } from '@angular/router';
import { ResourceId, RootResourceId } from '../../community/domain/resource';

@Component({
  selector: 'app-user-role-indicator',
  standalone: true,
  imports: [
    TextTileComponent,
    RouterLink
  ],
  templateUrl: './user-role-indicator.component.html',
  styleUrl: './user-role-indicator.component.scss'
})
export class UserRoleIndicatorComponent {
  private readonly store = inject(Store);
  private readonly userRole = toSignal(this.store.select(selectCommunityRole));

  communityId = input.required<RootResourceId>(); // Todo: should be replaced by communityId from store

  theme = signal<Theme>(Theme.dark);
  showJoinButton = computed<boolean>(() => this.userRole() === CommunityUserRole.visitor);
  userRoleStr = computed<string>(() => {
    if (this.userRole() === CommunityUserRole.freeFollower) {
      return 'Free';
    }
    if (this.userRole() === CommunityUserRole.basicFollower) {
      return 'Basic';
    }
    if (this.userRole() === CommunityUserRole.premiumFollower) {
      return 'Premium';
    }
    if (this.userRole() === CommunityUserRole.creator) {
      return 'Creator';
    }

    return '';
  });
  protected readonly CommunityRoutes = CommunityRoutes;
}
