import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { ProfileService } from '../../profile/profile.service';
import { CommunitySubscriptionService } from '../community-subscription.service';
import { UserContentApiService } from '../../../api/user/user-content-api.service';
import { SubscriptionInteractionService } from '../subscription-editor/subscription-interaction.service';
import { SubscriptionService } from '../subscription.service';
import { Store } from '@ngrx/store';
import { loadStripe } from '@stripe/stripe-js/pure/index';
import { environment } from '../../../../environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommunityUserRole } from '../../../api/user/domain/community-user-role';
import { selectCommunityRole } from '../../../store/community/community.selectors';
import { Resource, RootResource } from '../../domain/resource';
import { Product } from '../../../api/product/domain/product';
import { CommunityMembership } from '../../../api/membership/domain/community-membership';
import { Subscription, switchMap } from 'rxjs';
import { SubscriptionCardState } from '../subscription-card/subscription-card-state';
import { SubscriptionJoinOutput } from '../subscription-card/subscription-join-output';
import { SubscriptionPlan } from '../../../api/membership/domain/subscription-plan';
import { Community, CommunityProfile } from '../../domain/community-profile';
import { CommunityNavigationComponent } from '../../community-navigation/community-navigation.component';
import { SubscriptionCardComponent } from '../subscription-card/subscription-card.component';

@Component({
  selector: 'app-community-subscription',
  standalone: true,
  imports: [
    CommunityNavigationComponent,
    SubscriptionCardComponent
  ],
  templateUrl: './community-subscription.component.html',
  styleUrl: './community-subscription.component.scss'
})
export class CommunitySubscriptionComponent {
  private readonly profileService = inject(ProfileService);
  private readonly communitySubscriptionService = inject(CommunitySubscriptionService);
  private readonly userStateApiService = inject(UserContentApiService);
  private readonly subscriptionInteractionService = inject(SubscriptionInteractionService);
  private readonly subscriptionService = inject(SubscriptionService);
  private readonly store = inject(Store);
  private readonly stripePromise = loadStripe(environment.stripeKey);

  private loadSubscription: Subscription | undefined;

  requiredPlan = input<SubscriptionPlan>();
  showPurchaseSuccess = input<boolean>(false);
  activePlan = input<SubscriptionPlan>(SubscriptionPlan.NO_MEMBERSHIP); // todo: this shouldn't be an input but taken from the store
  showDescription = input<boolean>(true);
  subscribedToFeeSubscription = output<void>()

  showPurchaseError = signal<boolean>(false);

  userState = toSignal<CommunityUserRole>(this.store.select(selectCommunityRole));
  community = toSignal<RootResource<CommunityProfile>>(this.profileService.activeCommunity());
  subscriptions = signal<Resource<Product<CommunityMembership>>[]>([]);
  // communitySubscriptions = toSignal<Resource<Product<CommunityMembership>>[]>(this.profileService.activeCommunity()
  //   .pipe(
  //     switchMap(community => this.communitySubscriptionService.getAll(community.uuid, true))
  //   ));
  freeSubscription = computed(() => {
    return this.subscriptions()?.find(subscription => subscription.data.resources.plan === SubscriptionPlan.FREE);
  });
  basicSubscription = computed(() => {
    return this.subscriptions()?.find(subscription => subscription.data.resources.plan === SubscriptionPlan.BASIC);
  });
  premiumSubscription = computed(() => {
    return this.subscriptions()?.find(subscription => subscription.data.resources.plan === SubscriptionPlan.PREMIUM);
  });
  freeState = computed<SubscriptionCardState>(() =>
    this.getSubscriptionCardState(CommunityUserRole.freeFollower));
  basicState = computed<SubscriptionCardState>(() =>
    this.getSubscriptionCardState(CommunityUserRole.basicFollower));
  premiumState = computed<SubscriptionCardState>(() =>
    this.getSubscriptionCardState(CommunityUserRole.premiumFollower));


  constructor(
  ) {
    // loadStripe.setLoadParameters({advancedFraudSignals: false});
    this.loadSubscriptions();
  }

  onEditClick(subscription: Resource<Product<CommunityMembership>>) {
    this.subscriptionInteractionService.openModal(
      `Mitgliedschaft "${this.subscriptionService.getSubscriptionPlanStrings(subscription.data.resources.plan)}" editieren`,
      subscription,
      (editedSubscription) => {
        this.communitySubscriptionService.save(editedSubscription)
          .subscribe(() => this.loadSubscriptions());
      }
    );
  }

  onJoinFreeClick(joinData: SubscriptionJoinOutput) {
    this.userStateApiService.joinFreeCommunitySubscription(joinData.product, this.community()!)
      .subscribe(() => this.subscribedToFeeSubscription.emit());
  }

  async onJoinClick(joinData: SubscriptionJoinOutput) {
    this.showPurchaseError.set(false);
    // this.showPurchaseSuccess.set(false);  // todo activate this line

    const stripe = await this.stripePromise;

    // todo: the checkout process should be implemented at only one place, i.e. a service
    this.userStateApiService.purchaseCommunitySubscription(joinData.product, joinData.price?.key || null, joinData.plan, this.community()!)
      .subscribe(async session => {
        const {error} = await stripe?.redirectToCheckout({sessionId: session.id})!;
        if (error) {
          console.error("Stripe Checkout Error:", error);
          this.showPurchaseError.set(true);
        }
      });
  }

  private loadSubscriptions() {
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();

    this.loadSubscription = this.profileService.activeCommunity()
      .pipe(
        switchMap(community => this.communitySubscriptionService.getAll(community.uuid, true))
      )
      .subscribe(subscriptions => {
        this.subscriptions.set(subscriptions);
      });
  }

  private getSubscriptionCardState(roleMatchingSubscriptionCard: CommunityUserRole): SubscriptionCardState {
    if (!this.userState() || this.userState() === CommunityUserRole.visitor || this.userState() === CommunityUserRole.creator) {
      return SubscriptionCardState.default;
    }
    if (this.userState() === CommunityUserRole.freeFollower && (roleMatchingSubscriptionCard === CommunityUserRole.basicFollower || roleMatchingSubscriptionCard === CommunityUserRole.premiumFollower)) {
      return SubscriptionCardState.default;
    } else {
      return this.userState() === roleMatchingSubscriptionCard ? SubscriptionCardState.active : SubscriptionCardState.disabled;
    }
  }

  protected readonly SubscriptionPlan = SubscriptionPlan;
  protected readonly CommunityUserRole = CommunityUserRole;
}
