import {Injectable} from '@angular/core';
import {ResourceService} from "../resource.service";
import {Observable} from "rxjs";
import { Resource, ResourceId } from "../domain/resource";
import { Product } from '../../api/product/domain/product';
import { Bundle } from '../../api/product/domain/bundle';
import { MayAccess } from '../../api/resource/domain/may-access';

@Injectable({
  providedIn: 'root'
})
export abstract class ProductService extends ResourceService<Product<Bundle>> {

  protected constructor() {
    super()
  }

  load(assignmentId: string): Observable<Resource<Product<Bundle>>[]> {
    // return this.profileService.activeCommunity().pipe(
    //   switchMap(c =>
    return this.getAll(assignmentId, false);
    //     .pipe(
    //       // tap(feeds => console.log(feeds)),
    //       map(feeds => feeds.filter(feed => feed.id != mainFeedId))
    //     // )
    //   // )
    // );
  }

  abstract mayPurchaseProduct(resourceId: ResourceId): Observable<MayAccess>;
  abstract getProductsContainingResource(resourceId: ResourceId): Observable<Resource<Product<Bundle>>[]>;
}
