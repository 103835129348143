import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  showConfirmation(): boolean {
    return confirm('You have unsaved changes. Are you sure you want to leave?');
  }

  show(message: string) {
    alert(message);
  }

  showGeneralSaveError(appendedText?: string) {
    const textToAppend = appendedText ? '\n\n' + appendedText : '';
    alert(`An error occurred during saving. Please try again or contact support at contact@keldysh.io.${textToAppend}`);
  }
}
