<div class="youtube-url-container" [style.top]="topPosition()" [style.left]="leftPosition()">
  <button type="button" class="btn-close" (click)="onCloseButtonClick()"></button>
  <form class="">
    <div class="mb-3">
      <label for="button-text">Button Text *</label>
      <input type="text" id="button-text" class="form-control" [(ngModel)]="buttonText" name="button-text" required>
    </div>
<!--    <div class="mb-3">-->
<!--      <label for="button-color">Color</label>-->
<!--      <input type="text" id="button-color" class="form-control" [(ngModel)]="buttonColor" name="button-color">-->
<!--    </div>-->
    <div class="mb-3">
      <label for="button-color">Color</label>
      <input
        type="text"
        id="button-color"
        class="form-control"
        [(ngModel)]="buttonColor"
        name="button-color"
        #colorInput="ngModel"
        pattern="^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$"
      />
      <div *ngIf="colorInput.invalid && colorInput.value" class="text-danger">
        Please enter a valid hex color (e.g., #fff or #ffffff).
      </div>
    </div>
    <div class="mb-3">
      <label for="button-color">Text Color</label>
      <input
        type="text"
        id="button-text-color"
        class="form-control"
        [(ngModel)]="buttonTextColor"
        name="button-text-color"
        #textColorInput="ngModel"
        pattern="^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$"
      />
      <div *ngIf="textColorInput.invalid && textColorInput.value" class="text-danger">
        Please enter a valid hex color (e.g., #fff or #ffffff).
      </div>
    </div>
    <div class="mb-3">
      <label for="link">Link *</label>
      <input type="text" id="link" class="form-control" [(ngModel)]="link" name="link" required>
    </div>
    <div class="mb-3 d-flex align-items-end">
      <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="isSubmitDisabled()">Add</button>
    </div>
  </form>
</div>
