import { Component, computed, ElementRef, inject, input, model, output, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ALLOWED_YOUTUBE_URLS, YoutubeUrlService } from '../youtube-url.service';
import { MarkdownTag } from '../markdown-tag';

@Component({
  selector: 'app-youtube-url-form',
  standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule
    ],
  templateUrl: './youtube-url-form.component.html',
  styleUrl: './youtube-url-form.component.scss'
})
export class YoutubeUrlFormComponent {
  private readonly youtubeUrlService = inject(YoutubeUrlService);

  protected readonly ALLOWED_YOUTUBE_URLS = ALLOWED_YOUTUBE_URLS;

  url = model<string>('');

  referenceElement = input.required<HTMLElement>();
  urlAdded = output<string>();
  closed = output<void>();

  topPosition = computed<string>(() => {
    const elementRect = this.referenceElement().getBoundingClientRect();
    let top = elementRect.bottom;

    const container = this.referenceElement().offsetParent;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      top = elementRect.bottom - containerRect.top;
    }

    return top + 4 + 'px';
  });

  leftPosition = computed<string>(() => {
    const elementRect = this.referenceElement().getBoundingClientRect();
    let left = elementRect.left;

    const container = this.referenceElement().offsetParent;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      left = elementRect.left - containerRect.left;
    }

    return left + 'px';
  });

  showNoYouTubeLinkError = computed<boolean>(() => {
    return !!(this.url() && !this.isYouTubeUrlValid());
  });

  isSubmitDisabled = computed<boolean>(() => {
    return !this.url() || !this.isYouTubeUrlValid();
  });

  isYouTubeUrlValid = computed<boolean>(() => {
    return !!this.youtubeUrlService.extractYouTubeID(this.url());
  });

  submit() {
    this.urlAdded.emit(this.url());
  }

  onCloseButtonClick() {
    this.closed.emit();
  }
}
