<!--<div *ngIf="!postResource" class="d-flex justify-content-end">-->
<!--  <button type="submit" class="btn btn-primary" (click)="create()">Create</button>-->
<!--</div>-->
<div *hasWritePermission class="d-flex justify-content-end">
  <button type="submit" class="btn btn-primary" (click)="$event.stopPropagation(); edit()">Edit</button>
  <!--  <button type="submit" class="btn btn-success" (click)="edit()">Save</button>-->
</div>
<div class="col-lg-12">
  <app-post-view [post]="postResource.data"></app-post-view>
<!--  <app-generic-element *ngFor="let element of postResource.data.elements" [data]="element"></app-generic-element>-->
</div>
